import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Field, FieldProps, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Observable } from 'rxjs'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  SxProps,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { GorillaAlert } from '@procom-labs/atoms'
import {
  AiFeatureLocations,
  IAiJobPromptPayload,
  JobCreativityLevel,
  JobSeniorityLevel,
  PreferredLanguageType,
  useIsGorillaTheme,
  useSubscriptionRef,
} from '@procom-labs/common'

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import { JobGeneratorLens } from '../../models/jobGenerator'
import { LanguageSwitchButtons } from '../language-switcher'
import { LoadingIndicator } from '../loading-indicator'

interface IAIGenerateDefaults extends IAiJobPromptPayload {
  jobTagInput?: string
}

export const AiJobFineTunePanelDefaultSettings = {
  creativityLevel: JobCreativityLevel.Balanced,
  seniority: JobSeniorityLevel.Intermediate,
  language: PreferredLanguageType.EN,
}

const defaultValues: IAIGenerateDefaults = {
  title: '',
  workplaceType: null,
  location: '',
  jobTagInput: '',
  ...AiJobFineTunePanelDefaultSettings,
}

const Item = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isGorillaTheme',
})<{ isGorillaTheme: boolean }>(({ theme, isGorillaTheme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  boxShadow: 'none',
  ...(!isGorillaTheme && {
    backgroundColor: theme.palette.common.white,
  }),
}))

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

export interface IAiSettings
  extends Pick<
    IAIGenerateDefaults,
    'creativityLevel' | 'seniority' | 'language' | 'tags'
  > {}

export const AiJobFineTunePanel: FC<{
  isLoading: boolean
  jobInformation: IAiJobPromptPayload | null
  isExpanded?: boolean
  isForm?: boolean
  sx?: SxProps<Theme>
  setAiSettings?: (settings: IAiSettings) => void
  getContentStream: (
    payload: IAiJobPromptPayload,
    userId?: string | null
  ) => Observable<string>
  featureLocation: AiFeatureLocations
  warningText?: string | null
  trackingEventName?: string
  sendJobDescription: (description: string) => void
}> = memo(
  ({
    isLoading,
    jobInformation,
    isExpanded = true,
    isForm = true,
    sx,
    setAiSettings,
    getContentStream,
    featureLocation,
    warningText = '',
    sendJobDescription,
    trackingEventName,
  }) => {
    const { t } = useTranslation('main')
    const theme = useTheme()
    const [searchParams] = useSearchParams()
    const userId = searchParams.get('UserID') || ''
    const sendDataToGTM = useGTMDispatch()
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const [expanded, setExpanded] = React.useState<boolean>(isExpanded)

    const [initialFormValues, setInitialFormValues] = useState(defaultValues)
    const subscriptionRef = useSubscriptionRef()
    const atsJobDescriptionRef = useRef('')

    const isGorillaTheme = useIsGorillaTheme()

    const generateContent = useCallback(
      (payload: IAiJobPromptPayload): void => {
        subscriptionRef.current = getContentStream(payload, userId).subscribe({
          next: (data: string) => sendJobDescription(data),
        })
      },
      [getContentStream, subscriptionRef, sendJobDescription, userId]
    )

    useEffect(() => {
      setInitialFormValues({
        title: jobInformation?.title || defaultValues.title,
        creativityLevel:
          jobInformation?.creativityLevel || defaultValues.creativityLevel,
        language: jobInformation?.language || defaultValues.language,
        tags: jobInformation?.tags || [
          t('common.jobGenerator.aitags.Accessible'),
          t('common.jobGenerator.aitags.Teamwork'),
          t('common.jobGenerator.aitags.ProblemSolving'),
          t('common.jobGenerator.aitags.Communication'),
        ],
        seniority: jobInformation?.seniority || defaultValues.seniority,
        workplaceType: jobInformation?.workplaceType,
        location: jobInformation?.location,
        employmentType: jobInformation?.employmentType,
        atsJobDescription: jobInformation?.atsJobDescription,
        atsJobIntakeNotes: jobInformation?.atsJobIntakeNotes,
        templateId: jobInformation?.templateId,
      })
    }, [jobInformation, t])

    const handleAccordionState = (
      event: React.SyntheticEvent,
      isExpandedState: boolean
    ): void => {
      setExpanded(isExpandedState)
    }

    /**
     * Stop enter submitting the form.
     * @param keyEvent Event triggered when the user presses a key.
     */
    const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLElement>): void => {
      if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault()
      }
    }

    const onSubmit = (values: IAIGenerateDefaults): void => {
      const {
        seniority,
        language,
        tags,
        title,
        creativityLevel,
        workplaceType,
        location,
        employmentType,
        atsJobDescription,
        atsJobIntakeNotes,
      } = values

      atsJobDescriptionRef.current = ''
      generateContent({
        seniority,
        language,
        tags,
        title,
        creativityLevel,
        workplaceType,
        location,
        employmentType,
        atsJobDescription,
        atsJobIntakeNotes,
      })
    }

    const handleSetAiSettings = useCallback(
      (values) => {
        if (setAiSettings) {
          setAiSettings({
            creativityLevel: values.creativityLevel,
            seniority: values.seniority,
            language: values.language,
            tags: values.tags,
          })
        }
      },
      [setAiSettings]
    )

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, submitForm, handleChange }) => {
            return (
              <Grid onKeyDown={onKeyDown} component={isForm ? 'form' : 'div'}>
                <Stack spacing={2} sx={{ ...sx }}>
                  <Accordion
                    disableGutters
                    elevation={0}
                    expanded={expanded}
                    onChange={handleAccordionState}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ pl: 1 }}
                    >
                      <Typography variant="subtitle1Label">
                        {t('common.jobGenerator.fineTuneJob')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={isGorillaTheme ? { pl: 0 } : {}}>
                      <Stack spacing={2}>
                        <Item isGorillaTheme={isGorillaTheme}>
                          <Stack
                            direction={mobileView ? 'column' : 'row'}
                            sx={
                              !isGorillaTheme
                                ? {
                                    flexDirection: 'column',
                                  }
                                : {}
                            }
                            spacing={isGorillaTheme ? 2 : 0}
                            rowGap={1}
                            flexWrap="wrap"
                            alignItems={{ xs: 'center', lg: 'flex-start' }}
                            divider={
                              !mobileView && isGorillaTheme ? (
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  sx={{ borderColor: 'text.primary' }}
                                />
                              ) : null
                            }
                          >
                            <ToggleButtonGroup
                              color="primary"
                              exclusive
                              value={values.creativityLevel}
                              aria-label={t(
                                'common.jobGenerator.jobCreativityLevel'
                              )}
                              onChange={(_, value) => {
                                if (value) {
                                  setFieldValue('creativityLevel', value)
                                  handleSetAiSettings({
                                    ...values,
                                    creativityLevel: value,
                                  })
                                }
                              }}
                            >
                              <ToggleButton value={JobCreativityLevel.Creative}>
                                {t(
                                  'common.jobGenerator.jobCreativityLevelTypes.creative'
                                )}
                              </ToggleButton>
                              <ToggleButton value={JobCreativityLevel.Balanced}>
                                {t(
                                  'common.jobGenerator.jobCreativityLevelTypes.balanced'
                                )}
                              </ToggleButton>
                              <ToggleButton value={JobCreativityLevel.Precise}>
                                {t(
                                  'common.jobGenerator.jobCreativityLevelTypes.precise'
                                )}
                              </ToggleButton>
                            </ToggleButtonGroup>

                            <ToggleButtonGroup
                              color="primary"
                              exclusive
                              value={values.seniority}
                              aria-label={t(
                                'common.jobGenerator.jobSeniorityLevel'
                              )}
                              onChange={(_, value) => {
                                if (value) {
                                  setFieldValue('seniority', value)
                                  handleSetAiSettings({
                                    ...values,
                                    seniority: value,
                                  })
                                }
                              }}
                            >
                              <ToggleButton value={JobSeniorityLevel.Senior}>
                                {t(
                                  'common.jobGenerator.jobSeniorityLevelTypes.senior'
                                )}
                              </ToggleButton>
                              <ToggleButton
                                value={JobSeniorityLevel.Intermediate}
                              >
                                {t(
                                  'common.jobGenerator.jobSeniorityLevelTypes.intermediate'
                                )}
                              </ToggleButton>
                              <ToggleButton value={JobSeniorityLevel.Junior}>
                                {t(
                                  'common.jobGenerator.jobSeniorityLevelTypes.junior'
                                )}
                              </ToggleButton>
                            </ToggleButtonGroup>

                            <LanguageSwitchButtons
                              name="language"
                              spacing={1}
                            />
                          </Stack>
                        </Item>
                        <Item isGorillaTheme={isGorillaTheme}>
                          <Typography variant="subtitle1Label" gutterBottom>
                            {t('common.jobGenerator.jobTags')}
                          </Typography>
                          <Field name={JobGeneratorLens.jobTagInput.$key()}>
                            {({ field, form }: FieldProps) => (
                              <TextField
                                {...field}
                                fullWidth
                                sx={{ mt: 1 }}
                                value={field.value || ''}
                                name={JobGeneratorLens.jobTagInput.$key()}
                                placeholder={t(
                                  'common.jobGenerator.jobTagInputPlaceholder'
                                )}
                                InputLabelProps={{ shrink: true }}
                                helperText={t(
                                  'common.jobGenerator.jobTagInputLabelHelperText'
                                )}
                                onChange={handleChange}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    if (
                                      values.jobTagInput &&
                                      values.jobTagInput.trim() &&
                                      !values?.tags?.find(
                                        (tag) => tag === values.jobTagInput
                                      )
                                    ) {
                                      form.setFieldValue(
                                        JobGeneratorLens.tags.$key(),
                                        [
                                          ...(values.tags || []),
                                          values.jobTagInput,
                                        ]
                                      )
                                      setFieldValue(
                                        JobGeneratorLens.jobTagInput.$key(),
                                        ''
                                      )

                                      handleSetAiSettings({
                                        ...values,
                                        tags: [
                                          ...(values.tags || []),
                                          values.jobTagInput,
                                        ],
                                      })
                                    }
                                  }
                                }}
                              />
                            )}
                          </Field>
                          <Paper
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexWrap: 'wrap',
                              listStyle: 'none',
                              backgroundColor: 'transparent',
                              p: 0.5,
                              mt: 2,
                              columnGap: 1,
                            }}
                            component="ul"
                            elevation={0}
                          >
                            {values.tags?.map((data, index) => {
                              return (
                                <ListItem key={index}>
                                  <Chip
                                    size="small"
                                    sx={{
                                      ...(isGorillaTheme
                                        ? {
                                            backgroundColor:
                                              theme.palette.primary.main,
                                            color: 'common.white',
                                            height: 32,
                                            '.MuiChip-label': {
                                              fontWeight: 400,
                                              fontSize: 13,
                                            },
                                            '.MuiSvgIcon-root': {
                                              fontSize: 24,
                                              color: 'common.white',
                                              '&:hover': {
                                                color: 'common.white',
                                              },
                                            },
                                          }
                                        : {}),
                                    }}
                                    label={data}
                                    onDelete={() => {
                                      const filteredJobTags =
                                        values.tags?.filter(
                                          (tag) => tag !== data
                                        )
                                      setFieldValue('tags', filteredJobTags)
                                      handleSetAiSettings({
                                        ...values,
                                        tags: filteredJobTags,
                                      })
                                    }}
                                  />
                                </ListItem>
                              )
                            })}
                          </Paper>
                        </Item>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                  {warningText && (
                    <GorillaAlert severity="info">{warningText}</GorillaAlert>
                  )}

                  <Box sx={{ textAlign: 'center' }}>
                    <LoadingButton
                      fullWidth
                      loading={isLoading}
                      type="button"
                      sx={{ maxWidth: 286 }}
                      loadingIndicator={
                        <LoadingIndicator
                          label={t('common.jobGenerator.btn.generating')}
                        />
                      }
                      name={trackingEventName}
                      variant="contained"
                      color="secondary"
                      onClick={submitForm}
                    >
                      {t('common.jobGenerator.btn.generate')}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Grid>
            )
          }}
        </Formik>
      </>
    )
  }
)
