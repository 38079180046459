import React, { memo } from 'react'
import { FormControl, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Preloader3D } from './preloader'
import { FormEditor } from './form-editor/form-editor'

export const JobPreview: React.FC<{
  isLoading: boolean
  environment: any
}> = memo(({ isLoading, environment }) => {
  const { t } = useTranslation('main')

  if (isLoading) {
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        mt={27.4}
        justifyContent="center"
        alignContent="center"
      >
        <Grid item>
          <Typography variant="body1" align="center" sx={{ fontSize: 16 }}>
            {t('common.jobGenerator.aiPlaceholder')}
          </Typography>
        </Grid>
        <Grid item>
          <Preloader3D center />
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <FormEditor
          environment={environment}
          name="atsJobDescription"
          height={475}
        />
      </FormControl>
    </>
  )
})
