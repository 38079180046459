import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { debounceTime, map, Observable, of, Subscription } from 'rxjs'

import {
  base64ToBlobUrl,
  Employee,
  IContractorProfileResume,
  IFrameEntityTypes,
  IResume,
  mapToCandidates,
  mapToDataArray,
  quickSearchPayload,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  useAlert,
  useDefaultErrorHandler,
  useSubjectState,
} from '@procom-labs/molecules'

import { contractorProfileService } from '@submission-portal/services/contractor-profile.service'
import {
  ILegacyCopilotStoreState,
  legacyCopilotStore,
} from '@submission-portal/stores'
import { serviceDescriptorStore } from '@submission-portal/stores/service-descriptor-store'

export const useUploadedResourceChange: (
  resumeFileProp: keyof ILegacyCopilotStoreState,
  resumeFileBlobProp: keyof ILegacyCopilotStoreState
) => (file: IResume | null) => void = (fileProp, fileBlobProp) => {
  const subscriptionRef = useSubscriptionRef()

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  return useCallback(
    (file: IResume | null): void => {
      const fileStorageId = file?.fileStorageId
      if (fileStorageId) {
        legacyCopilotStore.dispatch({
          [fileProp]: file as IContractorProfileResume,
        })
        if (subscriptionRef.current && !subscriptionRef.current.closed) {
          subscriptionRef.current.unsubscribe()
        }
        subscriptionRef.current = contractorProfileService
          .downloadResume(fileStorageId, candidateAtsService.getAtsUserId())
          .subscribe({
            next: (buffer) => {
              const blob = new Blob([buffer])
              const url = URL.createObjectURL(blob)

              legacyCopilotStore.dispatch({
                [fileBlobProp]: url,
              })
            },
          })
      } else {
        legacyCopilotStore.dispatch({
          [fileProp]: null,
          [fileBlobProp]: '',
        })
      }
    },
    [candidateAtsService, subscriptionRef, fileProp, fileBlobProp]
  )
}

export const useDeleteResource: (
  fileProp: keyof ILegacyCopilotStoreState,
  fileBlobProp: keyof ILegacyCopilotStoreState
) => (id: string) => Observable<any> = (resumeFileProp, resumeFileBlobProp) => {
  return useCallback(
    (id: string): Observable<any> => {
      legacyCopilotStore.dispatch({
        [resumeFileProp]: null,
        [resumeFileBlobProp]: '',
      })

      return of(id)
    },
    [resumeFileProp, resumeFileBlobProp]
  )
}

export const useUploadResource: () => (
  formData: FormData,
  onUploadProgress: (event: ProgressEvent) => void
) => Observable<IResume> = () => {
  return useCallback(
    (formData: FormData, onUploadProgress: (event: ProgressEvent) => void) => {
      formData.append('ShouldParseResume', 'false')
      return contractorProfileService
        .uploadResume(formData, onUploadProgress)
        .pipe(map((data) => data.contractorResume))
    },
    []
  )
}

export const useResumeSelectionChange: (
  selectionProp: keyof ILegacyCopilotStoreState,
  selectionBlobProp: keyof ILegacyCopilotStoreState
) => (entityId: string, fileId: string) => void = (
  selectionProp,
  selectionBlobProp
) => {
  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const subscriptionRef = useSubscriptionRef()

  const { addAlert } = useAlert()
  const { t } = useTranslation('main')

  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  return useCallback(
    (entityId: string, fileId: string): void => {
      legacyCopilotStore.dispatch({
        [selectionProp]: fileId,
        // in case of failure to load the new resume for whatever reason, we need to ensure to clear previous blob
        [selectionBlobProp]: '',
        processingResume: true,
      })

      subscriptionRef.current?.unsubscribe()
      subscriptionRef.current = candidateAtsService
        .getCandidateFile({
          entityId: Number(entityId),
          fileId: Number(fileId),
        })
        .subscribe({
          next: (content) => {
            legacyCopilotStore.dispatch({
              [selectionBlobProp]: base64ToBlobUrl(content.content),
              processingResume: false,
              resumeSelectionFileSize: content.size,
            })
          },
          error: () => {
            handleError()
            legacyCopilotStore.dispatch({
              processingResume: false,
            })
          },
        })
    },
    [
      subscriptionRef,
      candidateAtsService,
      handleError,
      selectionProp,
      selectionBlobProp,
    ]
  )
}

export const useCoverPageSelectionChange: (
  selectionProp: keyof ILegacyCopilotStoreState,
  selectionBlobProp: keyof ILegacyCopilotStoreState
) => (entityId: string, fileId: string) => void = (
  selectionProp,
  selectionBlobProp
) => {
  return useCallback(
    (entityId: string, fileId: string): void => {
      legacyCopilotStore.dispatch({
        [selectionProp]: fileId,
        [selectionBlobProp]: '',
      })
    },
    [selectionProp, selectionBlobProp]
  )
}

export const useHandleSelectCoverPageJob = (): ((
  // We are bound by the selection value received by an event, this why we use any as the type
  sel: any
) => void) => {
  const subscriptionRef = useSubscriptionRef()

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const { t } = useTranslation('main')
  const { addAlert } = useAlert()

  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  return useCallback(
    (sel) => {
      legacyCopilotStore.dispatch({
        coverPageJobSelection: sel || '',
      })

      subscriptionRef.current?.unsubscribe()
      subscriptionRef.current = candidateAtsService
        .getJobOrderFiles({
          atsJobId: sel,
        })
        .subscribe({
          next: (posts) => {
            legacyCopilotStore.dispatch({
              coverPageSelections: mapToDataArray(posts, 'name'),
            })
          },
          error: handleError,
        })
    },
    [subscriptionRef, candidateAtsService, handleError]
  )
}
export const useHandleResumeSelectorSearch = (): [
  boolean,
  (keyword: string) => void
] => {
  const { t } = useTranslation('main')
  const [isSearching, setIsSearching] = useState(false)

  const { addAlert } = useAlert()

  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const [keyword$, keyword, setKeyword] = useSubjectState('')

  const [searchParams] = useSearchParams()
  const isCandidatePage =
    searchParams.get('EntityType') === IFrameEntityTypes.Candidate

  useEffect(() => {
    const searchSubscriptionRef = new Subscription()
    const keywordSubscriptionRef = new Subscription()
    if (!isCandidatePage) {
      keywordSubscriptionRef.add(
        keyword$.pipe(debounceTime(250)).subscribe((newKeyword) => {
          if (newKeyword) {
            setIsSearching(true)
            searchSubscriptionRef.add(
              candidateAtsService
                .searchCandidates(quickSearchPayload(newKeyword))
                .subscribe({
                  next: (posts) => {
                    setIsSearching(false)
                    legacyCopilotStore.dispatch({
                      candidateSelections: mapToCandidates(posts.data),
                    })
                  },
                  error: () => {
                    setIsSearching(false)
                    handleError()
                  },
                })
            )
          } else {
            legacyCopilotStore.dispatch({
              candidateSelections: [],
            })
          }
        })
      )
    } else {
      const candidateID = Number(searchParams.get('EntityID'))
      legacyCopilotStore.dispatch({
        canSelectCandidate: false,
      })
      searchSubscriptionRef.add(
        candidateAtsService.getCandidate(candidateID).subscribe({
          next: (data: Employee) => {
            setIsSearching(false)
            const candidateList = data ? mapToCandidates([data]) : []
            legacyCopilotStore.dispatch({
              candidateSelections: candidateList,
              candidateSelection: candidateList[0]?.id,
            })
          },
          error: () => {
            setIsSearching(false)
            handleError()
          },
        })
      )
    }

    return () => {
      if (searchSubscriptionRef && !searchSubscriptionRef.closed) {
        searchSubscriptionRef.unsubscribe()
      }
      if (keywordSubscriptionRef && !keywordSubscriptionRef.closed) {
        keywordSubscriptionRef.unsubscribe()
      }
    }
  }, [
    candidateAtsService,
    keyword$,
    handleError,
    keyword,
    isCandidatePage,
    searchParams,
  ])

  return [isSearching, setKeyword]
}
