import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { WorkOutlineRounded } from '@mui/icons-material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import {
  AccordionDetails,
  AccordionSummary,
  alpha,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { GorillaResumeEmployment } from '@procom-labs/common'

import { SectionTitle, StyledAccordion } from '../common'
import { ExperienceAccordion } from './experience-accordion'

export const Experience: FC<{
  employmentHistory: GorillaResumeEmployment[]
}> = memo(({ employmentHistory }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobileOrTab = useMediaQuery(() => theme.breakpoints.down('lg'))

  return (
    <StyledAccordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon fontSize="large" />}
        sx={{
          padding: '0 8px 0 0',
          borderBottom: `2px solid ${alpha(theme.palette.divider, 0.3)}`,
        }}
      >
        <SectionTitle
          icon={
            <WorkOutlineRounded
              sx={{
                color: theme.palette.secondary.main,
                fontSize: '18px',
              }}
            />
          }
          title={t('organisms.candidateOnePager.workExperience.title')}
        />
      </AccordionSummary>

      <AccordionDetails sx={{ px: 0 }}>
        <Timeline
          sx={{
            pl: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {employmentHistory.map((history, index) => (
            <TimelineItem
              key={`${history.title} ${history.employer}`}
              sx={{
                ...(index === employmentHistory.length - 1
                  ? { minHeight: 'auto' }
                  : {}),
              }}
            >
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  sx={{ width: '13px', height: '13px' }}
                />
                {index !== employmentHistory.length - 1 && (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent sx={{ pb: isMobileOrTab ? 4 : 0 }}>
                <ExperienceAccordion
                  startDate={history.startDate}
                  endDate={history.endDate}
                  title={history.title}
                  subtitle={history.industryName}
                  description={history.description}
                />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </AccordionDetails>
    </StyledAccordion>
  )
})
