import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'

import SearchIcon from '@mui/icons-material/Search'
import {
  AutocompleteProps,
  Box,
  TextField,
  TextFieldProps,
} from '@mui/material'
import Autocomplete, {
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete'
import { Option } from '@procom-labs/common'

interface SearchableListBoxProps
  extends Omit<
    AutocompleteProps<Option, false, false, false, 'div'>,
    'options' | 'renderInput' | 'onChange' | 'onSelect'
  > {
  selections: Option[]
  selection: string | null
  textFieldProps?: TextFieldProps
  onChange?: (value: string) => void
  onSelect?: (selectedId: string | null, data: Option[]) => void
  placeholder?: string
}

export const SearchableListBox: FC<SearchableListBoxProps> = ({
  selections,
  textFieldProps,
  onChange,
  onSelect,
  selection,
  placeholder,
  loading,
  ...autoCompleteProps
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const selectedValue = useMemo(() => {
    return selections.find((option) => option.id === selection) || null
  }, [selections, selection])

  return (
    <Box>
      <Autocomplete
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={selectedValue}
        options={selections}
        loading={loading}
        getOptionLabel={(option) => option.title}
        popupIcon={open ? null : <SearchIcon />}
        onInputChange={(
          event: ChangeEvent<{}>,
          value: string,
          reason: AutocompleteInputChangeReason
        ) => {
          if (reason === 'input') {
            onChange?.(value)
          }
        }}
        onChange={(event: any, value: any) =>
          onSelect?.(value?.id || null, selections)
        }
        filterOptions={(x) => x} // this prevents Autocomplete from automatically filtering the options
        renderOption={(props, option) => {
          // We do it like this as MUI autocomplete assumes titles to be the key. However, this can be an issue
          // to us in many cases, where we can have the same titles.
          return (
            <Box component="li" {...props} key={option.id}>
              {option.title}
            </Box>
          )
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            {...textFieldProps}
            variant="outlined"
          />
        )}
        clearIcon={false}
        {...autoCompleteProps}
      />
    </Box>
  )
}
