import { TFuncKey } from 'react-i18next'

import { CurrencyTypes, EmploymentTypes, FileSource } from '../../enums'
import { IDocument } from '../additional-documents'
import { CandidatesValues } from '../job/job'
import { IContractorProfileResume, IResume } from '../user'

export interface ICandidatesGroup {
  submissionStatus: string
  transKey: TFuncKey
  totalCandidateKey: keyof CandidatesValues
  cards: ICandidate[]
  colorScheme: string
}

export interface ICandidates {
  name: string
  value: ICandidate[]
}

export interface ICandidateCommon {
  jobTitle: string
  address: string
  city: string
  state: string
  dateAvailable?: string
  dateSubmitted: string
  firstName: string
  lastName: string
  status: string
  comments: string | null
  email: string
  employeeType: string
  hourlyRate: number
  jobMarginPercentage: number
  name: string
  payRate: number
  phone: string
  salary: number | null
  interviewRequestedDateTime: string
  rejectionDateTime: string
  rejectionReason: string
  rejectionHeading: string
  shortlistedDateTime: string
  offerExtendedNote: string
  offerExtendedDateTime: string
  atsJobId: number
}

export interface ClientPortalFieldsVisibility {
  isRateVisible: boolean
  isAvailabilityVisible: boolean
  isLegalStatusVisible: boolean
  isLocationVisible: boolean
}

export interface IEmailTemplate {
  language: string
  emailContent: string
  emailSubject: string
}

export interface IEmailPayload {
  userLanguage: string | null
  emailFrom: string
  emailTo: string[]
  emailToCc: string[] | null
  emailToBcc: string[] | null
  emailTemplates: IEmailTemplate[]
  sendEmailCopyToSender: boolean
}

export interface ICandidate extends ICandidateCommon {
  currency: CurrencyTypes
  billRate: number | null
  ratePeriodType: string | null
  atsUserId: number
  jobSubmissionId: string
  jobSubmissionStatus: string
  dateAvailableUserInput?: string
  dateAvailableType: string
  id: string
  contractorProfileId?: string | null
  employmentType: EmploymentTypes
  resume: IResume | null
  clientSideResume: IResume | null
  coverPage: IResume | null
  formattedSubmissionFile: IResume | null
  additionalDocuments: IDocument[] | null
  highlightsAdditionalDocuments: IDocument[] | null
  jobApplicationResume: IContractorProfileResume | null
  clientPortalFieldsVisibility: ClientPortalFieldsVisibility
  showBillRate: boolean
  interviewRequest: IInterviewRequest
  countryName?: string
  countryCode?: string
  stateCode?: string
  zip?: string
  latitude: string | null
  longitude: string | null
  highlights?: string
  atsJobSubmissionStatus?: string
  isCoverPageUploadEnabled: boolean
  isAdditionalDocumentsUploadEnabled: boolean
  isViewed?: boolean
  submissionEmailTemplate: IEmailPayload | null
  includeCandidateHighlightsInEmail: boolean
  isOnePagerEnabled: boolean | null
  workplaceType: string[]
}

export interface ICandidateResume extends Pick<ICandidate, 'resume'> {
  entityId: string
}

export interface ICandidateDocuments
  extends Pick<ICandidate, 'additionalDocuments'> {
  entityId: string
  entityType: string
}

export interface IResumeLink {
  name: string
  link: string
}

export interface IFindCandidate {
  groupIndex: number
  candidateIndex: number
}

export interface ICandidateCards {
  submissionStatus: string
  transKey: TFuncKey
  status: {
    transKey: TFuncKey
  }
  icon?: string
  date?: keyof ICandidate
  totalCandidateKey: keyof CandidatesValues
  cards?: ICandidate[]
  hideColumn?: boolean
  colorScheme: string
}

export enum CandidateTabs {
  resume = 0,
  documents = 1,
  notes = 2,
  timeline = 3,
}

export interface IAvailabilityPayload {
  date: string | null
  fromTime: string | Date | null
  toTime: string | Date | null
  timeZone: string | null
}

export interface IInterviewPlatform {
  platform: string
  specifyOther: string
  interviewLinkLocation: string
}

export interface IInvitees {
  id: string
  firstName: string
  lastName: string
  email: string
}

export interface IInterviewRequest {
  interviewRequesterName: string
  interviewRequesterEmail: string
  interviewRequestAdditionalNotes: string
  interviewDateTimes: IAvailabilityPayload[]
  interviewRequestCreatedDateTime?: string
  interviewPlatform?: IInterviewPlatform
  invitees?: IInvitees[] | []
}

export type HighlightDocument = { id: string; type: FileSource }

export interface ICandidateHighlights {
  resumeFileStorageId: string
  atsJobId?: number
  clientSocketConnectionId?: string | null
  language: string
  jobId?: string
  entityType?: string
  additionalDocuments?: HighlightDocument[]
}

export interface IAtsCandidateFile {
  contentSubType: string
  contentType: string
  description: string
  fileExtension: string
  fileSize: number
  id: number
  isDeleted: boolean
  isPrivate: boolean
  name: string
  type: string
  dateAdded: Date
  dateAddedToLocalTime: string
}

export interface ICandidateInterviewQuestions {
  aiResponse: string
  rawRequestBody: string
}

export interface IAtsCandidateHighlights {
  atsCandidateId: number
  atsCandidateResumeId?: number
  clientSocketConnectionId?: string | null
  language: string
}

export type ICandidateDmPayload = {
  id: string // jobId or jobSubmissionId
  title: string
  open: boolean
  candidateCard: boolean
}

export type ICollaboratorInvitePayload = {
  id: string
  email: string
  firstName: string
  lastName: string
  isInvited: boolean
}

export type InterviewFormPayload = {
  interviewDateTimes: IAvailabilityPayload[]
  interviewRequestAdditionalNotes: string
  interviewRequestCreatedDateTime?: string
  interviewCollaborators?: ICollaboratorInvitePayload[] | []
  interviewPlatform?: string
  interviewPlatformOther?: string
  interviewMeetingLocation?: string
}

export interface AtsFileInfo {
  entityId?: number
  fileStorageInfo?: {
    fileStorageId: string
  }
  data?: string
  atsFileInfo?: {
    entityId: number
    fileId: number
  }
  extension: string
}

export interface DateFormats {
  workExperience: string
  education: string
  militaryService: string
  certifications: string
  candidateAvailability: string
}

export type ResumeTemplateConfiguration = {
  templateId: string
  language: string
}

export type LegacyResumeTemplateConfiguration =
  | {
      templateFileStorageId: string
      dateFormats: DateFormats
      language: string
      keepOriginalResume?: boolean
      addCustomerLogo?: boolean
    }
  | {
      templateFileData: string
      dateFormats: DateFormats
      language: string
      keepOriginalResume?: boolean
      addCustomerLogo?: boolean
    }

export interface FormatResumeRequest {
  atsJobId: number
  atsCandidateResumeInfo: AtsFileInfo
  atsJobCoverPageInfo?: AtsFileInfo
  resumeTemplateConfiguration?: ResumeTemplateConfiguration
  addJobCoverPageToSubmissionDocument?: boolean
  candidateInfo: {
    dateAvailable?: number
    location?: string
    highlights?: string
    vacation?: string
    removePersonalInfo?: boolean
  }
}

export interface FormatLegacyResumeRequest {
  atsJobId: number | null
  atsCandidateResumeInfo: AtsFileInfo
  atsJobCoverPageInfo?: AtsFileInfo
  resumeTemplateConfiguration?: LegacyResumeTemplateConfiguration
  addJobCoverPageToSubmissionDocument?: boolean
  candidateInfo: {
    dateAvailable?: number
    location?: string
    highlights?: string
    vacation?: string
    removePersonalInfo?: boolean
  }
}

export interface AtsFileParameter {
  entityId: number
  fileId: number
}

export interface AtsCandidateFileParameter {
  atsCandidateId: number
}

export interface AtsJobOrderFileParameter {
  atsJobId: number
}

interface Address {
  address1: string
  address2: string
  city: string
  countryCode: string
  countryID: number
  countryName: string
  state: string
  zip: string
}

interface Candidate {
  id: number
  name: string
  dateAvailable: number
  dateAdded: number
  email: string
  phone: string
  mobile: string
  hourlyRate: number
  salary: number
  address: Address
  employeeType: string
  firstName: string
  lastName: string
  customText24: string
  status: string
  dateLastModified: number
  source: string
  customText12: string
}

export interface DataObject {
  contentSubType: string
  contentType: string
  candidate: Candidate
  description: string
  directory: string
  fileExtension: string
  name: string
  fileSize: number
  id: number
  isPrivate: boolean
  isDeleted: boolean
  type: string
  dateAdded: number
  dateAddedToLocalTime: number
  parsedResumeId: string | null
}

export interface DataObjectContent {
  content: string
  extension: string
  id: number
  name: string
  size: number
  contentType: string
}

export interface Employee {
  id: number
  name: string
  dateAvailable: number
  dateAdded: number
  email: string
  phone: string
  mobile: string
  hourlyRate: number
  salary: number
  address: Address
  employeeType: string
  firstName: string
  lastName: string
  customText24: string
  status: string
  dateLastModified: number
  source: string
  customText12: string
}

export interface EmployeeData {
  total: number
  data: Employee[]
}

export interface JobData {
  total: number
  data: Job[]
}

export interface Job {
  id: number
  title: string
  description: string
  status: string
  payRate: number
  salary: number
  salaryUnit: null
  markUpPercentage: number
  onSite: string
  employmentType: string
  startDate: number
  dateEnd: null
  dateAdded: number
  isPublic: number
  isOpen: boolean
  isDeleted: boolean
  owner: User
  reportTo: null
  clientContact: User
  responseUser: User
  customText18: string
  address: Address
  durationWeeks: number
  customText2: string
  customText7: string
  publicDescription: string
  clientBillRate: number
  correlatedCustomFloat3: null
  feeArrangement: null
  customFloat3: number
  correlatedCustomFloat1: number
  customText4: string
  correlatedCustomDate2: number
  dateLastModified: number
  dateLastPublished: number
  latitude: null
  longitude: null
  numOpenings: number
  clientCorporation: ClientCorporation
  categories: Categories
  submissions: null
}

export interface User {
  id: number
  firstName: string
  lastName: string
  email: string
  name: string | null
  username: string | null
}

export interface ClientCorporation {
  id: number
  name: string
  clientContacts: null
}

export interface Categories {
  total: number
  data: Category[]
}

export interface Category {
  id: number
  name: string
  description: string | null
  occupation: null
  type: null
  skills: null
  specialties: null
  enabled: null
  dateAdded: null
}

export interface SearchQuery {
  fieldName: string
  condition: string
}

export interface DateFilter {
  fieldName: string
  fromDateTime: string
  toDateTime: string
}

export interface SearchPayload {
  jobOrderId?: number
  searchQueries?: SearchQuery[]
  searchFilters?: SearchQuery[]
  dateFilters?: DateFilter[]
  startIndex?: number
  count?: number
  orderBy?: string
}

export interface SubmissionTemplate {
  name: string
  templateId: string
  vendorCode: string
  language: string
  isDefault: boolean
}

export interface LegacySubmissionTemplate {
  name: string
  fileStorageId: string
  vendorCode: string
  dateFormats: DateFormats
  language: string
  isDefault: boolean
  keepOriginalResume?: boolean
  addCustomerLogo?: boolean
}
