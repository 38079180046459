import React, { FC, useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { IconButton, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { GorillaDialog } from '@procom-labs/atoms'

export interface IConfirmationDialogProps {
  open: boolean
  title?: string
  content?: string
  closeBtn?: boolean
  okBtnText?: string
  cancelBtnText?: string
  isLoading?: boolean
  hideConfirmBtn?: boolean
  hideCancelBtn?: boolean
  disableClickAway?: boolean
  handleClose: (value: boolean, reason?: string) => void
  children?: React.ReactNode
}

export const ConfirmationDialog: FC<IConfirmationDialogProps> = ({
  open,
  title = 'Confirmation',
  content = 'Are you sure?',
  closeBtn = false,
  okBtnText = 'Ok',
  cancelBtnText = 'Cancel',
  handleClose,
  hideConfirmBtn = false,
  hideCancelBtn = false,
  children,
  isLoading = false,
  disableClickAway = false,
}) => {
  const handleModalClose = useCallback(
    (state, reason?: 'backdropClick' | 'escapeKeyDown') => {
      const canClickAway =
        disableClickAway &&
        (reason === 'backdropClick' || reason === 'escapeKeyDown')
      if (!isLoading && !canClickAway) {
        handleClose(false)
      }
    },
    [disableClickAway, handleClose, isLoading]
  )

  return (
    <>
      <GorillaDialog
        isLightMode
        open={open}
        maxWidth="xs"
        fullWidth
        onClose={handleModalClose}
        sx={{
          '.MuiPaper-root': {
            px: { xs: 0, sm: 2 },
            py: 7.5,
          },
        }}
      >
        <DialogTitle sx={{ p: title ? '16px 24px' : 0 }}>
          {title}
          {closeBtn && (
            <IconButton
              disabled={isLoading}
              onClick={() => handleClose(false)}
              sx={{
                position: 'absolute',
                right: 2,
                top: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        {(content || children) && (
          <DialogContent>
            {children || (
              <DialogContentText
                color={(innerTheme) => innerTheme.palette.text.primary}
              >
                {content}
              </DialogContentText>
            )}
          </DialogContent>
        )}
        <DialogActions
          sx={{
            justifyContent: hideCancelBtn ? 'end' : 'center',
            mt: 2.5,
            p: 0,
          }}
        >
          <Stack
            direction="row"
            width={hideCancelBtn ? '50%' : '100%'}
            columnGap={2.5}
            px={2}
          >
            <>
              {!hideCancelBtn && (
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  fullWidth
                  onClick={() => handleClose(false)}
                >
                  {cancelBtnText}
                </Button>
              )}
              {!hideConfirmBtn && (
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => handleClose(true)}
                  autoFocus
                >
                  {okBtnText}
                </LoadingButton>
              )}
            </>
          </Stack>
        </DialogActions>
      </GorillaDialog>
    </>
  )
}
