import Axios from 'axios-observable'
import { map, Observable } from 'rxjs'

import { CreateCartPayload, ICart, IJobSubmission } from '../models'

export class CandidateCartService {
  environment: any

  axios: Axios

  constructor(environment: any, axios: any) {
    this.environment = environment
    this.axios = axios
  }

  getCartById(cartId: string, setEntitiesStatus?: boolean): Observable<ICart> {
    return this.axios
      .get(`${this.environment.JOB_API_URL}/Cart/${cartId}`, {
        params: {
          setEntitiesStatus,
        },
      })
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  getCartByEntity(
    parentEntityType: string,
    parentEntityId: string,
    setEntitiesStatus?: boolean
  ): Observable<ICart[]> {
    return this.axios
      .get<ICart[]>(
        `${this.environment.JOB_API_URL}/Cart/${parentEntityType}/${parentEntityId}`,
        {
          params: {
            setEntitiesStatus,
          },
        }
      )
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  getCartJobSubmissions(
    cartId: string,
    parentEntityType?: string,
    parentEntityId?: string
  ): Observable<IJobSubmission[]> {
    return this.axios
      .get<IJobSubmission[]>(
        `${this.environment.JOB_API_URL}/Cart/job-submissions`,
        {
          params: {
            cartId,
            parentEntityType,
            parentEntityId,
          },
        }
      )
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  createCart(cart: CreateCartPayload): Observable<ICart> {
    return this.axios
      .post<ICart>(`${this.environment.JOB_API_URL}/Cart`, cart)
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  addCandidatesToCart(
    cartId: string,
    jobSubmissionIds: string[],
    setEntityStatus?: boolean
  ): Observable<ICart> {
    return this.axios
      .post<ICart>(
        `${this.environment.JOB_API_URL}/Cart/${cartId}/add-items`,
        {
          entitiesIds: jobSubmissionIds,
        },
        {
          params: {
            setEntityStatus,
          },
        }
      )
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }

  removeCandidatesFromCart(
    cartId: string,
    jobSubmissionIds: string[]
  ): Observable<any> {
    return this.axios.delete(
      `${this.environment.JOB_API_URL}/Cart/${cartId}/remove-items`,
      {
        data: { entitiesIds: jobSubmissionIds },
      }
    )
  }

  deleteCart(cartId: string): Observable<ICart> {
    return this.axios
      .delete(`${this.environment.JOB_API_URL}/Cart/${cartId}`)
      .pipe(
        map(({ data }) => {
          return data
        })
      )
  }
}
