import { useEffect } from 'react'

type ServiceWithAtsUserIdSetter = {
  setAtsUserId: (userId: string) => void
}
export const useSetAtsUserIdFromUrl = (
  service: ServiceWithAtsUserIdSetter
): void => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const userId = urlParams.get('UserID')
    if (userId) {
      service.setAtsUserId(userId)
    }
  }, [service])
}
