import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs'

import {
  AuthProfileType,
  HTTPResponseStatus,
  IAuthProfile,
  IAuthResponse,
  IAuthUser,
  IClient,
  IFeatureFlag,
  ITrackData,
  VendorCodes,
} from '@procom-labs/common'

import { environment } from '@submission-portal/environment'
import {
  authStorage,
  axios,
  credStorage,
  vendorStorage,
} from '@submission-portal/lib'
import { IAuthCredential } from '@submission-portal/models'

import { rollbarInstance } from '../providers'
import { performanceTrackingService } from './performance-tracking.service'

class AuthService {
  private authDataSubject = new BehaviorSubject<IAuthResponse | null>(null)

  authData$: Observable<IAuthResponse | null> =
    this.authDataSubject.asObservable()

  private clientDataSubject = new BehaviorSubject<IClient | null>(null)

  clientData$: Observable<IClient | null> =
    this.clientDataSubject.asObservable()

  private currentUserProfileSubject = new BehaviorSubject<IAuthProfile | null>(
    null
  )

  currentUserProfile$: Observable<IAuthProfile | null> =
    this.currentUserProfileSubject.asObservable()

  currentUserSubject = new BehaviorSubject<IAuthUser | null>(null)

  currentUser$: Observable<IAuthUser | null> =
    this.currentUserSubject.asObservable()

  constructor() {
    // We must start authData with a null value as it will be retrieved later by other calls.
    // If authStorage.get(true) is defined, this will cause the calls dependent on authData being undefined will not work as expected. This is
    // specially with the places that observes authDataSubject. When the value of authData is defined, this will compromise the entire flow
    // However, this is not the best. It will be fixed the right way properly when starting to use the useSelector API in all places
    this.authData = null /* authStorage.get(true) */
    axios.interceptors.response.use(
      this.handleResponse,
      this.handleResponseError
    )
  }

  // private get authData(): IAuthResponse | null {
  //   return this.authDataSubject.value
  // }

  private set authData(userObject: IAuthResponse | null) {
    this.authDataSubject.next(userObject)
    if (userObject?.user) {
      const { id, displayName, email } = userObject.user
      rollbarInstance.configure({
        payload: {
          person: {
            id,
            displayName,
            email,
          },
        },
      })
    } else {
      rollbarInstance.configure({
        payload: {
          person: {
            id: null,
          },
        },
      })
    }
  }

  setAuthData(useObject: IAuthResponse | null) {
    this.authDataSubject.next(useObject)
  }

  authorize(payload: IAuthCredential, apiKey?: string): Observable<void> {
    authStorage.clear()
    credStorage.clear()

    const apiKeyVendorIndex =
      (payload.vendorCode as VendorCodes) || VendorCodes.PCGL

    return axios
      .post<IAuthResponse>(
        `${environment.AUTH_API_URL}/Account/ats/user`,
        payload,
        {
          params: { vendor: payload.vendorCode },
          headers: {
            'api-key':
              apiKey ?? environment.PROCOM_VENDOR_API_KEYS[apiKeyVendorIndex],
          },
        }
      )
      .pipe(
        map(({ data }) => {
          const { user } = data
          if (
            user &&
            user.profiles.some(
              (p) =>
                p.profileType === AuthProfileType.AccountManager ||
                p.profileType === AuthProfileType.Recruiter
            )
          ) {
            this.currentUserSubject.next(data?.user)
            if (data?.user?.profiles) {
              const currentProfile = (): IAuthProfile | null => {
                const userProfile = data?.user?.profiles.find(
                  (profile) =>
                    profile.profileType === data?.user?.currentProfileType
                )

                return userProfile ?? null
              }
              this.currentUserProfileSubject.next(currentProfile())
            }
            this.authData = data
            authStorage.set(data)
            credStorage.set(payload)
          } else {
            throw new Error('Invalid Profile Type')
          }
        })
      )
  }

  getJobVendor(
    vendorCode: string,
    entityId: string,
    entityType: string,
    apiKey?: string
  ): Observable<string> {
    vendorStorage.clear()
    return axios
      .get<string>(`${environment.AUTH_API_URL}/Vendor/ats/vendor-code`, {
        params: {
          vendor: vendorCode,
          entityId,
          entityType,
        },
        headers: {
          'api-key':
            apiKey ??
            environment.PROCOM_VENDOR_API_KEYS[vendorCode as VendorCodes],
        },
      })
      .pipe(
        map(({ data }) => {
          vendorStorage.set({
            vendorCode: data,
            entityId,
            entityType,
          }) // Store Job Vendor Creds for current session
          return data
        })
      )
  }

  getSingleClient(): Observable<void> {
    return axios
      .get<IClient>(`${environment.AUTH_API_URL}/Client/single-client`)
      .pipe(
        map(({ data }) => {
          this.clientDataSubject.next(data)
        })
      )
  }

  sendUserActivityTrackData(data: ITrackData): Observable<any> {
    return axios.post(`${environment.AUTH_API_URL}/User/track-activity`, data)
  }

  getVendorFeatureFlags(): Observable<IFeatureFlag[]> {
    return axios
      .get<IFeatureFlag[]>(`${environment.AUTH_API_URL}/Vendor/feature-flag`)
      .pipe(map(({ data }) => data))
  }

  // arrow syntax has to be used to pass `this` reference
  private handleResponse = (response: AxiosResponse): AxiosResponse => {
    if (environment.ENABLE_PERFORMANCE_TRACKING === 'true') {
      performanceTrackingService.sendPerfTracking({ response })
    }
    return response
  }

  private handleResponseError = (error: any): any => {
    const { response, config } = error

    if (
      response?.status === HTTPResponseStatus.Unauthorized &&
      !config?.isRetryRequest
    ) {
      authStorage.clear()
      const creds = credStorage.get()
      if (creds) {
        return new Promise((resolve, reject) => {
          this.authorize(creds)
            .pipe(
              switchMap(() => {
                const newConfig: AxiosRequestConfig = {
                  ...config,
                  cancelToken: null,
                  headers: {
                    ...config.headers,
                    authorization: null,
                  },
                  isRetryRequest: true,
                }
                return axios.request(newConfig)
              })
            )
            .subscribe({
              next: (resp) => {
                resolve(resp)
              },
              error: () => {
                reject(error)
              },
            })
        })
      }
    } else if (response && response.status >= 400 && response.status !== 404) {
      rollbarInstance.error(error)
    }

    if (response && environment.ENABLE_PERFORMANCE_TRACKING === 'true') {
      performanceTrackingService.sendPerfTracking({ response, isError: true })
    }

    return Promise.reject(error)
  }
}

export const authService = new AuthService()
