import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CircularProgress } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import { useSubjectSelector } from '@procom-labs/common'
import { PDFViewer, TitledPanel, ViewportResizer } from '@procom-labs/molecules'

import { legacyCopilotStore } from '@submission-portal/stores'

export const LegacyCopilotResumeViewer: FC<BoxProps> = ({
  sx,
  ...restProps
}) => {
  const { t } = useTranslation('main')

  const { resumeFileBlob, resumeSelectionBlob, processingResume } =
    useSubjectSelector(legacyCopilotStore, [
      'resumeFileBlob',
      'resumeSelectionBlob',
      'processingResume',
    ])

  // While a resume is being processed, mostly, we cannot generate, so at least we should show loading indicator that the resume is being processed
  // Otherwise, the user will only see a blank area and will not know what is going on
  const canGenerate = !!resumeFileBlob || !!resumeSelectionBlob

  const url = resumeFileBlob || resumeSelectionBlob

  return (
    <TitledPanel
      label={t('submissionList.originalResume')}
      sx={{
        height: '100%',
        display: canGenerate || processingResume ? null : 'none',
        ...sx,
      }}
      {...restProps}
    >
      <ViewportResizer>
        {processingResume && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {processingResume && <CircularProgress />}
          </Box>
        )}
        {!processingResume && url && canGenerate && <PDFViewer file={url} />}
      </ViewportResizer>
    </TitledPanel>
  )
}
