import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxProps, Button } from '@mui/material'
import { EmailSupportLinks } from '@procom-labs/common'

interface CopilotSendFeedbackProps extends BoxProps {}

export const CopilotResumeSendFeedback: React.FC<CopilotSendFeedbackProps> = (
  boxProps
) => {
  const { t } = useTranslation('main')

  return (
    <Box
      {...boxProps}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Button
        sx={{ textTransform: 'none' }}
        href={`mailto:${EmailSupportLinks.GorillaWorks}`}
        target="_blank"
      >
        {t('submissionList.resumePrepFeedback')}
      </Button>
    </Box>
  )
}
