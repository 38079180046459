import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxProps, Button } from '@mui/material'

interface LegacyCopilotSendFeedbackProps extends BoxProps {}

export const LegacyCopilotResumeSendFeedback: React.FC<
  LegacyCopilotSendFeedbackProps
> = (boxProps) => {
  const { t } = useTranslation('main')

  return (
    <Box
      {...boxProps}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Button
        sx={{ textTransform: 'none' }}
        href="mailto:feedback@procomlabs.io"
        target="_blank"
      >
        {t('submissionList.resumePrepFeedback')}
      </Button>
    </Box>
  )
}
