import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Typography } from '@mui/material'
import {
  mapToDataArray,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  SearchableListBox,
  SelectWithOptions,
  TitledPanel,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'
import { ResumeUploader } from '@procom-labs/organisms'

import { legacyCopilotStore } from '@submission-portal/stores'
import { serviceDescriptorStore } from '@submission-portal/stores/service-descriptor-store'

import {
  useDeleteResource,
  useHandleResumeSelectorSearch,
  useResumeSelectionChange,
  useUploadedResourceChange,
  useUploadResource,
} from './legacy-copilot-resume-hooks'
import LegacyCopilotResumeUploadButton from './legacy-copilot-resume-upload-button'

const LegacyCopilotResumeSelector: FC = () => {
  const { t } = useTranslation('main')
  const { addAlert } = useAlert()
  const [isSearching, setSearchKeyword] = useHandleResumeSelectorSearch()

  const subscriptionRef = useSubscriptionRef()

  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const {
    candidateSelections,
    candidateSelection,
    resumeSelections,
    resumeSelection,
    resumeFile,
    canSelectCandidate,
  } = useSubjectSelector(legacyCopilotStore, [
    'candidateSelections',
    'candidateSelection',
    'resumeSelections',
    'resumeSelection',
    'resumeFile',
    'canSelectCandidate',
  ])

  const handleSelectCandidate = useCallback((sel) => {
    legacyCopilotStore.dispatch({
      candidateSelection: sel || '',
      resumeSelection: '',
      resumeSelections: [],
      resumeFile: null,
      resumeFileBlob: '',
      resumeSelectionBlob: '',
      resumeFileSfdtText: '',
    })
  }, [])

  useEffect(() => {
    // Upon any changes to the selected candidate, we must clear resume related data
    legacyCopilotStore.dispatch({
      resumeSelection: '',
      resumeSelections: [],
      resumeFile: null,
      resumeFileBlob: '',
      resumeFileSfdtText: '',
    })

    if (candidateSelection) {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe()
      }
      subscriptionRef.current = candidateAtsService
        .getCandidateFiles({
          atsCandidateId: Number(candidateSelection),
        })
        .subscribe({
          next: (posts) => {
            legacyCopilotStore.dispatch({
              resumeSelections: mapToDataArray(posts, 'name'),
            })
          },
          error: handleError,
        })
    }
  }, [subscriptionRef, candidateAtsService, handleError, candidateSelection])

  const handleSelectionChange = useResumeSelectionChange(
    'resumeSelection',
    'resumeSelectionBlob'
  )

  const handleResumeSelectionChange = useCallback(
    (newResumeSelection: string): void => {
      handleSelectionChange(candidateSelection, newResumeSelection)
    },
    [handleSelectionChange, candidateSelection]
  )

  const onUploadedResumeChange = useUploadedResourceChange(
    'resumeFile',
    'resumeFileBlob'
  )

  const handleDeleteResume = useDeleteResource('resumeFile', 'resumeFileBlob')

  const uploadResume = useUploadResource()

  const content = (
    <Grid container spacing={2}>
      {!resumeFile ? (
        <>
          <Grid item xs={6}>
            <SearchableListBox
              sx={{ width: '100%' }}
              placeholder={t('submissionList.selectCandidate')}
              selection={candidateSelection}
              selections={candidateSelections}
              onSelect={handleSelectCandidate}
              onChange={setSearchKeyword}
              loading={isSearching}
              disabled={!canSelectCandidate}
            />

            {candidateSelection ? (
              <SelectWithOptions
                sx={{ width: '100%', mt: '20px' }}
                placeholder={t('submissionList.noResumeSelected')}
                value={resumeSelection}
                onChange={handleResumeSelectionChange}
                disabled={!resumeSelections.length}
                displayEmpty
                options={resumeSelections}
              />
            ) : null}
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" alignItems="center" height="100%">
              <Typography align="center" style={{ width: '100%' }}>
                {t('submissionList.or')}
              </Typography>
            </Box>
          </Grid>
        </>
      ) : null}
      <Grid item xs={resumeFile ? 12 : 5} sx={{ margin: 'auto' }}>
        <Box>
          <ResumeUploader
            direction="column"
            uploadResume={uploadResume}
            deleteResume={handleDeleteResume}
            onChange={onUploadedResumeChange}
            value={resumeFile}
          >
            <LegacyCopilotResumeUploadButton>
              {t('submissionList.uploadResume')}
            </LegacyCopilotResumeUploadButton>
          </ResumeUploader>
        </Box>
      </Grid>
    </Grid>
  )

  return (
    <TitledPanel
      label={t('submissionList.selectResume')}
      sx={{ height: '100%' }}
    >
      {content}
    </TitledPanel>
  )
}

export default LegacyCopilotResumeSelector
