import React, { ChangeEvent, ReactElement, useCallback } from 'react'
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  useController,
} from 'react-hook-form'

import {
  FormControl,
  FormLabel,
  TextField,
  TextFieldProps,
} from '@mui/material'

import { useHookFieldError } from '../../hooks'

export interface TextHookFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  control?: Control<TFieldValues>
  name: TName
  allowOnlyDigits?: boolean
  allowCharOnly?: boolean
  maxDecimalPlaces?: number
  defaultValue?: PathValue<TFieldValues, TName> | undefined
  digitCount?: number
  customLabel?: boolean
  displayValue?: string
}

export const TextHookField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  InputLabelProps,
  fullWidth = true,
  allowOnlyDigits = false,
  allowCharOnly = false,
  maxDecimalPlaces = 0,
  digitCount = 0,
  helperText,
  defaultValue,
  customLabel = true,
  displayValue,
  label,
  ...restProps
}: TextFieldProps & TextHookFieldProps<TFieldValues, TName>): ReactElement => {
  const {
    field: { ref, onChange, onBlur, value, ...restField },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue,
  })

  const errText = useHookFieldError(fieldState, label)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let targetValue = ''
      if (allowOnlyDigits) {
        targetValue = String(e.target.value).replace(/\D/g, '')
      } else if (allowCharOnly) {
        targetValue = e.target.value.replace(/\d/g, '')
      } else {
        targetValue = e.target.value
      }

      if (restProps.onChange) {
        restProps.onChange(e)
      }

      if (targetValue && maxDecimalPlaces) {
        const pattern = new RegExp(`^\\d+(\\.\\d{0,${maxDecimalPlaces}})?$`)
        return pattern.test(targetValue) ? onChange(targetValue ?? null) : null
      }

      if (targetValue && digitCount) {
        const pattern = new RegExp(`^\\d{0,${digitCount}}$`)
        return pattern.test(targetValue) ? onChange(targetValue ?? null) : null
      }

      if (restProps.type === 'number') {
        return onChange(targetValue || null)
      }
      return onChange(targetValue)
    },
    [
      allowOnlyDigits,
      allowCharOnly,
      restProps,
      maxDecimalPlaces,
      digitCount,
      onChange,
    ]
  )

  const handleBlur = useCallback(
    (e) => {
      if (value && restProps.type !== 'number') {
        onChange(value?.trim())
      }
      onBlur()
      restProps.onBlur?.(e)
    },
    [onBlur, onChange, restProps, value]
  )

  const handleWheel = useCallback((e: React.WheelEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement
    if (target.type === 'number') {
      target.blur()
    }
  }, [])

  return (
    <FormControl
      disabled={restProps.disabled}
      required={restProps.required}
      fullWidth={fullWidth}
      error={!!(fieldState.invalid && errText)}
    >
      {customLabel && label ? <FormLabel>{label}</FormLabel> : null}
      <TextField
        fullWidth={fullWidth}
        {...restProps}
        {...restField}
        label={!customLabel ? label : ''}
        value={displayValue ?? value ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: InputLabelProps?.shrink === false ? undefined : true,
        }}
        error={!!(fieldState.invalid && errText)}
        helperText={errText ?? helperText}
        inputRef={ref}
        onWheelCapture={handleWheel}
      />
    </FormControl>
  )
}
