import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationDialog } from '../confirmation-dialog'

interface ConfirmDialogProps {
  onConfirm: () => void
  isConfirming: boolean
  setIsConfirming: Dispatch<SetStateAction<boolean>>
}

export const PromptConfirmationDialog: FC<ConfirmDialogProps> = ({
  onConfirm,
  isConfirming,
  setIsConfirming,
}) => {
  const { t } = useTranslation('main')

  const handleDialog = useCallback(
    (hasConfirmed = false) => {
      if (hasConfirmed) {
        onConfirm()
      }
      setIsConfirming(false)
    },
    [onConfirm, setIsConfirming]
  )

  return (
    <ConfirmationDialog
      open={isConfirming}
      handleClose={handleDialog}
      title={t('common.createJob.jobDescriptionReplacePrompt.title')}
      content={t('common.createJob.jobDescriptionReplacePrompt.content')}
      okBtnText={t(`common.btn.continue`)}
      cancelBtnText={t(`common.btn.cancel`)}
    />
  )
}
