import React, { FC } from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Grid, IconButton } from '@mui/material'
import { GorillaDrawer, GorillaStack } from '@procom-labs/atoms'
import { useSetAtsUserIdFromUrl, useSubjectSelector } from '@procom-labs/common'
import { ViewportResizer } from '@procom-labs/molecules'

// import CopilotResumeCoverPageSelector from '@submission-portal/components/resume-copilot/copilot-resume-cover-page-selector'
import { CopilotResumeGenerateButton } from '@submission-portal/components/resume-copilot/copilot-resume-generate-button'
import CopilotResumeSelector from '@submission-portal/components/resume-copilot/copilot-resume-selector'
// import { CopilotResumeEditor } from '@submission-portal/components/resume-copilot/copilot-resume-editor'
// import { CopilotResumeShowPersonalInformation } from '@submission-portal/components/resume-copilot/copilot-resume-show-personal-information'
import { CopilotResumeSendFeedback } from '@submission-portal/components/resume-copilot/copilot-resume-send-feedback'
// import { CopilotResumeViewer } from '@submission-portal/components/resume-copilot/copilot-resume-viewer'
import { CopilotResumeTemplateSelector } from '@submission-portal/components/resume-copilot/copilot-resume-template-selector'
// import { AiLoader } from '@submission-portal/components'
import { copilotStore } from '@submission-portal/stores'
import { serviceDescriptorStore } from '@submission-portal/stores/service-descriptor-store'

export const CopilotResumeCopilotView: FC = () => {
  const { resumeURL, drawerOpen } = useSubjectSelector(copilotStore, [
    'resumeURL',
    'drawerOpen',
  ])

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  useSetAtsUserIdFromUrl(candidateAtsService)

  const handleDrawerToggle = (): void => {
    copilotStore.dispatch({ drawerOpen: !drawerOpen })
  }

  return (
    <Box sx={{ p: '20px', display: 'flex', flexDirection: 'column' }}>
      <GorillaDrawer
        anchor="top"
        open={drawerOpen}
        variant="persistent"
        sx={{ height: '100%', paddingTop: '0px', width: '100%' }}
      >
        <div style={{ paddingTop: '16px' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
              <CopilotResumeSelector />
            </Grid>
            {/* <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
              <CopilotResumeCoverPageSelector />
            </Grid> */}
            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
              <CopilotResumeTemplateSelector />
            </Grid>
          </Grid>

          <Grid container spacing={4} sx={{ pt: '40px' }}>
            <Grid item xs={12}>
              <CopilotResumeGenerateButton />
            </Grid>
          </Grid>

          <Box
            sx={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <GorillaStack
              direction="row"
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <div style={{ width: '165px' }} />
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  border: '1',
                  ...(!resumeURL && { display: 'none' }),
                }}
              >
                {drawerOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <CopilotResumeSendFeedback />
            </GorillaStack>
          </Box>
        </div>
      </GorillaDrawer>
      <Grid
        container
        spacing={4}
        sx={{
          pt: '40px',
          marginTop: '-16px',
          paddingTop: '0px',
          ...(!resumeURL && { display: 'none' }),
        }}
      >
        <Box
          sx={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <GorillaStack
            direction="row"
            sx={{ width: '100%', justifyContent: 'space-between' }}
          >
            <div style={{ width: '165px' }} />
            <IconButton onClick={handleDrawerToggle} sx={{ border: '1' }}>
              {drawerOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <CopilotResumeSendFeedback />
          </GorillaStack>
        </Box>

        {resumeURL && (
          <ViewportResizer sx={{ width: '100%' }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              minHeight="500px"
              marginLeft="32px"
            >
              <iframe
                id="resume-editor"
                title="resume-editor"
                width="100%"
                height="100%"
                src={resumeURL}
                style={{ border: 'none' }}
              />
            </Box>
          </ViewportResizer>
        )}
      </Grid>
    </Box>
  )
}
