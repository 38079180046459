import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import Box, { BoxProps } from '@mui/material/Box'
import PublishIcon from '@mui/icons-material/Publish'
import { styled } from '@mui/material'

interface CopilotResumeUploadButtonProps extends BoxProps {
  buttonProps?: ButtonProps
}

const UploadButtonBox = styled(Box)`
  border: 2px dashed silver;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CopilotResumeUploadButton: React.FC<CopilotResumeUploadButtonProps> = ({
  buttonProps,
  children,
  ...boxProps
}) => {
  return (
    <UploadButtonBox {...boxProps}>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        endIcon={<PublishIcon />}
        {...buttonProps}
      >
        {children}
      </Button>
    </UploadButtonBox>
  )
}

export default CopilotResumeUploadButton
