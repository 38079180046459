import { FC } from 'react'

import { Box, Grid } from '@mui/material'
import { useSetAtsUserIdFromUrl, useSubjectSelector } from '@procom-labs/common'

import { AiLoader } from '@submission-portal/components'
import LegacyCopilotResumeCoverPageSelector from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-cover-page-selector'
import { LegacyCopilotResumeEditor } from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-editor'
import { LegacyCopilotResumeGenerateButton } from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-generate-button'
import LegacyCopilotResumeSelector from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-selector'
import { LegacyCopilotResumeSendFeedback } from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-send-feedback'
import { LegacyCopilotResumeShowPersonalInformation } from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-show-personal-information'
import { LegacyCopilotResumeTemplateSelector } from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-template-selector'
import { LegacyCopilotResumeViewer } from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-viewer'
import { legacyCopilotStore } from '@submission-portal/stores'
import { serviceDescriptorStore } from '@submission-portal/stores/service-descriptor-store'

export const LegacyCopilotResumeCopilotView: FC = () => {
  const { generating, resumeFileBlob, resumeSelectionBlob } =
    useSubjectSelector(legacyCopilotStore, [
      'generating',
      'resumeFileBlob',
      'resumeSelectionBlob',
    ])

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  useSetAtsUserIdFromUrl(candidateAtsService)

  const canGenerate = !!resumeFileBlob || !!resumeSelectionBlob

  return (
    <Box
      sx={{
        px: '20px',
        pt: '20px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
          <LegacyCopilotResumeSelector />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
          <LegacyCopilotResumeCoverPageSelector />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
          <LegacyCopilotResumeTemplateSelector />
        </Grid>
      </Grid>

      <Grid container spacing={4} sx={{ pt: '40px' }}>
        <Grid item xs={12}>
          <LegacyCopilotResumeGenerateButton />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LegacyCopilotResumeShowPersonalInformation />
        </Grid>
        <Grid item xs={12}>
          <LegacyCopilotResumeSendFeedback />
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ pt: '40px' }}>
        <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
          <LegacyCopilotResumeViewer />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ flex: 1, display: !canGenerate ? 'none' : null }}
        >
          {generating ? <AiLoader /> : <LegacyCopilotResumeEditor />}
        </Grid>
      </Grid>
    </Box>
  )
}
