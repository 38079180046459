import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Subscription } from 'rxjs'

import { Box } from '@mui/material'
import {
  IAiJobPromptPayload,
  IInterviewMetadata,
  TrackingEvents,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'
import { InterviewPreparation } from '@procom-labs/organisms'

import { jobAiService } from '@submission-portal/services/job-ai.service'

export const InterviewCopilotMain: FC = () => {
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('EntityID')
  const userId = searchParams.get('UserID')
  const { addAlert } = useAlert()
  const { t } = useTranslation('main')

  const [jobInformation, setJobInformation] =
    useState<IAiJobPromptPayload | null>(null)
  const [notes, setNotes] = useState<string[]>([])
  const [interviewMetadata, setInterviewMetadata] =
    useState<IInterviewMetadata>()

  useEffect(() => {
    let metaDataSubscriptionRef: Subscription | null = new Subscription()
    if (jobId) {
      metaDataSubscriptionRef = jobAiService
        .getInterviewMetadata(+jobId)
        .subscribe({
          next: (response) => {
            const interviewRequest = JSON.parse(response.rawRequestBody)
            const newInterviewMetadata: IInterviewMetadata = {
              questionsTypes: {
                behavioural: interviewRequest.BehavioralQuestions,
                technical: interviewRequest.TechnicalQuestions,
              },
              includeAnswers: interviewRequest.IncludeAnswers,
              language: interviewRequest.Language,
              response: response.aiResponse.replaceAll('\n', '<br>'),
            }
            setInterviewMetadata(newInterviewMetadata)
          },
        })
    }
    return () => {
      if (metaDataSubscriptionRef && !metaDataSubscriptionRef.closed) {
        metaDataSubscriptionRef.unsubscribe()
        metaDataSubscriptionRef = null
      }
    }
  }, [jobId])

  useEffect(() => {
    let subscription = new Subscription()
    if (jobId) {
      subscription = jobAiService
        .getJobInformation(jobId, { includeNotes: true })
        .subscribe({
          next: (data) => {
            setJobInformation({
              title: data.title,
              seniority: data.seniority,
              workplaceType: data.workplaceType,
              location: data.location,
              atsJobDescription: data.description,
              startDate: data.startDate,
            })
            if (data.notes) setNotes(data.notes)
          },
          error: () => {
            addAlert({
              severity: 'error',
              message: t('common.alert.somethingWrong'),
            })
          },
        })
    }
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [jobId, addAlert, t])

  return (
    <Box
      sx={{
        padding: 3,
        overflowY: 'scroll',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {jobInformation && userId && jobId && (
        <InterviewPreparation
          atsUserId={userId}
          atsJobId={jobId}
          jobTitle={jobInformation.title ?? ''}
          jobLocationString={jobInformation.location ?? ''}
          jobWorkplaceType={
            jobInformation.workplaceType ? [jobInformation.workplaceType] : null
          }
          jobStartDate={jobInformation.startDate}
          notes={notes.length ? notes : null}
          trackingEventName={
            TrackingEvents.AICopilots.InterviewQuestionsSubmissionPortal
          }
          interviewMetadata={interviewMetadata}
        />
      )}
    </Box>
  )
}
