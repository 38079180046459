import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  mapToSubmissionTemplates,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import { SelectWithOptions, TitledPanel } from '@procom-labs/molecules'

import { copilotStore, serviceDescriptorStore } from '@submission-portal/stores'

export const CopilotResumeTemplateSelector: FC = () => {
  const { t } = useTranslation('main')
  const subscriptionRef = useSubscriptionRef()

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])
  const { submissionTemplateSelections, submissionTemplateSelection } =
    useSubjectSelector(copilotStore, [
      'submissionTemplateSelections',
      'submissionTemplateSelection',
    ])

  const atsUserId = candidateAtsService.getAtsUserId()

  const handleSubmissionTemplateChange = useCallback((sel: string) => {
    copilotStore.dispatch({
      submissionTemplateSelection: sel,
    })
  }, [])

  useEffect(() => {
    if (subscriptionRef.current && !subscriptionRef.current.closed) {
      subscriptionRef.current.unsubscribe()
    }
    if (atsUserId) {
      subscriptionRef.current = candidateAtsService
        .getSubmissionTemplates()
        .subscribe({
          next: (templates) => {
            copilotStore.dispatch({
              // Set a default value
              submissionTemplateSelection: templates.find(
                (template) => template.isDefault
              )?.templateId,
              submissionTemplateSelections: mapToSubmissionTemplates(templates),
            })
          },
        })
    }
  }, [atsUserId, candidateAtsService, subscriptionRef])

  return (
    <TitledPanel
      label={t('submissionList.selectTemplate')}
      sx={{ height: '100%' }}
    >
      <SelectWithOptions
        sx={{ width: '100%', mt: '20px' }}
        placeholder={t('submissionList.noTemplateSelected')}
        value={submissionTemplateSelection}
        onChange={handleSubmissionTemplateChange}
        disabled={!submissionTemplateSelections.length}
        displayEmpty
        options={submissionTemplateSelections}
      />
    </TitledPanel>
  )
}
