import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Subject, Subscription } from 'rxjs'
import { useObservable } from 'rxjs-hooks'

import SaveIcon from '@mui/icons-material/Save'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import {
  FeatureFlagNames,
  ICandidate,
  IEmailTemplate,
  IEntityType,
  IJobSubmission,
  ITrackData,
  TrackingEvents,
  useIsFeatureFlagEnabled,
  useSubscriptionRef,
} from '@procom-labs/common'

import { useHandleSaveEmailContent } from '@submission-portal/hooks'
import { authService } from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'
import { IEmailForm } from '@submission-portal/types'
import { loadSubmissionEmailContent } from '@submission-portal/utils'

import { EmailPreview } from './email-preview'
import { PreviewModal } from './preview-modal'
import { getUpdatePayload } from './submission-update/submission-update-form'

type SubmissionReviewProps = {
  submission: IJobSubmission
  start?: Subject<string>
  pause?: Subject<string>
  templateData?: IEmailTemplate[]
  setTemplateData: React.Dispatch<
    React.SetStateAction<IEmailTemplate[] | undefined>
  >
  resetEmail: boolean
  setResetEmail: React.Dispatch<React.SetStateAction<boolean>>
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const SubmissionPreview = forwardRef<any, SubmissionReviewProps>(
  (
    {
      submission,
      start,
      pause,
      templateData,
      setTemplateData,
      resetEmail,
      setResetEmail,
      setOpenModal,
    },
    ref
  ) => {
    const { id } = submission
    const theme = useTheme()
    const currentUser = useObservable(() => authService.authData$)
    const isViewMode = useMemo(
      () => sessionStorage.getItem('isView') === 'true',
      []
    )
    const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
      FeatureFlagNames.ResumeOnePager
    )

    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [isSaving, setIsSaving] = useState(false)
    const [isDraftSaved, setIsDraftSaved] = useState(false)
    const [originalLanguage, setOriginalLanguage] = useState<string>()
    const [reFetchEmailContent, setReFetchEmailContent] = useState(false)

    const formInstance = useFormContext<IEmailForm>()

    const clientData = useObservable(() => authService.clientData$)

    const eventTrackingRef = useSubscriptionRef()
    const saveEmailContent = useHandleSaveEmailContent(
      formInstance,
      id,
      setIsSaving,
      templateData
    )

    const handleSaveEmailContent = useCallback(() => {
      saveEmailContent()
      setIsDraftSaved(true)
      setReFetchEmailContent(false)
    }, [saveEmailContent])

    useEffect(() => {
      window.scrollTo({
        top: 0,
      })
    }, [])

    useEffect(() => {
      setIsLoading(true)
      let subscription: Subscription | null = null
      subscription = loadSubmissionEmailContent(
        submission,
        formInstance,
        setTemplateData,
        setError,
        submissionStore,
        !reFetchEmailContent && !resetEmail,
        currentUser
      )
      subscription.add(() => {
        const jobSubmissionValues = getUpdatePayload(
          submission,
          IS_ONE_PAGER_ENABLED,
          currentUser?.user?.preferredLanguage
        )
        formInstance.setValue(
          'includeHighlights',
          jobSubmissionValues.includeHighlights
        )
        setResetEmail(false)
        setIsLoading(false)
      })
      return () => {
        if (subscription && !subscription.closed) {
          subscription.unsubscribe()
        }
      }
    }, [
      reFetchEmailContent,
      currentUser,
      submission,
      setTemplateData,
      resetEmail,
      setResetEmail,
    ])

    const candidate = useMemo(
      (): ICandidate => ({
        ...submission.candidate,
        showBillRate: !!clientData?.showBillRateToHiringManager,
      }),
      [submission.candidate, clientData?.showBillRateToHiringManager]
    )

    const { t, i18n } = useTranslation('main')

    const handleClosePreview = useCallback(() => {
      if (originalLanguage && originalLanguage !== i18n.language) {
        i18n.changeLanguage(originalLanguage)
      }
      setOpen(false)
    }, [setOpen, i18n, originalLanguage])
    const handleOpenPreview = useCallback(() => {
      if (i18n.language !== submission.submissionLanguage) {
        setOriginalLanguage(i18n.language)
        i18n.changeLanguage(submission.submissionLanguage)
      }
      setOpen(true)
      const data: ITrackData = {
        comments: '',
        contractProfileId: '',
        documentName: '',
        talentPoolName: '',
        entityType: IEntityType.JobSubmission,
        entityId: id,
        jobTitle: candidate.jobTitle,
        event: TrackingEvents.DataTracking.FriResumeViewInClientPortal,
        atsJobId: candidate.atsJobId,
        candidateName: candidate.name,
      }
      eventTrackingRef.current = authService
        .sendUserActivityTrackData(data)
        .subscribe()
    }, [
      setOpen,
      i18n,
      submission.submissionLanguage,
      setOriginalLanguage,
      eventTrackingRef,
      candidate,
      id,
    ])

    useEffect(() => {
      return () => {
        pause?.next('')
      }
    }, [pause])

    return (
      <Box sx={{ width: '100%' }}>
        <PreviewModal
          open={open}
          handleClose={handleClosePreview}
          jobSubmissions={[submission]}
          start={start}
          pause={pause}
        />
        <Grid
          container
          item
          xs={12}
          sm={10}
          gap={2}
          lg={6}
          sx={{
            margin: 'auto',
            marginBottom: '16px',
            justifyContent: 'end',
          }}
        >
          <Button
            disabled={isSaving || !formInstance.formState.isValid || isViewMode}
            endIcon={isSaving && <CircularProgress size={18} />}
            variant="outlined"
            type="button"
            id="btn-save-draft"
            onClick={handleSaveEmailContent}
            sx={{ backgroundColor: theme.palette.background.default }}
            startIcon={!isSaving && <SaveIcon />}
          >
            {isSaving ? t('common.btn.saving') : t('common.btn.save')}
          </Button>
          <Tooltip
            title={t('submissionDetail.reviewAndSubmit.previewBtnTooltip')}
            placement="top"
          >
            <Button
              variant="outlined"
              type="button"
              id="btn-preview-client-portal"
              onClick={handleOpenPreview}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              {t('common.btn.preview')}
            </Button>
          </Tooltip>
        </Grid>

        <EmailPreview
          isLoading={isLoading}
          error={error}
          templateData={templateData}
          setTemplateData={setTemplateData}
          containerProps={{
            xs: 12,
            sm: 10,
            lg: 6,
          }}
          hideLanguageSelect
          setReFetchEmailContent={setReFetchEmailContent}
          submission={submission}
          setIsDraftSaved={setIsDraftSaved}
          isDraftSaved={isDraftSaved}
          setOpenModal={setOpenModal}
          ref={ref}
        />
        <Grid
          container
          item
          xs={12}
          sm={10}
          lg={6}
          sx={{
            margin: 'auto',
            paddingTop: '16px',
          }}
        >
          <Grid
            item
            sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}
          >
            <Button
              disabled={
                isSaving || !formInstance.formState.isValid || isViewMode
              }
              endIcon={isSaving && <CircularProgress size={18} />}
              variant="outlined"
              type="button"
              id="btn-save-draft"
              onClick={handleSaveEmailContent}
              sx={{ backgroundColor: theme.palette.background.default }}
              startIcon={!isSaving && <SaveIcon />}
            >
              {isSaving ? t('common.btn.saving') : t('common.btn.saveDraft')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }
)
