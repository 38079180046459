import { FormikProps } from 'formik'
import i18n from 'i18next'
import { TFuncKey } from 'react-i18next'
import {
  catchError,
  finalize,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs'

import {
  AvailabilityDefaults,
  BaseSubjectStore,
  HTTPResponseStatus,
  ICandidate,
  IDocument,
  IDocumentUpload,
  IEmailPayload,
  IJobSubmission,
  IResume,
  JobStatusMapping,
  JobSubmissionAtsStatusLabels,
  mapAvailabilityToDataType,
  SuperSubject,
} from '@procom-labs/common'

import { environment } from '@submission-portal/environment'
import { axios } from '@submission-portal/lib'
import {
  IJobSubmissionReport,
  ISubmissionSummary,
  ISubmissionUpdatePayload,
  JobSubmissionReport,
} from '@submission-portal/models'
import {
  CandidateResult,
  DefaultSubmissionSearchFilter,
  SubmissionSearchFilter,
} from '@submission-portal/types'

const fields = [
  'AddedBy',
  'Candidate',
  'Contact',
  'JobTitle',
  'JobSubmissionId',
  'Status',
]

const ReviewAndSubmitField = 'ReviewAndSubmit'
const ClientActivityField = 'ClientActivity'

type IframeIdsType = {
  atsCandidateId: string
  atsUserId: string
}

interface SubmissionStoreProperties {
  submissions: ISubmissionSummary[]
  count: number
  loading: boolean
}

class SubmissionService {
  submissionUpdateFormPropSubject = new SuperSubject(
    {} as Partial<FormikProps<ISubmissionUpdatePayload>>
  )

  private iFrameIdsSubject = new SuperSubject<IframeIdsType>({
    atsCandidateId: '',
    atsUserId: '',
  })

  iFrameIds$ = this.iFrameIdsSubject.observable$

  setIFrameIds = (ids: IframeIdsType): void => {
    this.iFrameIdsSubject.value = ids
  }

  private entityId: string | null = localStorage.getItem('entityId')

  private currentSubmissionSubject = new SuperSubject<IJobSubmission | null>(
    null
  )

  currentSubmission$ = this.currentSubmissionSubject.observable$

  private isTextToSFDTLoadingSubject = new SuperSubject<boolean>(false)

  isTextToSFDTLoading$ = this.isTextToSFDTLoadingSubject.observable$

  isGeneratingJobSubmissionReportSubject = new SuperSubject<boolean>(false)

  isGeneratingJobSubmissionReport$ =
    this.isGeneratingJobSubmissionReportSubject.observable$

  jobSubmissionReportSubject = new SuperSubject<JobSubmissionReport[] | null>(
    null
  )

  jobSubmissionReport$ = this.jobSubmissionReportSubject.observable$

  isSecondLoadSubject = new SuperSubject<boolean>(false)

  isSecondLoad$ = this.isSecondLoadSubject.observable$

  currentIndexSubject = new SuperSubject<number>(0)

  currentIndex$ = this.currentIndexSubject.observable$

  private textSfdtSubject = new SuperSubject<string>('')

  textSfdt$ = this.textSfdtSubject.observable$

  getSubmissionData<T extends SubmissionStoreProperties>(
    store: BaseSubjectStore<T>,
    entityId: string | null,
    searchFilter: SubmissionSearchFilter
  ): Observable<ISubmissionSummary[]> {
    store.dispatch({
      loading: true,
    } as Partial<T>) // casting as Partial<T>
    return this.fetchSubmissions(entityId, searchFilter).pipe(
      tap(({ submissions, count }) => {
        store.dispatch({
          submissions,
          count,
        } as Partial<T>)
      }),
      map((data) => data.submissions),
      finalize(() => {
        store.dispatch({
          loading: false,
        } as Partial<T>)
      })
    )
  }

  fetchSubmissions(
    entityId: string | null,
    searchFilter: SubmissionSearchFilter = DefaultSubmissionSearchFilter
  ): Observable<{ submissions: ISubmissionSummary[]; count: number }> {
    const {
      keyword,
      pageSize,
      currentPage,
      sortField,
      sortOrder,
      clientActivity,
      reviewAndSubmit,
    } = searchFilter

    const dateFilters = ['dateAdded', 'dateLastModified'].reduce(
      (accumulator, field) => {
        const range = (searchFilter as any)[`${field}Range`]
        if (range && (range.startDate || range.endDate)) {
          const newObject = {
            fieldName: field,
            ...(range.startDate && { fromDateTime: range.startDate }),
            ...(range.endDate && { toDateTime: range.endDate }),
          }
          return [...accumulator, newObject]
        }
        return accumulator
      },
      [] as { fieldName: string; fromDateTime?: string; toDateTime?: string }[]
    )

    if (entityId) {
      this.entityId = entityId
      localStorage.setItem('entityId', entityId)
    }

    const startIndex = currentPage * pageSize
    const orderBy = sortField ? `${sortField} ${sortOrder}` : undefined

    const searchQueries = keyword
      ? fields.map((field) => ({
          fieldName: field,
          condition: keyword,
        }))
      : undefined

    const searchFilters = (() => {
      const filters = []
      if (reviewAndSubmit.length > 0) {
        filters.push({
          fieldName: ReviewAndSubmitField,
          condition: reviewAndSubmit
            .map((status) =>
              i18n.t(
                `submissionList.reviewAndSubmitStatuses.${status}` as TFuncKey
              )
            )
            .join(','),
        })
      }

      if (clientActivity.length > 0) {
        filters.push({
          fieldName: ClientActivityField,
          condition: clientActivity.join(','),
        })
      }

      return filters.length > 0 ? filters : null
    })()

    return axios
      .post(`${environment.JOB_API_URL}/JobSubmission/ats-direct`, {
        jobOrderId: this.entityId,
        startIndex,
        count: pageSize,
        orderBy,
        dateFilters,
        searchQueries,
        searchFilters,
      })
      .pipe(
        map(({ data: { data: submissions, total: count } }) => {
          return { submissions, count }
        })
      )
  }

  getSubmissionList(entityId: string | null): Observable<ISubmissionSummary[]> {
    if (entityId) {
      this.entityId = entityId
      localStorage.setItem('entityId', entityId)
    }

    return axios
      .get(
        `${environment.JOB_API_URL}/JobSubmission/ats-direct/${this.entityId}`
      )
      .pipe(
        map(({ data: { data } }) => {
          return data
        })
      )
  }

  submitCandidate(
    portalId: string,
    params: {
      [key: string]: string | boolean
    },
    emailContent?: string
  ): Observable<ISubmissionSummary> {
    return axios
      .post(
        `${environment.JOB_API_URL}/JobSubmission/${portalId}/submit-candidate`,
        emailContent ? JSON.stringify(emailContent) : '',
        {
          params,
        }
      )
      .pipe(
        map(({ data }) => data),
        catchError((error) => throwError(() => new Error(error)))
      )
  }

  testSubmit(): Observable<ISubmissionSummary[]> {
    return axios
      .get(
        `${environment.JOB_API_URL}/JobSubmission/ats-direct/${this.entityId}`
      )
      .pipe(
        map(({ data: { data } }) => {
          return data
        })
      )
  }

  submitMultipleCandidates(
    cartId: string,
    emailContent?: string
  ): Observable<ISubmissionSummary> {
    return axios
      .post(
        `${environment.JOB_API_URL}/JobSubmission/${cartId}/submit-multiple-candidates`,
        emailContent ? JSON.stringify(emailContent) : ''
      )
      .pipe(
        map(({ data }) => data),
        catchError((error) => throwError(() => new Error(error)))
      )
  }

  submissionStatus(
    submissionId: string
  ): Observable<JobSubmissionAtsStatusLabels> {
    return axios
      .get(
        `${environment.JOB_API_URL}/JobSubmission/ats/${submissionId}/submission-status`
      )
      .pipe(map(({ data }) => data))
  }

  submissionStatuses(): Observable<JobStatusMapping[]> {
    return axios
      .get(`${environment.JOB_API_URL}/JobSubmission/ats/submission-statuses`)
      .pipe(map(({ data }) => data))
  }

  getJobSubmission(id: string): Observable<IJobSubmission> {
    return axios
      .get(`${environment.JOB_API_URL}/JobSubmission/${id}`)
      .pipe(map(({ data }) => data))
  }

  updateStatus(payload: Partial<ICandidate>): Observable<ICandidate> {
    return axios
      .put(`${environment.JOB_API_URL}/JobSubmission/update-status`, payload)
      .pipe(
        map(({ data }) => data),
        catchError((error) => {
          // Handle the error as needed
          return throwError(() => new Error(error))
        })
      )
  }

  updatePrepSubmission(
    payload: Partial<IJobSubmission>
  ): Observable<ICandidate> {
    return axios
      .put(`${environment.JOB_API_URL}/JobSubmission`, payload)
      .pipe(map(({ data }) => data))
  }

  saveCandidateHighlights(id: string, highlights: string): Observable<any> {
    return axios.put(
      `${environment.JOB_API_URL}/JobSubmission/${id}/save-highlights`,
      highlights
    )
  }

  selectForPrep(atsJobId: number, atsCandidateId: number): Observable<boolean> {
    return axios
      .post(
        `${environment.JOB_API_URL}/JobSubmission/ats/${atsCandidateId}/select-for-prep/${atsJobId}`
      )
      .pipe(
        map(() => true),
        catchError((error) => {
          // Handle the error as needed
          return throwError(() => new Error(error))
        })
      )
  }

  searchSubmissions(
    jobOrderId: number,
    keyword: string,
    count: number,
    startIndex: number,
    orderBy?: string
  ): Observable<CandidateResult> {
    const searchFields: string[] = [
      'CandidateFullName',
      'CandidateFirstName',
      'CandidateLastName',
    ]

    const searchQueries = keyword
      ? searchFields.map((field) => ({
          Condition: keyword,
          FieldName: field,
        }))
      : undefined

    const requestData = {
      jobOrderId,
      count,
      startIndex,
      orderBy,
      ...(searchQueries && { SearchQueries: searchQueries }),
    }

    return axios
      .post(
        `${environment.JOB_API_URL}/Candidate/ats/search-candidates`,
        requestData
      )
      .pipe(
        map(({ data: { data, total } }) => {
          return {
            candidates: data,
            count: total,
          }
        }),
        catchError((error) => {
          // Handle the error as needed
          return throwError(() => new Error(error))
        })
      )
  }

  getSubmissionDetail(id: number): Observable<IJobSubmission> {
    return axios.get(`${environment.JOB_API_URL}/JobSubmission/ats/${id}`).pipe(
      map(({ data }) => {
        this.currentSubmissionSubject.value = data
        return data
      }),
      catchError((error) => {
        const { status } = error.toJSON()

        if (status === HTTPResponseStatus.NotFound) {
          return this.createSubmissionFromAts(id)
        }

        return throwError(() => new Error(error))
      })
    )
  }

  createSubmissionFromAts(id: number): Observable<IJobSubmission> {
    return axios
      .post<IJobSubmission>(
        `${environment.JOB_API_URL}/JobSubmission/ats/${id}`,
        {}
      )
      .pipe(
        map(({ data }) => {
          this.currentSubmissionSubject.value = data
          return data
        })
      )
  }

  uploadResume(
    formData: FormData,
    onUploadProgress: (event: ProgressEvent) => void
  ): Observable<Partial<IResume>> {
    return axios
      .post<string>(
        `${environment.JOB_API_URL}/JobSubmission/resume`,
        formData,
        {
          onUploadProgress,
        }
      )
      .pipe(map(({ data }) => ({ fileStorageId: data })))
  }

  uploadAdditionalDocuments(
    formData: FormData,
    onUploadProgress: (event: ProgressEvent) => void
  ): Observable<Partial<IDocument>> {
    return axios
      .post<IDocumentUpload>(
        `${environment.JOB_API_URL}/JobSubmission/additional-documents`,
        formData,
        {
          onUploadProgress,
        }
      )
      .pipe(
        map(({ data }) => {
          return {
            fileStorageId: data.fileStorageId,
            uploadedBy: data.uploadedBy,
            uploadedOn: data.uploadedOn,
          }
        })
      )
  }

  deleteSubmissionResume(
    atsJobSubmissionId: string,
    fileId: string
  ): Observable<any> {
    const url = `${
      environment.JOB_API_URL
    }/JobSubmission/${atsJobSubmissionId}/resume?${new URLSearchParams({
      fileId: `${fileId}`,
    })}`
    return axios.delete(url).pipe(tap(() => this.updateSubmissionResume(null)))
  }

  deleteSubmissionDocument(
    atsJobSubmissionId: string,
    fileId: string
  ): Observable<any> {
    const url = `${
      environment.JOB_API_URL
    }/JobSubmission/${atsJobSubmissionId}/additional-documents?${new URLSearchParams(
      {
        fileId: `${fileId}`,
      }
    )}`
    return axios
      .delete(url)
      .pipe(tap(() => this.updateSubmissionAdditionalDocuments(null, fileId)))
  }

  private buildCandidatePayload(
    updatePayload: ISubmissionUpdatePayload,
    resetEmail: boolean = false
  ): ICandidate {
    const submission = this.currentSubmissionSubject.value
    if (!submission) {
      return {} as ICandidate
    }
    const {
      billRate,
      comments,
      currency,
      salary,
      customAvailability,
      dateAvailable,
      availability,
      includeHighlights,
      ratePeriodType,
      isOnePagerEnabled,
      location,
      workplaceType,
    } = updatePayload

    return {
      ...submission.candidate,
      comments,
      currency,
      salary: salary ? +salary.replace(/[^\d.]/gi, '') : null,
      billRate: billRate ? +billRate.replace(/[^\d.]/gi, '') : null,
      ratePeriodType: ratePeriodType.toLowerCase(),
      ...(resetEmail ? { submissionEmailTemplate: null } : {}),
      dateAvailable:
        availability === AvailabilityDefaults.DATE ? dateAvailable : undefined,
      dateAvailableUserInput:
        availability === AvailabilityDefaults.OTHER
          ? customAvailability
          : undefined,
      dateAvailableType: mapAvailabilityToDataType(availability),
      includeCandidateHighlightsInEmail: includeHighlights,
      isOnePagerEnabled,
      address: location?.addressLine1 ?? '',
      city: location?.city ?? '',
      state: location?.state ?? '',
      countryName: location?.country ?? undefined,
      countryCode: location?.countryCode ?? undefined,
      stateCode: location?.stateCode ?? undefined,
      latitude: location?.latitude ?? null,
      longitude: location?.longitude ?? null,
      zip: location?.postalCode ?? undefined,
      workplaceType,
    }
  }

  updateSubmission(
    updatePayload: ISubmissionUpdatePayload,
    resetEmail: boolean = false
  ): Observable<void> {
    const submission = this.currentSubmissionSubject.value
    if (!submission) {
      return of()
    }
    const { submissionLanguage } = updatePayload

    const candidatePayload = this.buildCandidatePayload(
      updatePayload,
      resetEmail
    )
    const payload: IJobSubmission = {
      ...submission,
      submissionLanguage,
      candidate: candidatePayload,
    }

    return axios
      .put<IJobSubmission>(`${environment.JOB_API_URL}/JobSubmission`, payload)
      .pipe(
        map(({ data }) => {
          this.currentSubmissionSubject.value = data
        })
      )
  }

  getResume(id: string): Observable<ArrayBuffer> {
    const url = `${environment.JOB_API_URL}/File/resume?${new URLSearchParams({
      fileId: `${id}`,
    })}`
    return axios
      .get<ArrayBuffer>(url, {
        responseType: 'arraybuffer',
      })
      .pipe(map(({ data }) => data))
  }

  getSubmissionEmail(
    id: string,
    params?: {
      getSavedTemplates: boolean
      language?: string
      multipleCandidates?: boolean
      email?: string
    }
  ): Observable<IEmailPayload> {
    const { email, multipleCandidates, ...restParams } = params || {}
    const queryParams = {
      ...restParams,
      multipleCandidates: multipleCandidates ?? false,
      ...(email !== undefined && { email }),
    }
    return axios
      .get(`${environment.JOB_API_URL}/JobSubmission/${id}/email-preview`, {
        params: queryParams,
      })
      .pipe(map(({ data }) => data))
  }

  updateSubmissionEmail(
    id: string,
    payload: IEmailPayload,
    multipleCandidates?: boolean
  ): Observable<ISubmissionSummary> {
    return axios
      .put(
        `${environment.JOB_API_URL}/JobSubmission/${id}/submission-email`,
        payload,
        {
          params: {
            multipleCandidates,
          },
        }
      )
      .pipe(
        map(({ data }) => {
          if (this.currentSubmissionSubject.value) {
            const { candidate } = this.currentSubmissionSubject.value
            this.currentSubmissionSubject.value = {
              ...this.currentSubmissionSubject.value,
              candidate: {
                ...candidate,
                submissionEmailTemplate: {
                  ...payload,
                },
              },
            }
          }

          return data
        })
      )
  }

  disableOnePagerOnJobSubmission(): Observable<void> {
    const submission = this.currentSubmissionSubject.value

    if (submission) {
      const payload: IJobSubmission = {
        ...submission,
        candidate: {
          ...submission.candidate,
          isOnePagerEnabled: false,
        },
      }

      return axios
        .put<IJobSubmission>(
          `${environment.JOB_API_URL}/JobSubmission`,
          payload
        )
        .pipe(
          map(({ data }) => {
            this.currentSubmissionSubject.value = data
          })
        )
    }
    return of()
  }

  addOnePagerIdToResume(onePagerId: string): Observable<void> {
    const submission = this.currentSubmissionSubject.value

    if (submission?.candidate.resume) {
      const payload: IJobSubmission = {
        ...submission,
        candidate: {
          ...submission.candidate,
          resume: {
            ...submission.candidate.resume,
            onePagerId,
          },
        },
      }

      return axios
        .put<IJobSubmission>(
          `${environment.JOB_API_URL}/JobSubmission`,
          payload
        )
        .pipe(
          map(({ data }) => {
            this.currentSubmissionSubject.value = data
          })
        )
    }
    return of()
  }

  updateSubmissionResume(resume: IResume | null): void {
    if (this.currentSubmissionSubject.value) {
      const { candidate } = this.currentSubmissionSubject.value
      this.currentSubmissionSubject.value = {
        ...this.currentSubmissionSubject.value,
        candidate: {
          ...candidate,
          resume,
        },
      }
    }
  }

  updateSubmissionHighlightsFiles(highlightsFile: IDocument): void {
    if (this.currentSubmissionSubject.value) {
      const { candidate } = this.currentSubmissionSubject.value
      this.currentSubmissionSubject.value = {
        ...this.currentSubmissionSubject.value,
        candidate: {
          ...candidate,
          highlightsAdditionalDocuments: [highlightsFile],
        },
      }
    }
  }

  /*
  document is not null then we add the document in list
  if fileId is not null then remove document from list
   */
  updateSubmissionAdditionalDocuments(
    document: IDocument | null,
    fileId: string | null
  ): void {
    if (this.currentSubmissionSubject.value) {
      const { candidate } = this.currentSubmissionSubject.value
      if (candidate?.additionalDocuments) {
        if (document) {
          candidate.additionalDocuments = [
            ...candidate.additionalDocuments,
            document,
          ]
        } else if (fileId) {
          candidate.additionalDocuments.forEach((doc, index) => {
            if (
              doc.fileStorageId === fileId &&
              candidate?.additionalDocuments
            ) {
              candidate.additionalDocuments.splice(index, 1)
              candidate.additionalDocuments = [...candidate.additionalDocuments]
            }
          })
        }
      }
      this.currentSubmissionSubject.value = {
        ...this.currentSubmissionSubject.value,
        candidate: {
          ...candidate,
        },
      }
    }
  }

  getTextSFDT = (text: string, formatType: string): Observable<string> => {
    this.isTextToSFDTLoadingSubject.value = false
    this.textSfdtSubject.value = ''
    const textSfdtUrl = `${environment.JOB_API_URL}/File/text-to-sfdt`

    return axios
      .post(textSfdtUrl, {
        text,
        formatType,
      })
      .pipe(
        map(({ data }) => {
          this.textSfdtSubject.value = data
          this.isTextToSFDTLoadingSubject.value = true
          return data
        })
      )
  }

  getJobSubmissionReport(payload: IJobSubmissionReport): Observable<any> {
    this.isGeneratingJobSubmissionReportSubject.value = true
    const url = `${environment.JOB_API_URL}/Report/job-submission?fromDate=${payload?.fromDate}&toDate=${payload?.toDate}&vendor=${payload.vendor}`
    return axios
      .get<any>(url, {
        headers: {
          'api-key': payload.apiKey,
        },
      })
      .pipe(
        map(({ data }) => {
          this.jobSubmissionReportSubject.value = data
          this.currentIndexSubject.value = 0
          return data
        }),
        finalize(() => {
          this.isGeneratingJobSubmissionReportSubject.value = false
        })
      )
  }

  setIsSecondLoad(flag: boolean): void {
    this.isSecondLoadSubject.value = flag
  }

  updateCurrentIndex(): void {
    this.currentIndexSubject.value += 1
  }

  resetCurrentSubmission(): void {
    this.currentSubmissionSubject.value = null
  }
}

export const submissionService = new SubmissionService()
