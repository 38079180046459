import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { List, ListItem, ListItemText, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  DateValueFormat,
  formatDates,
  IJobSubmission,
} from '@procom-labs/common'
import OnboardingSuccess from '@procom-labs/common/src/assets/svg/onboarding-success.svg'

export const SubmissionSuccess: FC<{
  submission: IJobSubmission
  openPreview: () => void
  openUpdateForm: () => void
}> = ({ submission, openPreview, openUpdateForm }) => {
  const theme = useTheme()
  const { t } = useTranslation('main')
  const emailRecipients = useMemo(() => {
    const recipients: string[] = []

    submission.candidate.submissionEmailTemplate?.emailToCc?.forEach((c) =>
      recipients.push(c)
    )

    submission.candidate.submissionEmailTemplate?.emailToBcc?.forEach((c) =>
      recipients.push(c)
    )

    return recipients.map((recipient) => {
      const found = submission.collaborators.find(
        (collaborator) => collaborator.email === recipient
      )
      if (found) {
        if (found.firstName && found.lastName) {
          return `${found.firstName} ${found.lastName}`
        }
        return found.email
      }
      return recipient
    })
  }, [submission.candidate.submissionEmailTemplate, submission.collaborators])
  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
        py: 6,
      }}
      columnGap={6}
    >
      <Grid item ml={8}>
        <img src={OnboardingSuccess} alt="Girl showing thumbs up" />
      </Grid>
      <Grid item>
        <Typography variant="h5" mb={1} component="h5">
          {t('submissionDetail.submissionSuccess.heading')}
        </Typography>
        <Typography mb={1}>
          {t('submissionDetail.submissionSuccess.body1')
            .replace(
              '{{name}}',
              `${submission.candidate.firstName} ${submission.candidate.lastName}`
            )
            .replace('{{hm}}', submission.hiringManagerName)}
        </Typography>
        <Typography mb={1}>
          {t('submissionDetail.submissionSuccess.submittedOn').replace(
            '{{day}}',
            formatDates(
              DateValueFormat.DayMonthYearTimezone,
              submission.candidate.dateSubmitted
            )
          )}
        </Typography>
        {emailRecipients.length > 0 && (
          <>
            <Typography mb={1} mt={3}>
              {t('submissionDetail.submissionSuccess.collaborators')}
            </Typography>
            <List sx={{ listStyle: 'inside' }}>
              {emailRecipients.map((c) => (
                <ListItem key={c} sx={{ py: 0, display: 'list-item' }}>
                  <ListItemText sx={{ display: 'inline-block' }}>
                    {c}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}
        <Stack direction="row" spacing={3} mt={3}>
          <Button variant="text" onClick={openUpdateForm}>
            {t('submissionDetail.submissionSuccess.btnViewDetails')}
          </Button>
          <Button variant="text" onClick={openPreview}>
            {t('submissionDetail.submissionSuccess.btnViewEmail')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}
