import { useMemo } from 'react'

import { IJob } from '@common/models'

import { useIsClientCenovus } from './use-is-client'

export interface IJobLocationObj {
  workLocation?: IJob['workLocation']
  city?: IJob['city']
  stateCode?: IJob['stateCode']
  state?: IJob['state']
  country?: IJob['country'] | null
}

export const useJobLocation = (
  job?: IJobLocationObj | null,
  includeCountry = false
): string | null => {
  const isClientCenovus = useIsClientCenovus()
  return useMemo(() => {
    if (!job) return null
    return isClientCenovus
      ? job?.workLocation || ''
      : [
          job?.city,
          job?.stateCode || job?.state,
          includeCountry ? job?.country : null,
        ]
          .filter((d) => !!d)
          .join(', ')
  }, [job, isClientCenovus, includeCountry])
}
