import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useObservable } from 'rxjs-hooks'

import { Grid } from '@mui/material'
import {
  AiFeatureLocations,
  gtmEvents,
  IAtsCandidateHighlights,
  PreferredLanguageType,
  TrackingEvents,
  useServices,
  useSubscriptionRef,
} from '@procom-labs/common'
import { useAlert } from '@procom-labs/molecules'
import { AiSummaryGenerator } from '@procom-labs/organisms'

import { environment } from '@submission-portal/environment'
import { getCandidateFiles, useTrackingWrapper } from '@submission-portal/hooks'
import { authService } from '@submission-portal/services'

export const CopilotHighlights: React.FC = () => {
  const { t } = useTranslation('main')
  const { addAlert } = useAlert()
  const [isFailedResumeCall, setIsFailedResumeCall] = useState(false)
  const subscriptionRef = useSubscriptionRef()
  const generateCandidateHighlightsRef = useSubscriptionRef()
  const { jobAiService } = useServices()
  const clientData = useObservable(() => authService.clientData$)

  const [searchParams] = useSearchParams()
  const userId = searchParams.get('UserID')
  const candidateId = searchParams.get('EntityID')

  useEffect(() => {
    if (clientData) {
      subscriptionRef.current = jobAiService
        .getAtsCandidateHighlightsStream()
        .subscribe()
    }
  }, [subscriptionRef, jobAiService, clientData])

  const { track } = useTrackingWrapper()

  getCandidateFiles(candidateId)

  const handleGenerateCandidateHighlights = useCallback(
    (preferredLanguage, atsCandidateResumeId) => {
      const payload: IAtsCandidateHighlights = {
        atsCandidateId: Number(candidateId),
        atsCandidateResumeId: atsCandidateResumeId,
        language: preferredLanguage || PreferredLanguageType.EN,
      }
      generateCandidateHighlightsRef.current = jobAiService
        .generateAtsCandidateHighlights(payload)
        .subscribe({
          error: (error) => {
            const { data } = error.response
            if (
              `${data.message}`.includes(
                'Could not get candidate resume stream with id'
              )
            ) {
              setIsFailedResumeCall(true)
              addAlert({
                severity: 'error',
                message: t('error.invalidResume'),
              })
            }
          },
          complete: () => {
            track({
              eventName: gtmEvents.GenerateAiCandidateHighlights,
              ...payload,
              featureLocation: AiFeatureLocations.Bullhorn,
            })
          },
        })
    },
    [
      candidateId,
      generateCandidateHighlightsRef,
      track,
      userId,
      addAlert,
      setIsFailedResumeCall,
    ]
  )

  const handleResetBadResumeRequestFlag = useCallback(() => {
    setIsFailedResumeCall(false)
  }, [setIsFailedResumeCall])

  return (
    <Grid
      container
      item
      direction="column"
      p={4}
      sx={{ maxWidth: '1700px', margin: '0 auto' }}
      xs={8}
    >
      <AiSummaryGenerator
        environment={environment}
        jobAiService={jobAiService}
        title={t('common.summaryGenerator.title2')}
        description={t('common.summaryGenerator.subtitle')}
        handleGenerateCandidateSummary={handleGenerateCandidateHighlights}
        isBullhornPortal
        isSubmissionPortal
        isFailedResumeCall={isFailedResumeCall}
        trackingEventName={
          TrackingEvents.AICopilots.CandidateHighlightsBullhorn
        }
        handleResetBadResumeRequestFlag={handleResetBadResumeRequestFlag}
      />
    </Grid>
  )
}
