import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { catchError, finalize, map, Observable, of, switchMap } from 'rxjs'
import { useObservable } from 'rxjs-hooks'
import * as Yup from 'yup'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { Preloader } from '@procom-labs/atoms'
import {
  AiFeatureLocations,
  IAiJobPromptPayload,
  IResume,
  PageTypes,
  TrackingEvents,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  AiJobFineTunePanel,
  PromptConfirmationDialog,
  useAlert,
} from '@procom-labs/molecules'
import {
  JobPreview,
  ResumeUploader,
  VariantTypes,
} from '@procom-labs/organisms'

import LegacyCopilotResumeUploadButton from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-upload-button'
import { environment } from '@submission-portal/environment'
import { useTrackingWrapper } from '@submission-portal/hooks'
import {
  clientJobService,
  jobDescriptionService,
} from '@submission-portal/services'
import { jobAiService } from '@submission-portal/services/job-ai.service'
import { parseTextFromFile } from '@submission-portal/utils'

export const JobGenerator: React.FC = () => {
  const { t, i18n } = useTranslation('main')
  const { addAlert } = useAlert()
  const [isGenerateTriggered, setIsGenerateTriggered] = useState<boolean>(false)
  const [isBhAccessCheckLoading, setIsBhAccessCheckLoading] = useState(true)
  const [hasValidBhUser, setHasValidBhUser] = useState(false)
  const [selectAdditionalFile, setSelectAdditionalFile] =
    useState<IResume | null>(null)
  const [jobInformation, setJobInformation] =
    useState<IAiJobPromptPayload | null>(null)

  const [templateOptions, setTemplateOptions] = useState<string[]>([])
  const [templateId, setTemplateId] = useState<string | null>(null)

  const jobDescSubRef = useSubscriptionRef()
  const templatesSubRef = useSubscriptionRef()
  const [searchParams] = useSearchParams()

  const atsJobId = searchParams.get('EntityID')
  const userId = searchParams.get('UserID')
  const [atsJobDescription, setAtsJobDescription] = useState('')
  const [additionalSourceContent, setAdditionalSourceContent] =
    useState<string>('')

  const isLoading = useObservable(() => jobAiService.isJobLoading$, false)

  const [showOverrideDescriptionPrompt, setShowOverrideDescriptionPrompt] =
    useState(false)

  const jobDescriptionPayloadRef = useRef<IAiJobPromptPayload>()

  const jobDescriptionAtsUserIdRef = useRef<string | null>()

  const jobDescription = useObservable(
    () => jobAiService.jobDescriptionDeltas$,
    ''
  )

  useEffect(() => {
    setIsGenerateTriggered(false)
    setAtsJobDescription(jobDescription)
  }, [jobDescription, setAtsJobDescription])

  useEffect(() => {
    jobDescSubRef.current = jobAiService.getJobDescriptionStream().subscribe()
  }, [jobDescSubRef])

  const isJobSavedLoading = useObservable(
    () => jobDescriptionService.isJobSavedLoading$,
    false
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        atsJobDescription: Yup.string()
          .required(t('common.validations.requiredField'))
          .nullable(),
      }),
    [t]
  )

  useEffect(() => {
    setIsBhAccessCheckLoading(true)
    jobAiService
      .getJobInformation(atsJobId, {
        requestBy: TrackingEvents.AICopilots.JobDescriptionBullhorn,
        includeNotes: false,
      })
      .pipe(
        finalize(() => setIsBhAccessCheckLoading(false)),
        switchMap((data) => {
          setJobInformation({
            title: data.title,
            seniority: data.seniority,
            workplaceType: data.workplaceType,
            location: data.location,
            atsJobDescription: data.description,
            atsJobIntakeNotes: data.intakeNotes,
          })
          setAtsJobDescription(data.description)

          return clientJobService.getJobDescriptionTemplates().pipe(
            switchMap((templatesData) => {
              setTemplateOptions(templatesData)
              if (templatesData.length > 1) {
                setTemplateId(data.employmentType ?? null)
              }
              return of(null)
            }),
            catchError(() => {
              addAlert({
                message: t('common.alert.somethingWrong'),
                severity: 'error',
              })
              return of(null)
            })
          )
        }),
        catchError(() => {
          setHasValidBhUser(false)
          addAlert({
            message: t('common.alert.somethingWrong'),
            severity: 'error',
          })
          return of(null)
        })
      )
      .subscribe({
        complete: () => {
          setHasValidBhUser(true)
        },
      })
  }, [atsJobId, templatesSubRef, addAlert, t])

  const { track } = useTrackingWrapper()

  useEffect(() => {
    if (userId) {
      track({
        eventName: 'VPV',
        pageURL: window.location.origin + window.location.pathname,
        pageDOM: window.location.origin,
        pageURI: window.location.pathname,
        pageType: PageTypes.BullhornJobGenerator,
        pageTitle: PageTypes.BullhornJobGenerator,
        language: i18n.language,
        userId,
        location: AiFeatureLocations.Bullhorn,
      })
    }
  }, [i18n.language, track, userId])

  const handleSendJobDescription = useCallback((description) => {
    setAtsJobDescription(description)
  }, [])

  const onSubmit = useCallback(
    (values) => {
      jobDescriptionService
        .saveJobDescriptionToBullhorn(atsJobId, values?.atsJobDescription)
        .subscribe({
          complete: () => {
            addAlert({
              severity: 'success',
              message: t('common.alert.jobSaved'),
            })
          },
          error: () => {
            addAlert({
              message: t('common.alert.somethingWrong'),
              severity: 'error',
            })
          },
        })
    },
    [atsJobId, addAlert, t]
  )

  const formikInstance = useFormik({
    onSubmit,
    validationSchema,
    initialValues: { atsJobDescription },
    enableReinitialize: true,
  })

  const hasDescription = formikInstance.values.atsJobDescription

  const handleGenerateJobDescription = useCallback(() => {
    if (jobDescriptionPayloadRef.current) {
      setIsGenerateTriggered(true)
      jobDescriptionPayloadRef.current = {
        ...jobDescriptionPayloadRef.current,
        additionalSourceContent,
        templateId,
      }
      jobAiService
        .generateClientJobDescription(jobDescriptionPayloadRef.current)
        .subscribe()
    }
  }, [additionalSourceContent, templateId])

  const handleGenerateAiService = useCallback(
    (payload: IAiJobPromptPayload, atsUserId?: string | null) => {
      jobDescriptionPayloadRef.current = { ...payload, atsJobId }
      jobDescriptionAtsUserIdRef.current = atsUserId

      if (hasDescription) {
        setShowOverrideDescriptionPrompt(true)
      } else {
        handleGenerateJobDescription()
      }
      return new Observable<string>()
    },
    [hasDescription, atsJobId, handleGenerateJobDescription]
  )

  const handleDeleteResume = useCallback((fileStorageId: string) => {
    return of(fileStorageId)
  }, [])

  const handleDocumentParse = useCallback(
    (file: File, extension: string, onStaticUploadProgress?: () => void) => {
      parseTextFromFile(file, extension).subscribe({
        next: (response) => {
          setAdditionalSourceContent(response)
          if (onStaticUploadProgress) onStaticUploadProgress()
        },
        error: (error) => {
          addAlert({
            severity: 'error',
            message: error.message,
          })
        },
      })
    },
    [addAlert]
  )

  const uploadResume = useCallback(
    (formData: FormData, onStaticUploadProgress) => {
      const file: File = formData.get('file') as File
      const extension = file.name.split('.').pop() || ''

      const resume: Partial<IResume> = {
        name: file.name,
        extension,
        size: `${file.size}`,
      }

      return of(
        handleDocumentParse(file, extension, onStaticUploadProgress)
      ).pipe(
        map(() => {
          return resume
        })
      )
    },
    [handleDocumentParse]
  )

  const handleChange = useCallback((file: IResume | null) => {
    if (!file) {
      setAdditionalSourceContent('')
    }
    setSelectAdditionalFile(file)
  }, [])

  const handleTemplateSelect = (event: SelectChangeEvent<unknown>): void => {
    setTemplateId(event.target.value as string)
  }

  if (isBhAccessCheckLoading) {
    return <Preloader center />
  }

  if (!hasValidBhUser) {
    return (
      <div className="center-screen">
        <Typography paragraph variant="h3">
          {t('common.alert.accessDenied')}
        </Typography>
      </div>
    )
  }

  return (
    <Grid
      container
      direction="row"
      p={4}
      sx={{ maxWidth: '1700px', margin: '0 auto', overflow: 'auto' }}
    >
      <Typography variant="h6" component="h3">
        {t('common.jobGenerator.breadcrumb')} (Beta)
      </Typography>
      <PromptConfirmationDialog
        onConfirm={() => handleGenerateJobDescription()}
        isConfirming={showOverrideDescriptionPrompt}
        setIsConfirming={setShowOverrideDescriptionPrompt}
      />

      <Grid container spacing={4} direction="row-reverse">
        <Grid item xs={12} md={4}>
          {templateOptions.length > 1 && (
            <Box>
              <FormControl>
                <Typography variant="subtitle1Label">
                  {t('jobDescriptionCopilot.selectTemplate')}
                </Typography>
                <Select
                  fullWidth
                  size="small"
                  sx={{
                    height: '44px',
                    backgroundColor: 'background.default',
                  }}
                  onChange={handleTemplateSelect}
                  value={templateId}
                >
                  {templateOptions.map((item) => (
                    <MenuItem key={item} value={item}>
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <Box mb={2} mt={1}>
            {templateOptions.length > 1 && (
              <Typography variant="subtitle1Label">
                {t('jobDescriptionCopilot.uploadDocument')}
              </Typography>
            )}
            <ResumeUploader
              uploadResume={uploadResume}
              deleteResume={handleDeleteResume}
              onChange={handleChange}
              value={selectAdditionalFile}
              variant={VariantTypes.Compact}
            >
              <LegacyCopilotResumeUploadButton>
                {t('jobDescriptionCopilot.uploadDocument')}
              </LegacyCopilotResumeUploadButton>
            </ResumeUploader>
          </Box>

          <AiJobFineTunePanel
            isLoading={isLoading}
            jobInformation={jobInformation}
            getContentStream={handleGenerateAiService}
            featureLocation={AiFeatureLocations.Bullhorn}
            sendJobDescription={handleSendJobDescription}
            trackingEventName={TrackingEvents.AICopilots.JobDescriptionBullhorn}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormikProvider value={formikInstance}>
            <JobPreview
              isLoading={isGenerateTriggered}
              environment={environment}
            />
            <LoadingButton
              loading={isJobSavedLoading}
              type="submit"
              variant="contained"
              id="btn-save-description-to-job"
              disabled={
                atsJobDescription === '' || isJobSavedLoading || isLoading
              }
              sx={{ mt: '2rem', float: 'right' }}
              onClick={() => {
                formikInstance.submitForm()
              }}
            >
              {t('common.jobGenerator.btn.saveToJob')}
            </LoadingButton>
          </FormikProvider>
        </Grid>
      </Grid>
    </Grid>
  )
}
