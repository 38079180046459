import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSubjectSelector } from '@procom-labs/common'
import { TitledPanel, ViewportResizer } from '@procom-labs/molecules'
import { DocumentEditor, IDocumentEditorRef } from '@procom-labs/organisms'

import { onDownloadCopilotResume } from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-utils'
import { environment } from '@submission-portal/environment'
import { legacyCopilotStore } from '@submission-portal/stores'

export const LegacyCopilotResumeEditor: FC = () => {
  const { t } = useTranslation('main')
  const {
    candidateSelection,
    resumeSelection,
    resumeFile,

    coverPageJobSelection,
    coverPageSelection,
    coverPageFile,
    generating,
    resumeFileSfdtText,
  } = useSubjectSelector(legacyCopilotStore, [
    'candidateSelection',
    'resumeSelection',
    'resumeFile',

    'coverPageJobSelection',
    'coverPageSelection',
    'coverPageFile',
    'generating',
    'resumeFileSfdtText',
  ])

  const documentEditorRef = useRef<IDocumentEditorRef>()

  const [resumeTitle, setResumeTitle] = useState<string>(
    t('common.form.untitled')
  )

  useEffect(() => {
    if (resumeFileSfdtText) {
      documentEditorRef?.current?.documentEditor?.open(resumeFileSfdtText)
    }
  }, [resumeFileSfdtText])

  // Reset the editor upon any changes on the generation selections
  useEffect(() => {
    legacyCopilotStore.dispatch({
      resumeFileSfdtText: '',
    })
  }, [
    candidateSelection,
    resumeSelection,
    resumeFile,
    coverPageJobSelection,
    coverPageSelection,
    coverPageFile,
  ])

  // Why do we set display to none instead of just returning null?
  // The reason is that Syncfusion's height does not work as expected when the node is rendered many times. Specially, this editor is expected
  // to be hidden and shown many times while the resume viewer spans the height.

  return (
    <TitledPanel
      label={t('submissionList.resumeCopilot')}
      sx={{
        height: '100%',
        display: !resumeFileSfdtText || generating ? 'none' : '',
      }}
    >
      <ViewportResizer sx={{ width: '100%' }}>
        <DocumentEditor
          serviceUrl={environment.SYNC_FUSION_EDITOR_SERVICE_URL}
          onDownload={onDownloadCopilotResume}
          resumeTitle={resumeTitle ?? ''}
          setResumeTitle={setResumeTitle}
          height="750px"
          ref={documentEditorRef}
          disableDownload={false}
        />
      </ViewportResizer>
    </TitledPanel>
  )
}
