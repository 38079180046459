import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { debounceTime } from 'rxjs'

import { Box, Grid, Typography } from '@mui/material'
import {
  mapToJobs,
  quickSearchPayload,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  SearchableListBox,
  SelectWithOptions,
  TitledPanel,
  useAlert,
  useDefaultErrorHandler,
  useSubjectState,
} from '@procom-labs/molecules'
import { ResumeUploader } from '@procom-labs/organisms'

import {
  useCoverPageSelectionChange,
  useDeleteResource,
  useHandleSelectCoverPageJob,
  useUploadedResourceChange,
  useUploadResource,
} from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-hooks'
import LegacyCopilotResumeUploadButton from '@submission-portal/components/legacy-resume-copilot/legacy-copilot-resume-upload-button'
import { legacyCopilotStore } from '@submission-portal/stores'
import { serviceDescriptorStore } from '@submission-portal/stores/service-descriptor-store'

const LegacyCopilotResumeCoverPageSelector: FC = () => {
  const handleSelectCoverPageJob = useHandleSelectCoverPageJob()
  const { t } = useTranslation('main')

  const [keyword$, keyword, setKeyword] = useSubjectState('')

  const keywordSubscriptionRef = useSubscriptionRef()
  const searchSubscriptionRef = useSubscriptionRef()

  const [isSearching, setIsSearching] = useState(false)

  const { addAlert } = useAlert()

  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const {
    coverPageFile,
    coverPageJobSelections,
    coverPageJobSelection,
    coverPageSelections,
    coverPageSelection,
  } = useSubjectSelector(legacyCopilotStore, [
    'coverPageFile',
    'coverPageJobSelections',
    'coverPageJobSelection',
    'coverPageSelections',
    'coverPageSelection',
  ])

  const handleSelectionChange = useCoverPageSelectionChange(
    'coverPageSelection',
    'coverPageSelectionBlob'
  )

  const handleCoverPageChange = useCallback(
    (newRecord: string): void => {
      handleSelectionChange(coverPageJobSelection, newRecord)
    },
    [handleSelectionChange, coverPageJobSelection]
  )

  useEffect(() => {
    if (
      keywordSubscriptionRef.current &&
      !keywordSubscriptionRef.current.closed
    ) {
      keywordSubscriptionRef.current.unsubscribe()
    }

    keywordSubscriptionRef.current = keyword$
      .pipe(debounceTime(250))
      .subscribe((newKeyword) => {
        if (newKeyword) {
          setIsSearching(true)

          if (
            searchSubscriptionRef.current &&
            !searchSubscriptionRef.current.closed
          ) {
            searchSubscriptionRef.current.unsubscribe()
          }

          searchSubscriptionRef.current = candidateAtsService
            .searchJobOrders(quickSearchPayload(newKeyword))
            .subscribe({
              next: (posts) => {
                setIsSearching(false)
                legacyCopilotStore.dispatch({
                  coverPageJobSelections: mapToJobs(posts.data),
                  processingResume: false,
                })
              },
              error: () => {
                setIsSearching(false)
                handleError()
              },
            })
        } else {
          legacyCopilotStore.dispatch({
            coverPageJobSelections: [],
          })
        }
      })
  }, [
    searchSubscriptionRef,
    keywordSubscriptionRef,
    candidateAtsService,
    keyword$,
    handleError,
    keyword,
  ])

  const onUploadedResumeChange = useUploadedResourceChange(
    'coverPageFile',
    'coverPageFileBlob'
  )

  const handleDeleteResume = useDeleteResource(
    'coverPageFile',
    'coverPageFileBlob'
  )

  const uploadResume = useUploadResource()

  return (
    <TitledPanel
      label={t('submissionList.selectOptionalCoverPage')}
      sx={{ height: '100%' }}
    >
      <Grid container spacing={2}>
        {coverPageFile ? null : (
          <>
            <Grid item xs={6}>
              <SearchableListBox
                sx={{ width: '100%' }}
                placeholder={t('submissionList.selectCoverPageJob')}
                selection={coverPageJobSelection}
                selections={coverPageJobSelections}
                onSelect={handleSelectCoverPageJob}
                onChange={setKeyword}
                loading={isSearching}
              />

              {coverPageJobSelection ? (
                <SelectWithOptions
                  sx={{ width: '100%', mt: '20px' }}
                  placeholder={t('submissionList.selectCoverPage')}
                  value={coverPageSelection}
                  onChange={handleCoverPageChange}
                  disabled={!coverPageSelections.length}
                  displayEmpty
                  options={coverPageSelections}
                  clearable
                />
              ) : null}
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography align="center" style={{ width: '100%' }}>
                  {t('submissionList.or')}
                </Typography>
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={coverPageFile ? 12 : 5} sx={{ margin: 'auto' }}>
          <Box>
            <ResumeUploader
              direction="column"
              uploadResume={uploadResume}
              deleteResume={handleDeleteResume}
              onChange={onUploadedResumeChange}
              value={coverPageFile}
            >
              <LegacyCopilotResumeUploadButton>
                {t('submissionList.uploadCoverPage')}
              </LegacyCopilotResumeUploadButton>
            </ResumeUploader>
          </Box>
        </Grid>
      </Grid>
    </TitledPanel>
  )
}

export default LegacyCopilotResumeCoverPageSelector
