import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { alpha, Box, Button, Grid, styled, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  CartCandidateStatuses,
  FeatureFlagNames,
  ICart,
  ICartCandidate,
  ICartEntityStatus,
  IJobSubmission,
  useIsFeatureFlagEnabled,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'

import AddToList from '@submission-portal/assets/add-to-list.jpg'
import { MultipleCandidateSuccess } from '@submission-portal/features/submission-detail/components'
import { cartService } from '@submission-portal/services'
import { submissionStore } from '@submission-portal/stores'

import { CandidateCartEmailPreview } from './candidate-cart-email-preview'
import { CandidateCartList } from './candidate-cart-list'
import { CandidateCartPrepForm } from './candidate-cart-prep-form'

const findStatusDifferences = (
  currentCart: ICart,
  fetchedCart: ICart
): ICartEntityStatus[] => {
  return currentCart.entities.filter((currentCartItem) => {
    const matchingItem = fetchedCart.entities.find(
      (fetchedCartItem) => fetchedCartItem.id === currentCartItem.id
    )
    return matchingItem && currentCartItem.status !== matchingItem.status
  })
}

const NoResultsSvg = styled('svg')(() => ({
  aspectRatio: '106/165',
}))
export const CandidateCartModalContent: FC<{
  showSuccessScreen: boolean
  preppedCandidates: ICartCandidate[]
  selectedCandidates: ICartCandidate[]
  unpreppedCandidates: ICartCandidate[]
  selectedCandidate?: ICartCandidate
  setSelectedCandidate: (candidate: ICartCandidate | undefined) => void
  handleNextToSuccessScreen: () => void
  handleScrollToTop: () => void
  showEmail: boolean
  setShowEmail: (showEmail: boolean) => void
}> = memo(
  ({
    showSuccessScreen,
    preppedCandidates,
    unpreppedCandidates,
    selectedCandidates,
    selectedCandidate,
    setSelectedCandidate,
    handleNextToSuccessScreen,
    handleScrollToTop,
    showEmail,
    setShowEmail,
  }) => {
    const theme = useTheme()
    const { t } = useTranslation('main')
    const refreshCartSubRef = useSubscriptionRef()

    const { cart, fetchedSubmissions, pendingOnePagers } = useSubjectSelector(
      submissionStore,
      ['cart', 'fetchedSubmissions', 'pendingOnePagers']
    )
    const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
      FeatureFlagNames.ResumeOnePager
    )

    const handleCartUpdate = useCallback(
      (c: ICart, statusDifferences: ICartEntityStatus[]) => {
        const fetched: IJobSubmission[] = IS_ONE_PAGER_ENABLED
          ? fetchedSubmissions.filter(
              (sub) =>
                !statusDifferences.find((diff) => diff.id === sub.id) &&
                !pendingOnePagers.find((p) => p.jobSubmissionId === sub.id)
            )
          : []

        submissionStore.dispatch({
          cart: c,
          ...(IS_ONE_PAGER_ENABLED ? { fetchedSubmissions: fetched } : {}),
        })
      },
      [IS_ONE_PAGER_ENABLED, fetchedSubmissions, pendingOnePagers]
    )

    // Get new cart data and update the store
    const refreshCart = useCallback(() => {
      if (cart) {
        refreshCartSubRef.current = cartService
          .getCartById(cart.id, true)
          .subscribe({
            next: (c) => {
              // Check if there are any status differences between the fetched cart and the current cart
              const statusDifferences = findStatusDifferences(cart, c)

              if (statusDifferences.length > 0) {
                handleCartUpdate(c, statusDifferences)
              }
            },
          })
      }
    }, [cart, refreshCartSubRef, handleCartUpdate])

    const handleBackToList = useCallback(
      (refresh: boolean = true) => {
        if (refresh) {
          refreshCart()
        }
        setSelectedCandidate(undefined)
        setShowEmail(false)
      },
      [refreshCart, setSelectedCandidate, setShowEmail]
    )

    const setCandidateCartStatus = useCallback(
      (jobSubmissionId: string, status: CartCandidateStatuses) => {
        if (cart) {
          submissionStore.dispatch({
            cart: {
              ...cart,
              entities: cart?.entities.map((entity) =>
                entity.id === jobSubmissionId ? { ...entity, status } : entity
              ),
            },
          })
        }
      },
      [cart]
    )

    const nextHandler = useCallback(() => {
      if (selectedCandidate) {
        const isCandidatePrepped =
          selectedCandidate.entityStatus === CartCandidateStatuses.Ready

        if (isCandidatePrepped) {
          handleBackToList()
          return
        }

        const currentIndex = unpreppedCandidates.findIndex(
          (c) => c?.atsJobSubmissionId === selectedCandidate?.atsJobSubmissionId
        )
        if (currentIndex < 0) return

        if (
          unpreppedCandidates.length > 1 &&
          currentIndex === unpreppedCandidates.length - 1
        ) {
          // If this is the last unprepped candidate but there are more unprepped candidates before this one,
          // move to the first unprepped candidate
          setSelectedCandidate(unpreppedCandidates[0])
          setCandidateCartStatus(
            selectedCandidate.jobSubmissionId,
            CartCandidateStatuses.Ready
          )
          handleScrollToTop()
          return
        }

        if (
          unpreppedCandidates.length === 1 &&
          currentIndex === unpreppedCandidates.length - 1
        ) {
          // If this is the last unprepped candidate and there are no more unprepped candidates before this one,
          // go back to the list
          handleBackToList()
          return
        }

        // Move to the next unprepped candidate
        setSelectedCandidate(unpreppedCandidates[currentIndex + 1])
        setCandidateCartStatus(
          selectedCandidate.jobSubmissionId,
          CartCandidateStatuses.Ready
        )
        handleScrollToTop()
      }
    }, [
      selectedCandidate,
      unpreppedCandidates,
      handleBackToList,
      setSelectedCandidate,
      handleScrollToTop,
      setCandidateCartStatus,
    ])

    const backHandler = useCallback(() => {
      const currentIndex = selectedCandidates.findIndex(
        (c) => c?.atsJobSubmissionId === selectedCandidate?.atsJobSubmissionId
      )
      if (currentIndex < 1) return

      setSelectedCandidate(selectedCandidates[currentIndex - 1])
      handleScrollToTop()
    }, [
      selectedCandidates,
      selectedCandidate?.atsJobSubmissionId,
      setSelectedCandidate,
      handleScrollToTop,
    ])

    const handleReleaseCart = useCallback(() => {
      setShowEmail(true)
    }, [])

    const handleRemoveCandidateModal = useCallback(
      (jobSubmissionId: string) => {
        submissionStore.dispatch({
          touchedCandidate: jobSubmissionId,
          isCandidateRemoveModalOpen: true,
        })
      },
      []
    )

    const enableBack = useMemo(() => {
      if (!selectedCandidate) return false
      if (selectedCandidate?.entityStatus !== CartCandidateStatuses.Ready) {
        const currentIndex = unpreppedCandidates.findIndex(
          (c) => c?.atsJobSubmissionId === selectedCandidate?.atsJobSubmissionId
        )

        if (currentIndex >= 1) {
          return true
        }
      }
      return false
    }, [selectedCandidate, unpreppedCandidates])

    const isEmailPreview = showEmail && cart
    const hasNoSelectedCandidates = !selectedCandidates?.length

    if (showSuccessScreen) {
      return (
        <MultipleCandidateSuccess totalCandidates={preppedCandidates.length} />
      )
    }
    if (isEmailPreview) {
      return (
        <CandidateCartEmailPreview
          cart={cart}
          handleBackToList={handleBackToList}
          handleNextToSuccessScreen={handleNextToSuccessScreen}
        />
      )
    }
    if (selectedCandidate) {
      return (
        <CandidateCartPrepForm
          candidate={selectedCandidate}
          nextHandler={nextHandler}
          backHandler={backHandler}
          enableBack={enableBack}
          handleBackToList={handleBackToList}
          handleScrollToTop={handleScrollToTop}
        />
      )
    }
    if (hasNoSelectedCandidates) {
      return (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.common.white,
            py: 6,
          }}
          columnGap={6}
        >
          <Grid
            item
            xs={5}
            ml={8}
            sx={{ display: 'flex', justifyContent: 'end' }}
          >
            <NoResultsSvg sx={{ width: { xs: '25vw', lg: '10vw' } }}>
              <use href="/assets/svg/no-results-character.svg#default" />
            </NoResultsSvg>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" mb={1} component="h5">
              {t('submissionList.multipleCandidates.noCandidates')}
            </Typography>
            <Typography mb={1}>
              {t('submissionList.multipleCandidates.noCandidatesBody')}
            </Typography>
            <Box
              component="img"
              src={AddToList}
              sx={{ width: '300px', mt: 2 }}
            />
          </Grid>
        </Grid>
      )
    }
    return (
      <>
        {unpreppedCandidates.length > 0 && (
          <CandidateCartList
            candidates={unpreppedCandidates}
            label={t('submissionList.multipleCandidates.unpreppedCandidates')}
            labelBackgroundColor={alpha(theme.palette.warning.light, 0.4)}
            setSelectedCandidate={setSelectedCandidate}
            removeCandidate={handleRemoveCandidateModal}
          />
        )}
        {preppedCandidates.length > 0 && (
          <CandidateCartList
            candidates={preppedCandidates}
            label={t('submissionList.multipleCandidates.preppedCandidates')}
            labelBackgroundColor={alpha(theme.palette.success.light, 0.4)}
            setSelectedCandidate={setSelectedCandidate}
            removeCandidate={handleRemoveCandidateModal}
            status={CartCandidateStatuses.Ready}
          />
        )}
        {preppedCandidates.length === selectedCandidates.length && (
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              sx={{ boxShadow: 'none !important' }}
              variant="contained"
              onClick={handleReleaseCart}
            >
              {t('submissionList.multipleCandidates.submit')}
            </Button>
          </Box>
        )}
      </>
    )
  }
)
