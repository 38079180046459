import { useCallback, useEffect, useMemo } from 'react'
import { TFuncKey, useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'

import {
  AvailabilityDefaults,
  BusinessType,
  CandidateOnePagerService,
  candidateOnePagerStore,
  capitalize,
  CurrencyTypes,
  DateAvailableDataTypes,
  FeatureFlagNames,
  HighlightTypes,
  IAdditionalHighlight,
  ICandidate,
  ICandidateOnePager,
  IOnePagerForm,
  mapDataTypeToAvailability,
  RatePeriod,
  useIsFeatureFlagEnabled,
} from '@procom-labs/common'

import {
  getFixedNumber,
  getSkillGroups,
  getVisibilityMap,
} from './one-pager-utils'

interface IUseCandidateOnePager {
  getHighlightText: (highlight: IAdditionalHighlight) => string
  getFormDefaultValues: (onePager: ICandidateOnePager) => IOnePagerForm
  getSkillLabel: (businessType: string, type: string) => string
}

export const useCandidateOnePager = (): IUseCandidateOnePager => {
  const { t } = useTranslation()

  const getHighlightText = useCallback(
    (highlight: IAdditionalHighlight): string => {
      if (
        Object.values(HighlightTypes).includes(highlight.key as HighlightTypes)
      ) {
        return `${t(
          `organisms.candidateOnePager.highlights.${highlight.key}` as TFuncKey
        )}: ${highlight.value}`
      }
      return highlight.value
    },
    [t]
  )

  const getSkillLabel = useCallback(
    (businessType: string, type: string) => {
      if (
        businessType.toLowerCase() === BusinessType.SOFT &&
        type.toLowerCase() === BusinessType.SOFT
      ) {
        return t('organisms.candidateOnePager.softSkills')
      }
      if (
        businessType.toLowerCase() === BusinessType.TECHNICAL &&
        type.toLowerCase() === BusinessType.TECHNICAL
      ) {
        return t('organisms.candidateOnePager.technical')
      }

      if (
        businessType.toLowerCase() === BusinessType.LEADERSHIP &&
        type.toLowerCase() === BusinessType.LEADERSHIP
      ) {
        return t('organisms.candidateOnePager.leadership')
      }
      return capitalize(type)
    },
    [t]
  )

  const getFormDefaultValues = useCallback(
    (onePager: ICandidateOnePager): IOnePagerForm => {
      const {
        rate,
        rateType,
        currency,
        contract,
        workplaceType,
        dateAvailable,
        dateAvailableType,
        dateAvailableUserInput,
      } = onePager.candidateDescription

      const skillGroups = getSkillGroups(
        onePager.skills.skills,
        onePager.parsedResumeData.skills
      )
      return {
        visibility: getVisibilityMap(onePager.fieldsVisibility),
        candidateTitle: onePager.candidateTitle,
        candidate: {
          location: onePager.candidateDescription.location,
          rate,
          rateType: rateType ?? RatePeriod.Hour,
          currency: currency ?? CurrencyTypes.CAD,
          contract: contract ?? '',
          workplaceType: workplaceType ?? [],
          availability:
            dateAvailable && !dateAvailableType
              ? AvailabilityDefaults.DATE
              : mapDataTypeToAvailability(dateAvailableType ?? ''),
          dateAvailable: dateAvailable ?? '',
          customAvailability:
            dateAvailableType === DateAvailableDataTypes.Custom &&
            dateAvailableUserInput
              ? dateAvailableUserInput
              : '',
          summary: onePager.parsedResumeData.careerSummary?.summary ?? '',
        },
        workExperience: onePager.parsedResumeData.employments.map((job) => ({
          startDate: job.startDate,
          endDate: job.endDate,
          title: job.title,
          industryName: job.industryName,
          description: job.description,
        })),
        education: onePager.parsedResumeData.educations.map((education) => ({
          startDate: education.startDate,
          endDate: education.endDate,
          qualification: education.qualification,
          institution: education.institution,
          description: education.description,
        })),
        certifications: onePager.parsedResumeData.certifications.map(
          (certification) => ({
            name: certification.name,
            dateReceived: certification.dateReceived,
          })
        ),
        additionalHighlights: onePager.additionalHighlights.map(
          (highlight) => ({
            key: highlight.key,
            value: getHighlightText(highlight),
          })
        ),
        skills: {
          minYears: onePager.skills.minYears,
          maxYears: onePager.skills.maxYears,
          groups: skillGroups.map((group) => ({
            type: getSkillLabel(group.businessType, group.type),
            businessType: group.businessType,
            skills: group.skills.map((skill) => ({
              name: skill.name,
              yearsOfExperience: skill.yearsOfExperience
                ? getFixedNumber(skill.yearsOfExperience)
                : 0,
            })),
          })),
        },
        jobs: {
          minYears: onePager.jobs.minYears,
          maxYears: onePager.jobs.maxYears,
          jobs:
            onePager.jobs.jobs?.map((job) => ({
              name: job.name,
              yearsOfExperience: job.yearsOfExperience
                ? getFixedNumber(job.yearsOfExperience)
                : 0,
            })) ?? [],
        },
        industries: onePager.industries.map((industry) => ({
          name: industry.name,
          yearsOfExperience: industry.yearsOfExperience
            ? getFixedNumber(industry.yearsOfExperience)
            : 0,
        })),
        roles: onePager.openToRoles,
      }
    },
    [getHighlightText, getSkillLabel]
  )

  return { getHighlightText, getFormDefaultValues, getSkillLabel }
}

export const useLoadCandidateOnePager = (
  candidateOnePagerService: CandidateOnePagerService,
  candidate: ICandidate | null | undefined
): { showOnePager: boolean } => {
  const { t } = useTranslation('main')
  const IS_ONE_PAGER_ENABLED = useIsFeatureFlagEnabled(
    FeatureFlagNames.ResumeOnePager
  )

  const showOnePager: boolean = useMemo(
    () =>
      !!(
        candidate?.isOnePagerEnabled &&
        candidate?.resume?.onePagerId &&
        IS_ONE_PAGER_ENABLED
      ),
    [
      IS_ONE_PAGER_ENABLED,
      candidate?.isOnePagerEnabled,
      candidate?.resume?.onePagerId,
    ]
  )

  useEffect(() => {
    let subscription: Subscription | null = null
    if (showOnePager && candidate?.resume?.onePagerId) {
      subscription = candidateOnePagerService
        .getCandidateOnePager(candidate.resume.onePagerId)
        .subscribe({
          next: (data) => {
            candidateOnePagerStore.dispatch({
              onePager: data,
              error: null,
            })
          },
          error: () => {
            candidateOnePagerStore.dispatch({
              error: t('organisms.candidateOnePager.errorNotFound'),
            })
          },
        })
    }
    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe()
      }
    }
  }, [candidate?.resume?.onePagerId, showOnePager, t, candidateOnePagerService])

  return { showOnePager }
}
