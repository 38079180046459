import React from 'react'
import parse from 'autosuggest-highlight/parse'
import { throttle } from 'lodash-es'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import {
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  InputAdornment,
  SxProps,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { GorillaAlert } from '@procom-labs/atoms'
import { ILocation } from '@procom-labs/common'

import { Wrapper } from '@googlemaps/react-wrapper'

export type LocationAutocompleteProps = Pick<
  TextFieldProps,
  | 'fullWidth'
  | 'label'
  | 'variant'
  | 'sx'
  | 'placeholder'
  | 'InputLabelProps'
  | 'helperText'
  | 'inputRef'
  | 'size'
> & {
  name: string
  freeSolo?: boolean
  enableCurrentLocation?: boolean
  value: ILocation | null
  required?: boolean
  error?: boolean
  initialValue?: string
  onChange: (event: any) => void
  dataGaId?: string
  disabled?: boolean
  ignoreAddressLine2?: boolean
  readOnly?: boolean
  customLabel?: boolean
  disableComponentRestriction?: boolean
  formLabelStyle?: SxProps
}

type WrapperProps = LocationAutocompleteProps & {
  apiKey: string
}

const addressFieldsMapping: {
  compKey: string
  locationKey: keyof ILocation
  locationCodeKey?: keyof ILocation
}[] = [
  { compKey: 'locality', locationKey: 'city' },
  {
    compKey: 'administrative_area_level_1',
    locationKey: 'state',
    locationCodeKey: 'stateCode',
  },
  {
    compKey: 'country',
    locationKey: 'country',
    locationCodeKey: 'countryCode',
  },
  { compKey: 'postal_code', locationKey: 'postalCode' },
  { compKey: 'route', locationKey: 'streetName' },
  { compKey: 'street_number', locationKey: 'streetNumber' },
  { compKey: 'subpremise', locationKey: 'subPremise' },
]

const autocompleteService: {
  current: google.maps.places.AutocompleteService | null
} = { current: null }

const geocoderService: {
  current: google.maps.Geocoder | null
} = { current: null }

const MainComponent: React.FC<LocationAutocompleteProps> = ({
  name,
  value,
  onChange,
  freeSolo,
  enableCurrentLocation,
  required,
  error,
  initialValue,
  ignoreAddressLine2 = false,
  dataGaId,
  customLabel = false,
  formLabelStyle,
  label,
  disableComponentRestriction,
  ...rest
}: LocationAutocompleteProps) => {
  const currentPlace = React.useMemo(
    () =>
      value?.addressLine1
        ? ({
            description: value.addressLine1,
          } as google.maps.places.AutocompletePrediction)
        : null,
    [value]
  )

  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<
    google.maps.places.AutocompletePrediction[]
  >([])

  const { t } = useTranslation('main')

  const execOnChange = (location: Partial<ILocation> | null): void => {
    onChange({
      target: {
        name,
        value: location,
      },
    })
  }

  const geocode = (opts: google.maps.GeocoderRequest): void => {
    const location: Partial<ILocation> = opts.address
      ? {
          addressLine1: opts.address,
        }
      : {}

    if (!geocoderService.current && window.google) {
      geocoderService.current = new window.google.maps.Geocoder()
    }
    if (geocoderService.current) {
      geocoderService.current
        .geocode(opts)
        .then(({ results }: google.maps.GeocoderResponse) => {
          if (results && results.length) {
            const result = results[0]
            if (location.addressLine1 && !ignoreAddressLine2) {
              location.addressLine2 = location.addressLine1
            }
            location.addressLine1 =
              result.formatted_address || location.addressLine1

            if (result.geometry?.location) {
              location.latitude = result.geometry.location.lat().toString()
              location.longitude = result.geometry.location.lng().toString()
            }

            if (result.address_components) {
              addressFieldsMapping.forEach(
                ({ compKey, locationKey, locationCodeKey }) => {
                  const comp = result.address_components.find(
                    (o) => o.types.indexOf(compKey) > -1
                  )
                  if (comp) {
                    location[locationKey] = comp.long_name
                    if (locationCodeKey) {
                      location[locationCodeKey] = comp.short_name
                    }
                  }
                }
              )
              if (location?.countryCode && location?.stateCode) {
                location.stateIsoCode = `${location.countryCode}-${location.stateCode}`
              }
            }
            execOnChange(location)
          }
        })
    } else if (freeSolo) {
      execOnChange(location)
    }
  }

  const handleCurrentLocation = (): void => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const location = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        }
        geocode({
          location,
        })
      })
    }
  }

  const handleChange = (
    option: google.maps.places.AutocompletePrediction | null
  ): void => {
    if (option) {
      geocode({ address: option.description })
    } else {
      execOnChange({})
    }
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: (
            results: google.maps.places.AutocompletePrediction[] | null
          ) => void
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback)
        },
        200
      ),
    []
  )
  React.useEffect(() => {
    if (initialValue) {
      geocode({ address: initialValue })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(currentPlace ? [currentPlace] : [])
      return undefined
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: disableComponentRestriction ? [] : ['ca', 'us'],
        },
      },
      (results: google.maps.places.AutocompletePrediction[] | null) => {
        if (active) {
          let newOptions: google.maps.places.AutocompletePrediction[] = []

          if (
            currentPlace &&
            !results?.find((o) => o.description === currentPlace.description)
          ) {
            newOptions = [currentPlace]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
        }
      }
    )

    return () => {
      active = false
    }
  }, [currentPlace, inputValue, fetch])

  return (
    <FormControl
      sx={formLabelStyle}
      required={required}
      fullWidth={rest.fullWidth}
      disabled={rest.disabled}
      error={error}
    >
      {customLabel ? <FormLabel>{label}</FormLabel> : null}
      <Autocomplete
        sx={{
          '.MuiAutocomplete-inputRoot': {
            paddingX: '8px !important',
          },
        }}
        noOptionsText={t('common.inputs.autocomplete.noOptionsText')}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        isOptionEqualToValue={(option, val) =>
          val && option
            ? option.description?.trim().toLowerCase() ===
              val.description?.trim().toLowerCase()
            : false
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions={!!freeSolo}
        popupIcon={null}
        disableClearable
        freeSolo={freeSolo}
        fullWidth={rest.fullWidth}
        readOnly={rest.readOnly}
        disabled={rest.disabled}
        value={
          (currentPlace as google.maps.places.AutocompletePrediction) ?? ''
        }
        onChange={(
          event: any,
          newValue: google.maps.places.AutocompletePrediction | string | null
        ) => {
          if (typeof newValue !== 'string') {
            setOptions(newValue ? [newValue, ...options] : options)
            handleChange(newValue)
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
          if (
            value?.addressLine1?.trim()?.toLowerCase() !==
            newInputValue?.trim()?.toLowerCase()
          ) {
            if (freeSolo) {
              execOnChange({ addressLine1: newInputValue })
            } else {
              execOnChange({})
            }
          }
        }}
        renderInput={(params) => {
          const args = {
            ...params,
            InputProps: {
              ...params.InputProps,
              endAdornment: enableCurrentLocation ? (
                <InputAdornment position="end">
                  <IconButton
                    disabled={rest.disabled || rest.readOnly}
                    onClick={handleCurrentLocation}
                  >
                    <MyLocationIcon />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment
                  position="end"
                  sx={
                    value?.latitude
                      ? {
                          color: 'primary.main',
                        }
                      : {}
                  }
                >
                  <LocationOnIcon />
                </InputAdornment>
              ),
            },
            name,
          }
          return (
            <TextField
              {...args}
              label={!customLabel ? label : ''}
              {...rest}
              error={error}
              required={required}
            />
          )
        }}
        renderOption={(
          props,
          option: google.maps.places.AutocompletePrediction
        ) => {
          if (option.structured_formatting) {
            const matches =
              option.structured_formatting.main_text_matched_substrings
            const parts = parse(
              option.structured_formatting.main_text,
              matches
                ? matches.map((match) => [
                    match.offset,
                    match.offset + match.length,
                  ])
                : []
            )

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box
                      component={LocationOnIcon}
                      sx={{ color: 'text.secondary', mr: 2 }}
                    />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="#99999B">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )
          }
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: 'text.secondary', mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  <span
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {option.description}
                  </span>
                </Grid>
              </Grid>
            </li>
          )
        }}
      />
    </FormControl>
  )
}

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation('main')

  return (
    <GorillaAlert
      severity="error"
      action={
        <Button onClick={resetErrorBoundary} color="inherit" size="small">
          {t('common.btn.retry')}
        </Button>
      }
    >
      <AlertTitle>{t('common.alert.somethingWrong')}</AlertTitle>
      {error.message}
    </GorillaAlert>
  )
}

export const LocationAutocomplete: React.FC<WrapperProps> = ({
  apiKey,
  ...rest
}: WrapperProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Wrapper apiKey={apiKey} libraries={['places']}>
      <MainComponent {...rest} />
    </Wrapper>
  </ErrorBoundary>
)
