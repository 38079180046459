import React, { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxProps, Switch, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import { useSubjectSelector } from '@procom-labs/common'

import { legacyCopilotStore } from '@submission-portal/stores'

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: theme.palette.grey[400],
    '&.Mui-checked': {
      color: green[500],
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[500],
    },
  },
}))

interface IShowPersonalInformationProps extends BoxProps {}

export const LegacyCopilotResumeShowPersonalInformation: React.FC<
  IShowPersonalInformationProps
> = (boxProps) => {
  const { t } = useTranslation('main')

  const { removePersonalInformation } = useSubjectSelector(legacyCopilotStore, [
    'removePersonalInformation',
  ])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    legacyCopilotStore.dispatch({
      removePersonalInformation: event.target.checked,
    })
  }, [])

  return (
    <Box
      {...boxProps}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Typography>{t('submissionList.resumePersonalInformation')}</Typography>
      <StyledSwitch
        checked={removePersonalInformation}
        onChange={handleChange}
      />
    </Box>
  )
}
