import React from 'react'
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  useController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import { FormLabelOwnProps } from '@mui/material/FormLabel/FormLabel'

import { useHookFieldError } from '../../hooks'
import { ClearIconBtn } from '../buttons'

interface ISelectHookFieldProp<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends SelectProps {
  control: Control<TFieldValues>
  label?: string
  name: TName
  showPleaseSelect?: boolean
  clearAble?: boolean
  onClear?: () => void
  children: React.ReactNode
  customLabel?: boolean
  customLabelProps?: FormLabelOwnProps
  stripMargin?: boolean
}

export const SelectHookField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  label,
  name,
  showPleaseSelect = true,
  clearAble = false,
  onClear,
  children,
  fullWidth = true,
  defaultValue,
  customLabel = true,
  customLabelProps,
  stripMargin,
  ...selectProps
}: ISelectHookFieldProp<TFieldValues, TName>) => {
  const { t } = useTranslation('main')
  const labelId = `${name}-label`

  const {
    field: { ref, ...restField },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue: defaultValue as PathValue<TFieldValues, TName> | undefined,
  })

  const errText = useHookFieldError(fieldState, label)

  return (
    <FormControl
      fullWidth={fullWidth}
      disabled={selectProps?.disabled}
      required={selectProps?.required}
      error={!!(fieldState.invalid && fieldState.error)}
    >
      {customLabel ? (
        <FormLabel {...customLabelProps}>{label}</FormLabel>
      ) : (
        <InputLabel id={labelId} shrink required={selectProps.required}>
          {label}
        </InputLabel>
      )}
      <Select
        label={!customLabel ? label : ''}
        labelId={labelId}
        displayEmpty
        fullWidth={fullWidth}
        notched={!customLabel}
        sx={{
          '.MuiInputBase-inputAdornedEnd': {
            pr: `40px !important`,
          },
          ...(stripMargin && {
            marginTop: '0px !important',
          }),
        }}
        {...restField}
        {...selectProps}
        value={restField.value ?? ''}
        onChange={(event, child) => {
          restField.onChange?.(event.target?.value)
          selectProps?.onChange?.(event, child)
        }}
        inputRef={ref}
        endAdornment={
          <ClearIconBtn
            name={restField.name}
            hide={
              !clearAble ||
              selectProps.disabled ||
              defaultValue === restField.value
            }
            value={restField.value}
            onClick={() => {
              restField.onChange?.(defaultValue ?? '')
              onClear?.()
            }}
          />
        }
      >
        {showPleaseSelect && (
          <MenuItem value="">{t('common.inputs.select.pleaseSelect')}</MenuItem>
        )}
        {children}
      </Select>
      {errText ? <FormHelperText>{errText}</FormHelperText> : null}
    </FormControl>
  )
}
