import * as process from 'process'

const dev = {
  AUTH_API_URL: process.env.AUTH_API_URL
    ? process.env.AUTH_API_URL
    : 'https://localhost:44321',
  JOB_API_URL: process.env.JOB_API_URL
    ? process.env.JOB_API_URL
    : 'https://localhost:44335',
  GTM_KEY: process.env.GTM_KEY || 'GTM-5JVF36B',
  MIXPANEL_TOKEN: process.env.MIXPANEL_TOKEN ?? '',
  TINY_MCE_API_KEY: process.env.TINY_MCE_API_KEY
    ? process.env.TINY_MCE_API_KEY
    : 'r2glkki6lkrhkd3rc4stsd3rgiiglvddl17zl1epw1cnha7q',
  PROCOM_VENDOR_API_KEYS: {
    PCGL: process.env.PROCOM_VENDOR_API_KEY_PCGL
      ? process.env.PROCOM_VENDOR_API_KEY_PCGL
      : '',
    APLN: process.env.PROCOM_VENDOR_API_KEY_APLN
      ? process.env.PROCOM_VENDOR_API_KEY_APLN
      : '',
    CMPSTF: process.env.PROCOM_VENDOR_API_KEY_CMPSTF
      ? process.env.PROCOM_VENDOR_API_KEY_CMPSTF
      : '',
    TSLA: process.env.PROCOM_VENDOR_API_KEY_TSLA
      ? process.env.PROCOM_VENDOR_API_KEY_TSLA
      : '',
    Symbiotic: process.env.PROCOM_VENDOR_API_KEY_Symbiotic
      ? process.env.PROCOM_VENDOR_API_KEY_Symbiotic
      : '',
    ESTWST: process.env.PROCOM_VENDOR_API_KEY_ESTWST
      ? process.env.PROCOM_VENDOR_API_KEY_ESTWST
      : '',
    SFTCHE: process.env.PROCOM_VENDOR_API_KEY_SFTCHE
      ? process.env.PROCOM_VENDOR_API_KEY_SFTCHE
      : '',
    VNDRHWN: process.env.PROCOM_VENDOR_API_KEY_VNDRHWN
      ? process.env.PROCOM_VENDOR_API_KEY_VNDRHWN
      : '',
    GW: process.env.GW_VENDOR_API_KEY_GW
      ? process.env.GW_VENDOR_API_KEY_GW
      : '',
    DEMO: process.env.DEMO_VENDOR_API_KEY_DEMO
      ? process.env.DEMO_VENDOR_API_KEY_DEMO
      : '',
    IMETHODS: process.env.GW_VENDOR_API_KEY_IMETHODS
      ? process.env.GW_VENDOR_API_KEY_IMETHODS
      : '',
  },
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY
    ? process.env.GOOGLE_MAPS_API_KEY
    : 'AIzaSyCfSytBelPmnp5BqJ0S3nQ1uuPq8y9cqg8',
  GOOGLE_RECAPTCHA_KEY:
    process.env.GOOGLE_RECAPTCHA_KEY ??
    '6Le7yUMoAAAAAJNa8h71NPySvz2lyd_zNhM7JQvm',
  rollbar: {
    captureUncaught: process.env.ROLLBAR_CAPTURE_UNCAUGHT
      ? process.env.ROLLBAR_CAPTURE_UNCAUGHT === 'true'
      : false,
    captureUnhandledRejections: process.env.ROLLBAR_CAPTURE_UNHANDLED
      ? process.env.ROLLBAR_CAPTURE_UNHANDLED === 'true'
      : false,
    accessToken: process.env.ROLLBAR_ACCESS_TOKEN || '123',
    enabled: process.env.ROLLBAR_ENABLED || 'false',
    payload: {
      environment: process.env.ROLLBAR_ENVIRONMENT || 'local',
    },
  },
  FBO_PERFORMACE_URL: process.env.FBO_PERFORMACE_URL
    ? process.env.FBO_PERFORMACE_URL
    : 'https://fbo-performance-qa.azurewebsites.net',
  ENABLE_PERFORMANCE_TRACKING:
    process.env.ENABLE_PERFORMANCE_TRACKING || 'false',
  SYNC_FUSION_EDITOR_KEY: process.env.SYNC_FUSION_EDITOR_KEY
    ? process.env.SYNC_FUSION_EDITOR_KEY
    : '',
  SYNC_FUSION_EDITOR_SERVICE_URL: process.env.SYNC_FUSION_EDITOR_SERVICE_URL
    ? process.env.SYNC_FUSION_EDITOR_SERVICE_URL
    : '',
  ENABLE_TIPS_AND_SUGGESTIONS: process.env.ENABLE_TIPS_AND_SUGGESTIONS
    ? process.env.ENABLE_TIPS_AND_SUGGESTIONS === 'true'
    : false,
  ENABLE_NON_SYNCED_CANDIDATES_FILTER: process.env
    .ENABLE_NON_SYNCED_CANDIDATES_FILTER
    ? process.env.ENABLE_NON_SYNCED_CANDIDATES_FILTER === 'true'
    : false,
  ENABLE_DATA_TRACKING: process.env.ENABLE_DATA_TRACKING ?? 'false',
}

const ci = {
  AUTH_API_URL: '#{AUTH_API_URL}#',
  JOB_API_URL: '#{JOB_API_URL}#',
  GTM_KEY: '#{GTM_KEY}#',
  MIXPANEL_TOKEN: '#{MIXPANEL_TOKEN}#',
  TINY_MCE_API_KEY: '#{TINY_MCE_API_KEY}#',
  PROCOM_VENDOR_API_KEYS: {
    PCGL: '#{PROCOM_VENDOR_API_KEY_PCGL}#',
    APLN: '#{PROCOM_VENDOR_API_KEY_APLN}#',
    CMPSTF: '#{PROCOM_VENDOR_API_KEY_CMPSTF}#',
    TSLA: '#{PROCOM_VENDOR_API_KEY_TSLA}#',
    Symbiotic: '#{PROCOM_VENDOR_API_KEY_Symbiotic}#',
    ESTWST: '#{PROCOM_VENDOR_API_KEY_ESTWST}#',
    SFTCHE: '#{PROCOM_VENDOR_API_KEY_SFTCHE}#',
    VNDRHWN: '#{PROCOM_VENDOR_API_KEY_VNDRHWN}#',
    GW: '#{GW_VENDOR_API_KEY_GW}#',
    DEMO: '#{DEMO_VENDOR_API_KEY_DEMO}#',
    IMETHODS: '#{GW_VENDOR_API_KEY_IMETHODS}#',
  },
  GOOGLE_MAPS_API_KEY: '#{GOOGLE_MAPS_API_KEY}#',
  GOOGLE_RECAPTCHA_KEY: '#{GOOGLE_RECAPTCHA_KEY}#',
  rollbar: {
    accessToken: '#{Rollbar.FrontEndAccessToken}#',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: '#{Rollbar.FrontEndEnabled}#',
    payload: {
      environment: '#{Rollbar.Environment}#',
    },
  },
  FBO_PERFORMACE_URL: '#{FBO_PERFORMACE_URL}#',
  ENABLE_PERFORMANCE_TRACKING: '#{ENABLE_PERFORMANCE_TRACKING}#',
  SYNC_FUSION_EDITOR_KEY: '#{SYNC_FUSION_EDITOR_KEY}#',
  SYNC_FUSION_EDITOR_SERVICE_URL: '#{SYNC_FUSION_EDITOR_SERVICE_URL}#',
  ENABLE_TIPS_AND_SUGGESTIONS: '#{ENABLE_TIPS_AND_SUGGESTIONS}#',
  ENABLE_NON_SYNCED_CANDIDATES_FILTER:
    '#{ENABLE_NON_SYNCED_CANDIDATES_FILTER}#',
  ENABLE_DATA_TRACKING: '#{ENABLE_DATA_TRACKING}#',
}

export const environment = {
  ...(process.env.REACT_APP_STAGE === 'ci' ? ci : dev),
}
