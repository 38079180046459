import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import {
  IJobSubmission,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  GeneratorButton,
  LoadingIndicator,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'

import {
  useAtsResourcesFetcher,
  useScopedFetchFile,
} from '@submission-portal/hooks'
import { serviceDescriptorStore } from '@submission-portal/stores'
import { candidatesPrepStore } from '@submission-portal/stores/candidates-prep-store'
import { CandidatesPrepStep, FileModes } from '@submission-portal/types'

import { processSubmission } from '../candidate-prep-utils'

export const CandidatePrepGeneratorButton: React.FC = () => {
  const { t } = useTranslation('main')

  const [searchParams] = useSearchParams()
  const atsJobId = searchParams.get('EntityID')

  const { addAlert } = useAlert()
  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )

  const {
    resumeSelection,
    resumeFile,
    resumeMode,
    preppingSubmission,
    candidate: submission,
  } = useSubjectSelector(candidatesPrepStore, [
    'resumeSelection',
    'resumeFile',
    'resumeMode',
    'preppingSubmission',
    'candidate',
  ])

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])
  const subscriptionRef = useSubscriptionRef()

  const onFetchFile = useScopedFetchFile()
  const fetchResource = useAtsResourcesFetcher(onFetchFile)

  const persistedValue = submission?.candidate?.resume

  const handlePrepSubmission = useCallback(() => {
    if (!atsJobId) {
      // Can never happen
      return
    }

    if (subscriptionRef.current && !subscriptionRef.current.closed) {
      subscriptionRef.current.unsubscribe()
    }

    candidatesPrepStore.dispatch({
      preppingSubmission: true,
    })

    subscriptionRef.current = processSubmission({
      atsJobId,
      fetchResource,
      candidateStore: candidatesPrepStore,
      candidateAtsService,
    }).subscribe({
      next: (newSubmission) => {
        if (newSubmission) {
          candidatesPrepStore.dispatch({
            prepStep: CandidatesPrepStep.Prep,
            candidate: newSubmission as unknown as IJobSubmission,
            preppingSubmission: false,
          })
        }
      },
      error: () => {
        handleError()
        candidatesPrepStore.dispatch({
          preppingSubmission: false,
        })
      },
    })
  }, [
    atsJobId,
    handleError,
    subscriptionRef,
    fetchResource,
    candidateAtsService,
  ])

  return (
    <GeneratorButton
      sx={{ mt: '3rem' }}
      id="cta-generate-candidate-highlights"
      size="medium"
      type="submit"
      alt={t('candidatePreb.stepper.prepSubmission')}
      disabled={
        resumeMode === FileModes.Select
          ? resumeSelection.length === 0 && !persistedValue
          : !resumeFile
      }
      onClick={handlePrepSubmission}
      loading={preppingSubmission}
      loadingIndicator={
        <LoadingIndicator label={t('common.jobGenerator.btn.generating')} />
      }
    >
      {preppingSubmission
        ? t('common.jobGenerator.btn.generating')
        : t('candidatePreb.stepper.prepSubmission')}
    </GeneratorButton>
  )
}
