import React, { useCallback, useMemo, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import {
  FormControl,
  FormLabel,
  FormLabelProps,
  TextFieldProps,
  useTheme,
} from '@mui/material'
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import { useHookFieldError } from '../../hooks'
import { ClearIconBtn } from '../buttons'

interface IDatePickerHookField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Partial<DatePickerProps<any>> {
  name: TName
  control?: Control<TFieldValues>
  clearable?: boolean
  textFieldProps?: TextFieldProps
  fullWidth?: boolean
  customLabel?: boolean
  customLabelProps?: FormLabelProps
  formatter?: (value: DateTime) => string
}

export const DatePickerHookField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  clearable = false,
  textFieldProps = {},
  fullWidth = true,
  customLabel = true,
  customLabelProps,
  label,
  formatter,
  ...datePickerProps
}: IDatePickerHookField<TFieldValues, TName>) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation('main')

  const [dateOpen, setDateOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>()

  const { field, fieldState, formState } = useController({
    name,
    control,
  })

  const errText = useHookFieldError(fieldState, label)

  const toggleOpen = useCallback(() => {
    setDateOpen((prev) => !prev)
    setTimeout(() => {
      inputRef?.current?.focus()
    }, 0)
  }, [])

  const onClose = useCallback(() => {
    toggleOpen()
    field.onBlur()
  }, [field, toggleOpen])

  const onChange = useCallback(
    async (value: DateTime, context) => {
      if (formatter) {
        field.onChange?.(formatter(value))
      } else {
        field.onChange?.(
          value.setZone('UTC', { keepLocalTime: true }).toISO() || ''
        )
      }
      datePickerProps?.onChange?.(value.toJSDate(), context)
    },
    [datePickerProps, field, formatter]
  )

  const onInputClick = useCallback(
    (e) => {
      if (
        !datePickerProps.disabled &&
        !datePickerProps.readOnly &&
        e.target?.type === 'text'
      ) {
        e.preventDefault()
        toggleOpen()
      }
    },
    [datePickerProps.disabled, datePickerProps.readOnly, toggleOpen]
  )

  const onKeyDown = useCallback(
    (e) => {
      if (e.target?.type !== 'button' && e.keyCode === 13) {
        e.preventDefault()
        toggleOpen()
      }
    },
    [toggleOpen]
  )

  const onKeyUp = useCallback(
    (e) => {
      if (e.target?.type !== 'button' && e.keyCode === 9) {
        toggleOpen()
      }
    },
    [toggleOpen]
  )

  const dateValue = useMemo(() => {
    if (field.value) {
      return DateTime.fromISO(field.value, {
        setZone: true,
      })
    }
    return null
  }, [field.value])

  const extendedTextFieldProps = useMemo(() => {
    return clearable && textFieldProps && field.value
      ? {
          ...textFieldProps,
          InputProps: {
            ...textFieldProps.InputProps,
            endAdornment: (
              <ClearIconBtn
                name={field.name}
                value={field.value}
                disabled={datePickerProps.disabled}
                onClick={() => {
                  field.onChange(null)
                }}
                sx={{
                  marginRight: 0,
                }}
              />
            ),
          },
        }
      : textFieldProps
  }, [clearable, datePickerProps.disabled, field, textFieldProps])

  return (
    <FormControl
      fullWidth={fullWidth}
      required={textFieldProps.required}
      disabled={datePickerProps.disabled}
      error={
        !!(
          (fieldState.invalid || formState.submitCount) &&
          !dateOpen &&
          errText
        )
      }
    >
      {customLabel ? (
        <FormLabel {...customLabelProps}>{label}</FormLabel>
      ) : null}
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={i18n.language}
        localeText={{
          cancelButtonLabel: t('common.btn.cancel'),
          okButtonLabel: t('common.btn.ok'),
        }}
      >
        <DatePicker
          {...datePickerProps}
          closeOnSelect
          label={!customLabel ? label : ''}
          open={dateOpen}
          onOpen={toggleOpen}
          onClose={onClose}
          value={dateValue}
          views={datePickerProps.views ?? ['year', 'month', 'day']}
          onChange={onChange}
          readOnly={!dateOpen}
          slots={{
            openPickerIcon: CalendarTodayIcon,
          }}
          slotProps={{
            textField: {
              ...field,
              ...extendedTextFieldProps,
              onClick: onInputClick,
              onKeyDown,
              onKeyUp,
              value: dateValue,
              inputProps: {
                ...extendedTextFieldProps?.inputProps,
                placeholder:
                  extendedTextFieldProps?.inputProps?.placeholder ??
                  'MM/DD/YYYY',
                readOnly: true,
                style: {
                  cursor: datePickerProps.disabled ? 'auto' : 'pointer',
                },
                ref: inputRef,
              },

              sx: {
                '.Mui-focused .MuiSvgIcon-root': {
                  color: theme.palette.primary.main,
                },
                '.Mui-error .MuiSvgIcon-root': {
                  color: theme.palette.error.main,
                },
              },
              error: !!(
                (fieldState.invalid || formState.submitCount) &&
                !dateOpen &&
                errText
              ),
              helperText:
                (fieldState.invalid || formState.submitCount) &&
                !dateOpen &&
                errText
                  ? errText
                  : null,
            },
            openPickerButton: {
              disabled: datePickerProps.disabled,
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  )
}
