import React, { useEffect } from 'react'

export const GorillaResumeRedirect: React.FC = () => {
  useEffect(() => {
    const urlMapping: Record<string, string> = {
      'utility-portal-dev.gorillaworks.io':
        'gw-dev-resume-portal-web.azurewebsites.net',
      'utility-portal-uat.gorillaworks.io':
        'gw-uat-resume-portal-web.azurewebsites.net',
    }

    const currentDomain = window.location.hostname
    const newDomain = urlMapping[currentDomain]

    if (newDomain) {
      window.location.href = `https://${newDomain}${window.location.pathname}${window.location.search}`
    } else {
      // redirect to production
      window.location.href = `https://resume.gorillaworks.io${window.location.pathname}${window.location.search}`
    }
  }, [])

  return null // This component doesn't render anything
}
