import {
  AvailabilityDefaults,
  FieldVisibility,
  GorillaResumeCertification,
  GorillaResumeEducation,
  GorillaResumeEmployment,
  GorillaResumeSkill,
  IAdditionalHighlight,
  ICandidateOnePager,
  IOnePagerExperienceItem,
  IOnePagerForm,
  mapAvailabilityToDataType,
  SkillGroup,
  VisibilityTypes,
} from '@procom-labs/common'

export const getSkillGroups = (
  skills: GorillaResumeSkill[],
  parsedResumeSkills: GorillaResumeSkill[]
): SkillGroup[] => {
  // ParsedResumeSkills contains the skills received from the parser. They won't be visible in the form but will be used to group the skills
  // Skills contains the skills that are visible on the One-Pager. These can be edited/added/removed by the user

  return parsedResumeSkills.reduce((acc, skill) => {
    const { businessType } = skill
    const found = acc.find((group) => group.businessType === businessType)

    if (!found && businessType) {
      let type = businessType
      const filteredSkills = skills.filter(
        (item) => item.businessType === businessType
      )
      const commonType = filteredSkills[0]?.type
      if (
        filteredSkills.length > 0 &&
        commonType &&
        filteredSkills.every((item) => item.type === commonType)
      ) {
        type = commonType
      }
      acc.push({ businessType, type, skills: filteredSkills })
    }
    return acc
  }, [] as SkillGroup[])
}

export const getVisibilityMap = (
  visibility: FieldVisibility[]
): Record<VisibilityTypes, boolean> =>
  visibility.reduce((acc, field) => {
    acc[field.fieldName] = field.isVisible
    return acc
  }, {} as Record<VisibilityTypes, boolean>)

export const getSkillProgress = (years: number, max: number): number => {
  if (years > max) return 100
  let dividend = years
  if (years < 1) dividend = 0.5
  return Math.ceil((dividend / max) * 100)
}

export const getFixedNumber = (value: number | string): number => {
  const a = typeof value === 'string' ? parseFloat(value) : value
  return parseFloat(a.toFixed(1))
}

export const mapFormValuesToCandidateOnePager = (
  formValues: IOnePagerForm,
  onePager: ICandidateOnePager
): ICandidateOnePager => {
  const {
    candidateTitle,
    candidate,
    visibility,
    workExperience,
    additionalHighlights,
    education,
    certifications,
    skills,
    jobs,
    industries,
    roles,
  } = formValues

  return {
    ...onePager,
    candidateTitle,
    fieldsVisibility: Object.entries(visibility).map(([key, value]) => ({
      fieldName: key as VisibilityTypes,
      isVisible: value,
    })),
    candidateDescription: {
      location: candidate.location,
      rate: candidate.rate,
      rateType: candidate.rateType,
      currency: candidate.currency,
      workplaceType: candidate.workplaceType,
      contract: candidate.contract,
      dateAvailable:
        candidate.availability === AvailabilityDefaults.DATE
          ? candidate.dateAvailable
          : null,
      dateAvailableUserInput:
        candidate.availability === AvailabilityDefaults.OTHER
          ? candidate.customAvailability
          : '',
      dateAvailableType: mapAvailabilityToDataType(candidate.availability),
    },

    additionalHighlights: additionalHighlights.reduce((acc, { value }) => {
      if (value) acc.push({ key: null, value })

      return acc
    }, [] as IAdditionalHighlight[]),

    skills: {
      minYears: skills.minYears,
      maxYears: skills.maxYears,
      skills: skills.groups.reduce((skillsList, group) => {
        return skillsList.concat(
          group.skills.reduce((acc, skill) => {
            if (skill.name) {
              acc.push({
                name: skill.name,
                yearsOfExperience: skill.yearsOfExperience,
                businessType: group.businessType,
                type: group.type,
              })
            }
            return acc
          }, [] as GorillaResumeSkill[])
        )
      }, [] as GorillaResumeSkill[]),
    },
    jobs: {
      minYears: jobs.minYears,
      maxYears: jobs.maxYears,
      jobs: jobs.jobs.reduce((acc, { name, yearsOfExperience }) => {
        if (name) acc.push({ name, yearsOfExperience })
        return acc
      }, [] as IOnePagerExperienceItem[]),
    },
    industries: industries.reduce((acc, { name, yearsOfExperience }) => {
      if (name) acc.push({ name, yearsOfExperience })
      return acc
    }, [] as IOnePagerExperienceItem[]),
    openToRoles: roles?.filter((role) => role) ?? [],
    parsedResumeData: {
      ...onePager.parsedResumeData,
      careerSummary: {
        ...onePager.parsedResumeData.careerSummary,
        summary: candidate.summary,
      },
      employments: workExperience.reduce((acc, job, index) => {
        if (
          job.startDate ||
          job.endDate ||
          job.title ||
          job.industryName ||
          job.description
        ) {
          acc.push({
            ...onePager.parsedResumeData.employments[index],
            startDate: job.startDate,
            endDate: job.endDate,
            title: job.title,
            industryName: job.industryName,
            description: job.description,
          })
        }

        return acc
      }, [] as GorillaResumeEmployment[]),
      educations: education.reduce((acc, edu, index) => {
        if (
          edu.startDate ||
          edu.endDate ||
          edu.qualification ||
          edu.institution ||
          edu.description
        ) {
          acc.push({
            ...onePager.parsedResumeData.educations[index],
            startDate: edu.startDate,
            endDate: edu.endDate,
            qualification: edu.qualification,
            institution: edu.institution,
            description: edu.description,
          })
        }

        return acc
      }, [] as GorillaResumeEducation[]),
      certifications: certifications.reduce((acc, { name, dateReceived }) => {
        if (name) {
          acc.push({
            name,
            dateReceived,
          })
        }

        return acc
      }, [] as GorillaResumeCertification[]),
    },
  }
}
