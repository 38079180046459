import { BehaviorSubject } from 'rxjs'
import { useCallback, useEffect, useState } from 'react'

/**
 * A React hook that provides a debounced version of useState.
 *
 * This hook creates an BehaviorSubject that debounces its values, allowing you to delay processing
 * until some time has passed without the value being updated.
 *
 * @template T The type of the state.
 *
 * @param initialValue - The initial value of the state.
 *
 * @returns {Array} A tuple where:
 *  - The first item is the BehaviorSubject.
 *  - The second item is the current value of the state.
 *  - The third item is a function to update the value of the state and the BehaviorSubject.
 *
 * @example
 *
 *  const [keyword$, keyword, setKeyword] = useSubjectState('');
 *  useEffect(() => {
 *    const subscription = keyword$.pipe(debounceTime(1000))
 *      .subscribe((value) => {
 *        console.log(`debounced value: ${value}`);
 *      });
 *    return () => subscription.unsubscribe();
 *  }, [keyword$]);
 *
 *  // somewhere in your code...
 *  setKeyword('new value');
 */
export const useSubjectState = <T>(
  initialValue: T
): [BehaviorSubject<T>, T, (newValue: T) => void] => {
  // Initialize BehaviorSubject with the initial value and keep it in state
  const [subject] = useState<BehaviorSubject<T>>(
    () => new BehaviorSubject(initialValue)
  )

  // Regular React state for keeping the value
  const [value, setValue] = useState(initialValue)

  // On component mount, subscribe to BehaviorSubject's values and
  // update the value in state whenever BehaviorSubject emits.
  // On component unmount, cleanup by unsubscribing from BehaviorSubject.
  useEffect(() => {
    const subscription = subject.subscribe(setValue)
    return () => subscription.unsubscribe()
  }, [subject])

  // Function to update both the state value and the BehaviorSubject value.
  const setDebouncedValue = useCallback(
    (newValue: T) => {
      subject.next(newValue)
    },
    [subject]
  )

  return [subject, value, setDebouncedValue]
}
