import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { Box } from '@mui/material'

export const PDFViewer: React.FC<{
  file: string
}> = ({ file }) => {
  const pdfViewRef = useRef<HTMLDivElement | null>(null)
  const [containerWidth, setContainerWidth] = useState<number>(0)

  // Function to render the PDF
  const renderPDF = useCallback(async () => {
    if (!file || !window.pdfjsLib || containerWidth === 0) return

    try {
      // Load the PDF document
      const loadingTask = window.pdfjsLib.getDocument(file)
      const pdf = await loadingTask.promise

      // Clear previous canvases if any
      if (pdfViewRef.current) {
        pdfViewRef.current.innerHTML = ''
      }

      // Fetch all pages concurrently
      const pagePromises = []
      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum += 1) {
        pagePromises.push(pdf.getPage(pageNum))
      }
      const pages = await Promise.all(pagePromises)

      // Render each page on a new canvas
      pages.forEach((page) => {
        // Initial viewport to get original page dimensions
        const viewport = page.getViewport({ scale: 1 })

        // Calculate the scale to fit the container width
        const scale = containerWidth / viewport.width
        const scaledViewport = page.getViewport({ scale })

        // Create a new canvas for each page
        const canvas = document.createElement('canvas')
        canvas.height = scaledViewport.height
        canvas.width = scaledViewport.width

        const context = canvas.getContext('2d')

        if (context) {
          // Render the page on the canvas
          const renderContext = {
            canvasContext: context,
            viewport: scaledViewport,
          }
          page.render(renderContext)

          // Append the canvas to the container
          pdfViewRef.current?.appendChild(canvas)
        }
      })
    } catch (error) {
      if (error instanceof Error && pdfViewRef.current) {
        pdfViewRef.current.innerHTML = `Failed to load PDF file: ${error.message}`
      }
    }
  }, [file, containerWidth])

  // Function to load the pdf.js library script dynamically
  const loadPdfJsLib = useCallback(() => {
    // Check if the script is already added to the document
    if (!window.pdfjsLib) {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf_viewer.min.css'
      const script = document.createElement('script')
      script.src =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.min.js' // Ensure this matches the version you want
      script.async = true
      script.onload = () => {
        // Configure the workerSrc for pdf.js after loading
        window.pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`
        renderPDF() // Trigger rendering after loading the library
      }
      document.body.appendChild(script)
    } else {
      renderPDF() // Render immediately if pdf.js is already loaded
    }
  }, [renderPDF])

  const updateWidth = useCallback(() => {
    if (pdfViewRef.current) {
      setContainerWidth(pdfViewRef.current.offsetWidth)
    }
  }, [])

  // Resize observer to handle width changes of the container
  useLayoutEffect(() => {
    // Initial update
    updateWidth()

    // Set up ResizeObserver to handle dynamic resizing
    const resizeObserver = new ResizeObserver(() => {
      updateWidth()
    })

    if (pdfViewRef.current) {
      resizeObserver.observe(pdfViewRef.current)
    }

    // Cleanup observer on unmount
    return () => {
      resizeObserver.disconnect()
    }
  }, [updateWidth])

  // Trigger rendering when the file or container width changes
  useEffect(() => {
    loadPdfJsLib()
  }, [loadPdfJsLib])

  return <Box ref={pdfViewRef} width="100%" />
}

export default PDFViewer
