import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Subscription } from 'rxjs'

import { Theme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Preloader } from '@procom-labs/atoms'
import { errorToString } from '@procom-labs/common'

import { ISubmissionSummary } from '@submission-portal/models'
import { submissionService } from '@submission-portal/services'

import { SubmissionListItem } from '../components'

export const SubmissionList: FC = () => {
  const [searchParams] = useSearchParams()
  const subscriptionRef = useRef<Subscription>()

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [submissionList, setSubmissionList] = useState<ISubmissionSummary[]>([])

  const jobId = searchParams.get('EntityID')
  const userId = searchParams.get('UserID')

  const { t } = useTranslation('main')

  useEffect(() => {
    sessionStorage.setItem('rootPath', '/')
  }, [])

  const clearSubscription = (): void => {
    const { current } = subscriptionRef
    if (current && !current.closed) {
      current.unsubscribe()
    }
  }

  const loadSubmissionList = useCallback((): void => {
    setIsLoading(true)
    setSubmissionList([])
    clearSubscription()

    subscriptionRef.current = submissionService
      .getSubmissionList(jobId)
      .subscribe({
        next: (data) => setSubmissionList(data),
        error: (err) => setError(errorToString(err)),
      })
    subscriptionRef.current.add(() => setIsLoading(false))
  }, [jobId])

  useEffect(() => {
    if (jobId && userId)
      submissionService.setIFrameIds({
        atsCandidateId: jobId,
        atsUserId: userId,
      })
  }, [jobId, userId])

  useEffect(() => {
    loadSubmissionList()
    return clearSubscription
  }, [])

  if (isLoading) {
    return <Preloader center />
  }

  if (submissionList && submissionList.length) {
    return (
      <Grid
        container
        direction="column"
        p={4}
        sx={{ maxWidth: '1700px', margin: '0 auto' }}
      >
        {isDesktop && (
          <Grid
            container
            item
            columns={16}
            spacing={1}
            sx={{
              pb: 2,
              paddingX: (theme) => `${theme.spacing(6)} !important`,
            }}
          >
            <Grid item xs={4}>
              <Typography noWrap> {t('submissionList.candidates')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography noWrap>{t('submissionList.dateAdded')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography noWrap>{t('submissionList.lastModified')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography noWrap>{t('submissionList.addedBy')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography noWrap>{t('submissionList.status')}</Typography>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        )}

        <Grid
          container
          item
          sx={{
            backgroundColor: 'background.paper',
            paddingX: {
              md: 4,
              sm: 2,
              xs: 1,
            },
            paddingY: (theme) => `${theme.spacing(2)} !important`,
          }}
        >
          {submissionList.map((submission) => (
            <SubmissionListItem
              submission={submission}
              key={submission.jobSubmissionId}
            />
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <div className="center-screen">
      <Typography paragraph variant="h3">
        {t('error.notFound')}
      </Typography>
      {error && (
        <Typography variant="h5" paragraph>
          {error}
        </Typography>
      )}
    </div>
  )
}
