import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Subscription } from 'rxjs'
import * as Yup from 'yup'

import {
  errorToString,
  IAuthResponse,
  ICart,
  IEmailPayload,
  IEmailTemplate,
  IJobSubmission,
  PreferredLanguageType,
} from '@procom-labs/common'

import { submissionService } from '@submission-portal/services'
import { SubmissionStore } from '@submission-portal/stores'
import { IEmailForm, SendEmailCopyKey } from '@submission-portal/types'

interface EmailFormSchema {
  emailSubject: Yup.StringSchema<string | undefined>
  emailContent: Yup.StringSchema<string | undefined>
  emailTo: Yup.ArraySchema<Yup.StringSchema<string | undefined>>
  emailToCc?: Yup.ArraySchema<Yup.StringSchema<string | undefined>>
  emailToBcc?: Yup.ArraySchema<Yup.StringSchema<string | undefined>>
  [key: string]: any
}

export const loadSubmissionEmailContent = (
  submission: IJobSubmission,
  formInstance: UseFormReturn<IEmailForm, any, undefined>,
  setTemplateData: React.Dispatch<
    React.SetStateAction<IEmailTemplate[] | undefined>
  >,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  submissionStore?: SubmissionStore,
  getSavedTemplates: boolean = true,
  currentUser: IAuthResponse | null = null
): Subscription => {
  const { id, submissionLanguage } = submission
  const isView = sessionStorage.getItem('isView') === 'true'
  const isTogglingCandidateHighlight =
    submissionStore?.candidateHighlightToggleStatus
  return submissionService
    .getSubmissionEmail(id, {
      getSavedTemplates,
      language: submissionLanguage ?? null,
    })
    .subscribe({
      next: (data) => {
        setTemplateData(data.emailTemplates)

        const contentObject = data.emailTemplates?.find((c) =>
          submissionLanguage
            ? c.language === submissionLanguage
            : c.language === PreferredLanguageType.EN
        )

        const initialContent = contentObject?.emailContent?.substring(
          contentObject.emailContent.indexOf('<table'),
          contentObject.emailContent.lastIndexOf('</table>') + 8
        )

        // only update emailContent if it is toggling candidate highlight, to pevent others fileds from being updated
        if (isTogglingCandidateHighlight) {
          formInstance.setValue('emailContent', initialContent ?? '')
        } else {
          formInstance.setValue(
            'language',
            submissionLanguage ?? PreferredLanguageType.EN
          )
          formInstance.setValue(
            'emailSubject',
            contentObject?.emailSubject ?? ''
          )
          formInstance.setValue('emailContent', initialContent ?? '')
          formInstance.setValue('emailFrom', [data.emailFrom])
          formInstance.setValue('emailTo', data.emailTo ?? [])

          let ccValues =
            data.emailToCc?.filter(
              (email) =>
                !data.emailFrom.includes(email) && !data.emailTo.includes(email)
            ) ?? []

          if (submission.candidate.submissionEmailTemplate?.emailToCc) {
            ccValues = submission.candidate.submissionEmailTemplate?.emailToCc
          }
          if (
            currentUser?.user?.email &&
            currentUser.user.email !== submission.accountManagerEmail
          ) {
            // If the sender is not the account manager, remove the sender from the cc list
            ccValues = ccValues.filter(
              (email) => email !== currentUser?.user?.email
            )
          }

          formInstance
            .getValues('emailToCc')
            .filter((cc) => !ccValues.includes(cc))
            .map((cc) => ccValues.push(cc))

          if (isView) {
            formInstance.setValue(
              'emailToCc',
              submission.candidate.submissionEmailTemplate?.emailToCc ?? []
            )
          } else {
            formInstance.setValue('emailToCc', ccValues)
          }

          formInstance.setValue('emailToBcc', data.emailToBcc ?? [])

          const sendEmailCopyToSenderLS =
            localStorage.getItem(SendEmailCopyKey) === 'true'
          if (submission.candidate.submissionEmailTemplate) {
            formInstance.setValue(
              'sendEmailCopyToSender',
              data.sendEmailCopyToSender
            )
          } else {
            formInstance.setValue(
              'sendEmailCopyToSender',
              sendEmailCopyToSenderLS
            )
          }
        }

        submissionStore?.dispatch({
          isTogglingCandidateHighlight: false,
        })
      },
      error: (err) => setError(errorToString(err)),
    })
}

export const loadCartEmailContent = (
  cart: ICart,
  formInstance: UseFormReturn<IEmailForm, any, undefined>,
  setTemplateData: React.Dispatch<
    React.SetStateAction<IEmailTemplate[] | undefined>
  >,
  setError: React.Dispatch<React.SetStateAction<string | null>>,
  submissionStore: SubmissionStore,
  getSavedTemplates: boolean = true
): Subscription => {
  const { id, language } = cart
  return submissionService
    .getSubmissionEmail(id, {
      getSavedTemplates,
      multipleCandidates: true,
    })
    .subscribe({
      next: (data) => {
        submissionStore.dispatch({
          emailObject: data,
        })
        setTemplateData(data.emailTemplates)

        const contentObject = data.emailTemplates?.find((c) =>
          language
            ? c.language === language
            : c.language === PreferredLanguageType.EN
        )

        const initialContent = contentObject?.emailContent?.substring(
          contentObject.emailContent.indexOf('<table'),
          contentObject.emailContent.lastIndexOf('</table>') + 8
        )

        formInstance.setValue('language', language ?? PreferredLanguageType.EN)
        formInstance.setValue('emailSubject', contentObject?.emailSubject ?? '')
        formInstance.setValue('emailContent', initialContent ?? '')
        formInstance.setValue('emailFrom', [data.emailFrom])
        formInstance.setValue('emailTo', data.emailTo ?? [])

        formInstance.setValue('emailToCc', data.emailToCc ?? [])
        formInstance.setValue('emailToBcc', data.emailToBcc ?? [])

        const sendEmailCopyToSenderLS =
          localStorage.getItem(SendEmailCopyKey) === 'true'
        if (cart.emailTemplate) {
          formInstance.setValue(
            'sendEmailCopyToSender',
            data.sendEmailCopyToSender
          )
        } else {
          formInstance.setValue(
            'sendEmailCopyToSender',
            sendEmailCopyToSenderLS
          )
        }
      },
      error: (err) => setError(errorToString(err)),
    })
}

export const emailFormValidation = (
  invalidEmail: string,
  requiredList: string
): Yup.ObjectSchema<EmailFormSchema> =>
  Yup.object().shape({
    emailSubject: Yup.string().required(),
    emailContent: Yup.string().required(),
    emailTo: Yup.array()
      .of(Yup.string().email(invalidEmail))
      .min(1, requiredList),
    emailToCc: Yup.array().of(Yup.string().email(invalidEmail)),
    emailToBcc: Yup.array().of(Yup.string().email(invalidEmail)),
  })

export const buildEmailObject = (
  data: IEmailForm,
  templateData: IEmailTemplate[]
): IEmailPayload => {
  const templatesData = [...templateData]
  const templateIndex = templatesData.findIndex(
    (t) => t.language === data.language
  )

  templatesData[templateIndex] = {
    language: data.language,
    emailContent: data.emailContent,
    emailSubject: data.emailSubject,
  }

  return {
    userLanguage: data.language,
    emailFrom: data.emailFrom[0],
    emailTo: data.emailTo,
    emailToCc: data.emailToCc,
    emailToBcc: data.emailToBcc,
    emailTemplates: templatesData,
    sendEmailCopyToSender: data.sendEmailCopyToSender,
  }
}
