import Axios from 'axios-observable'
import { map, Observable } from 'rxjs'

import {
  AtsCandidateFileParameter,
  AtsFileParameter,
  AtsJobOrderFileParameter,
  DataObject,
  DataObjectContent,
  Employee,
  EmployeeData,
  FormatLegacyResumeRequest,
  FormatResumeRequest,
  ICollaborator,
  IFile,
  IJob,
  JobData,
  LegacySubmissionTemplate,
  SearchPayload,
  SubmissionTemplate,
} from '@common/models'

export interface CandidateAtsServiceProps {
  baseUrl: string
  axios: Axios
  apiKey: string
  atsUserId?: string
}

export class CandidateAtsService {
  private readonly baseUrl: string

  private readonly axios: Axios

  private readonly apiKey: string

  private atsUserId: string

  constructor(props: CandidateAtsServiceProps) {
    this.baseUrl = props.baseUrl
    this.axios = props.axios
    this.apiKey = props.apiKey
    this.atsUserId = props.atsUserId || ''
  }

  setAtsUserId(userId: string): void {
    this.atsUserId = userId
  }

  getAtsUserId(): string {
    return this.atsUserId
  }

  private getCandidateUrl(atsCandidateId: number): string {
    return `${this.baseUrl}/Candidate/ats/candidate/${atsCandidateId}`
  }

  private searchCandidatesUrl(): string {
    return `${this.baseUrl}/Candidate/ats/search-candidates`
  }

  private formatResumeUrl(): string {
    return `${this.baseUrl}/Candidate/ats/format-resume`
  }

  private formatLegacyResumeUrl(): string {
    return `${this.baseUrl}/Candidate/ats/format-legacy-resume`
  }

  private candidateFileUrl(atsCandidateId: number, fileId: number): string {
    return `${this.baseUrl}/Candidate/ats/${atsCandidateId}/files/${fileId}`
  }

  private candidateFilesUrl(atsCandidateId: number): string {
    return `${this.baseUrl}/Candidate/ats/${atsCandidateId}/files`
  }

  private submissionTemplatesUrl(): string {
    return `${this.baseUrl}/Candidate/ats/submission-templates`
  }

  private legacySubmissionTemplatesUrl(): string {
    return `${this.baseUrl}/Candidate/ats/legacy-submission-templates`
  }

  private searchJobOrdersUrl(): string {
    return `${this.baseUrl}/ClientJob/ats/search-job-orders`
  }

  private jobOrderUrl(atsJobId: number): string {
    return `${this.baseUrl}/ClientJob/ats/${atsJobId}`
  }

  private jobOrderFilesUrl(atsJobId: number): string {
    return `${this.baseUrl}/ClientJob/ats/${atsJobId}/files`
  }

  private jobOrderFileUrl(atsJobId: number, fileId: number): string {
    return `${this.baseUrl}/ClientJob/ats/${atsJobId}/files/${fileId}`
  }

  private jobOrderCollaborators(atsJobId: number): string {
    return `${this.baseUrl}/ClientJob/ats/job-collaborators/${atsJobId}`
  }

  private submissionFileUrl(jobSubmissionId: string): string {
    return `${this.baseUrl}/JobSubmission/ats/${jobSubmissionId}/submission-file`
  }

  private sendEntityFileUrl(): string {
    return `${this.baseUrl}/File/ats/bullhorn`
  }

  getJobOrder(request: AtsJobOrderFileParameter): Observable<IJob> {
    return this.axios
      .get(this.jobOrderUrl(request.atsJobId))
      .pipe(map((response) => response.data))
  }

  getJobCollaborators(
    request: AtsJobOrderFileParameter
  ): Observable<ICollaborator[]> {
    return this.axios
      .get(this.jobOrderCollaborators(request.atsJobId))
      .pipe(map((response) => response.data))
  }

  formatResume(payload: Partial<FormatResumeRequest>): Observable<string> {
    return this.axios
      .post(this.formatResumeUrl(), payload)
      .pipe(map((response) => response.data))
  }

  formatLegacyResume(
    payload: Partial<FormatLegacyResumeRequest>
  ): Observable<string> {
    return this.axios
      .post(this.formatLegacyResumeUrl(), payload)
      .pipe(map((response) => response.data))
  }

  searchCandidates(payload: Partial<SearchPayload>): Observable<EmployeeData> {
    return this.axios
      .post(this.searchCandidatesUrl(), payload)
      .pipe(map((response) => response.data))
  }

  getCandidate(atsCandidateId: number): Observable<Employee> {
    return this.axios
      .get(this.getCandidateUrl(atsCandidateId))
      .pipe(map((response) => response.data))
  }

  searchJobOrders(payload: Partial<SearchPayload>): Observable<JobData> {
    return this.axios
      .post(this.searchJobOrdersUrl(), payload)
      .pipe(map((response) => response.data))
  }

  getSubmissionTemplates(): Observable<SubmissionTemplate[]> {
    return this.axios
      .get(this.submissionTemplatesUrl())
      .pipe(map((response) => response.data))
  }

  getLegacySubmissionTemplates(): Observable<LegacySubmissionTemplate[]> {
    return this.axios
      .get(this.legacySubmissionTemplatesUrl())
      .pipe(map((response) => response.data))
  }

  getJobOrderFiles(
    request: AtsJobOrderFileParameter
  ): Observable<DataObject[]> {
    return this.axios
      .get(this.jobOrderFilesUrl(request.atsJobId))
      .pipe(map((response) => response.data))
  }

  getJobOrderFile(
    request: AtsFileParameter,
    convertToPdf: boolean = true
  ): Observable<DataObjectContent> {
    return this.axios
      .get(this.jobOrderFileUrl(request.entityId, request.fileId), {
        params: { convertToPdf, atsUserId: this.atsUserId },
      })
      .pipe(map((response) => response.data))
  }

  getCandidateFile(
    request: AtsFileParameter,
    convertToPdf: boolean = true
  ): Observable<DataObjectContent> {
    return this.axios
      .get(this.candidateFileUrl(request.entityId, request.fileId), {
        params: { convertToPdf },
      })
      .pipe(map((response) => response.data))
  }

  postCandidateFile(
    request: AtsFileParameter,
    payload: DataObject,
    submissionId: string,
    isHighlightsDocument = false
  ): Observable<any> {
    return this.axios
      .post(this.candidateFileUrl(request.entityId, request.fileId), payload, {
        params: { submissionId, isHighlightsDocument },
      })
      .pipe(map((response) => response.data))
  }

  getCandidateFiles(
    request: AtsCandidateFileParameter
  ): Observable<DataObject[]> {
    return this.axios
      .get(this.candidateFilesUrl(request.atsCandidateId))
      .pipe(map((response) => response.data))
  }

  postSubmissionFile(
    jobSubmissionId: string,
    content: string
  ): Observable<IFile> {
    return this.axios
      .post(this.submissionFileUrl(jobSubmissionId), content)
      .pipe(map((response) => response.data))
  }

  postEntityFile(
    file: File,
    entityType: string,
    entityId: string,
    externalId: string,
    name: string
  ): Observable<any> {
    const formData = new FormData()
    formData.append('File', file)
    formData.append('EntityType', entityType)
    formData.append('EntityId', entityId)
    formData.append('ExternalId', externalId)
    formData.append('Name', name)

    return this.axios
      .post(this.sendEntityFileUrl(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .pipe(map((response) => response.data))
  }
}
