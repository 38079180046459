export enum PublicRouterPaths {
  Data = '/data',
  ResumeCopilot = '/resume-copilot',
  LegacyResumeCopilot = '/legacy-resume-copilot',
  CandidatePrep = '/candidate-prep',
  CandidatePrepEntry = '/candidate-prep/:id',
  JobGenerator = '/job-generator',
  InterviewCopilot = '/interview-copilot',
  LanguageCopilot = '/language-copilot',
  CopilotHighlights = '/copilot-highlights',
  EventTracker = '/event-tracking',
  CandidateOnePagerPOC = '/candidate-one-pager-poc',
  ProcomResumeCopilot = '/resume-copilot-pro',
  AplinResumeCopilot = '/aplin-resume-copilot',
  EastWestStaffingResumeCopilot = '/eastweststaffing-resume-copilot',
  VanderhouwenResumeCopilot = '/vanderhouwen-resume-copilot',
  BullhornResumeCopilot = '/bullhorn-resume-copilot',
  DocumentEditor = '/document-editor',
}

export enum DocumentParserErrors {
  InvalidFile = 'Invalid File',
  PdfParseError = 'Could not parse the pdf file',
  DocParseError = 'Could not parse the doc file',
}
