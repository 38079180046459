import React, { ReactElement } from 'react'
import { Button, Stack, SxProps, Theme } from '@mui/material'
import { PreferredLanguageType, useIsGorillaTheme } from '@procom-labs/common'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

const LangBtnSx: SxProps<Theme> = {
  fontSize: '13px',
  fontWeight: 900,
  lineHeight: 1.7,
  minWidth: '37px',
  pt: 0,
  pb: 0.5,
  borderRadius: '50px',
  textDecoration: 'none',
  '&:hover': { textDecoration: 'none' },
  '&.Mui-disabled': {
    color: 'common.white',
    backgroundColor: 'common.purple',
  },
  '&:not(.Mui-disabled)': {
    color: 'commmon.purple',
  },
}

export const LanguageSwitchButtons = ({
  name,
  spacing,
}: {
  name: string
  spacing?: number
}): ReactElement => {
  const { t } = useTranslation('main')
  const [field] = useField(name)
  const { setFieldValue } = useFormikContext()

  const isGorillaTheme = useIsGorillaTheme()

  return (
    <Stack direction="row" spacing={spacing ?? 2.5}>
      <Button
        onClick={() => setFieldValue(field.name, PreferredLanguageType.EN)}
        disabled={field.value === PreferredLanguageType.EN}
        sx={isGorillaTheme ? LangBtnSx : {}}
      >
        {t('common.languageSwitcher.english')}
      </Button>
      <Button
        onClick={() => setFieldValue(field.name, PreferredLanguageType.FR)}
        disabled={field.value === PreferredLanguageType.FR}
        sx={isGorillaTheme ? LangBtnSx : {}}
      >
        {t('common.languageSwitcher.french')}
      </Button>
    </Stack>
  )
}
