import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import {
  AtsFileInfo,
  FormatResumeRequest,
  useSubjectSelector,
  useSubscriptionRef,
} from '@procom-labs/common'
import {
  ConfirmationDialog,
  LoadingIndicator,
  useAlert,
  useDefaultErrorHandler,
} from '@procom-labs/molecules'

import { copilotStore } from '@submission-portal/stores'
import { serviceDescriptorStore } from '@submission-portal/stores/service-descriptor-store'

const getAtsFileInfo = (
  fileStorageId: string | undefined,
  entityId: string | undefined,
  fileId: string | undefined,
  isDoc: boolean,
  extension?: string
): AtsFileInfo | undefined => {
  // We prioritize custom file upload over existing selection
  if (fileStorageId) {
    return {
      fileStorageInfo: { fileStorageId },
      extension: isDoc ? 'docx' : 'pdf',
    }
  }

  if (entityId && fileId && extension) {
    return {
      atsFileInfo: {
        entityId: Number(entityId),
        fileId: Number(fileId),
      },
      extension,
    }
  }

  return undefined
}

export const CopilotResumeGenerateButton: FC = () => {
  const { addAlert } = useAlert()
  const subscriptionRef = useSubscriptionRef()
  const { t } = useTranslation('main')
  const handleError = useDefaultErrorHandler(
    addAlert,
    t('common.alert.somethingWrong')
  )
  const [searchParams] = useSearchParams()
  const atsJobId = searchParams.get('EntityID')

  const [isConfirming, setIsConfirming] = useState(false)

  const { candidateAtsService } = useSubjectSelector(serviceDescriptorStore, [
    'candidateAtsService',
  ])

  const {
    candidateSelection,
    candidateSelections,
    resumeSelection,
    resumeSelections,
    resumeFile,
    submissionTemplateSelection,
    submissionTemplateSelections,

    coverPageJobSelection,
    coverPageSelection,
    coverPageFile,
    generating,
    resumeFileSfdtText,
    resumeFileBlob,
    removePersonalInformation,
  } = useSubjectSelector(copilotStore, [
    'candidateSelection',
    'candidateSelections',
    'resumeSelection',
    'resumeSelections',
    'resumeFile',
    'submissionTemplateSelection',
    'submissionTemplateSelections',

    'coverPageJobSelection',
    'coverPageSelection',
    'coverPageFile',
    'generating',
    'resumeFileSfdtText',
    'resumeFileBlob',
    'removePersonalInformation',
  ])

  const handleGenerate = useCallback(() => {
    const resumeExtension = resumeSelections.find(
      (r) => r.id === resumeSelection
    )?.data.fileExtension
    // Check if the user decided to upload a resume. Otherwise, we look for the candidateSelection and resumeSelection
    const atsCandidateResumeInfo = getAtsFileInfo(
      resumeFile?.wordFileStorageId ?? resumeFile?.fileStorageId,
      candidateSelection,
      resumeSelection,
      !!resumeFile?.wordFileStorageId,
      resumeExtension
    )

    const candidateData = candidateSelections.find(
      (candidate) => candidate.id === candidateSelection
    )

    const candidateInfo: FormatResumeRequest['candidateInfo'] = {}
    if (candidateData) {
      candidateInfo.dateAvailable = candidateData.data.dateAvailable
    }

    const templateData = submissionTemplateSelections.find(
      (template) => template.id === submissionTemplateSelection
    )

    if (!atsCandidateResumeInfo || !templateData || !atsJobId) {
      // Just return if conditions are not met, no further actions.
      return
    }

    // We look for the Word file storage first.
    const atsJobCoverPageInfo = getAtsFileInfo(
      coverPageFile?.wordFileStorageId ?? coverPageFile?.fileStorageId,
      coverPageJobSelection,
      coverPageSelection,
      !!resumeFile?.wordFileStorageId
    )

    copilotStore.dispatch({
      resumeURL: '',
      generating: true,
    })

    if (subscriptionRef.current && !subscriptionRef.current.closed) {
      subscriptionRef.current.unsubscribe()
    }

    subscriptionRef.current = candidateAtsService
      .formatResume({
        atsJobId: parseInt(atsJobId, 10),
        candidateInfo: {
          ...candidateInfo,
          removePersonalInfo: removePersonalInformation,
        },
        atsCandidateResumeInfo,
        atsJobCoverPageInfo,
        resumeTemplateConfiguration: {
          templateId: templateData.data.templateId,
          language: templateData.data.language,
        },
      })
      .subscribe({
        next: (url) => {
          copilotStore.dispatch({
            generating: false,
          })

          // Make sure to dispatch the text editor update in a later call
          setTimeout(() => {
            copilotStore.dispatch({
              resumeURL: url,
              drawerOpen: false,
            })
          }, 0)
        },
        error: () => {
          copilotStore.dispatch({
            generating: false,
          })
          handleError()
        },
      })
  }, [
    resumeSelections,
    resumeFile?.wordFileStorageId,
    resumeFile?.fileStorageId,
    candidateSelection,
    resumeSelection,
    candidateSelections,
    submissionTemplateSelections,
    atsJobId,
    coverPageFile?.wordFileStorageId,
    coverPageFile?.fileStorageId,
    coverPageJobSelection,
    coverPageSelection,
    subscriptionRef,
    candidateAtsService,
    removePersonalInformation,
    submissionTemplateSelection,
    handleError,
  ])

  const handleDialog = useCallback(
    async (hasConfirmed = false) => {
      setIsConfirming(false)

      if (hasConfirmed) {
        handleGenerate()
      }
    },
    [handleGenerate]
  )

  const handleStartGenerate = useCallback(async () => {
    // IF these are no previous edits or generation, then we generate right away. Otherwise, we confirm the process
    // from the user to ensure that no data will be lost
    if (!resumeFileSfdtText) {
      handleGenerate()
    } else {
      setIsConfirming(true)
    }
  }, [handleGenerate, resumeFileSfdtText])

  const canGenerate =
    !!submissionTemplateSelection && (!!resumeFileBlob || !!resumeSelection)

  // If no candidates are selected, or there are no resume selected or file uploaded, then
  // there is no generation button
  if (!canGenerate) {
    return null
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <ConfirmationDialog
        open={isConfirming}
        handleClose={handleDialog}
        title={t('submissionList.confirmGenerationDialogTitle')}
        content={t('submissionList.confirmGenerationDialogDescription')}
        okBtnText={t(`common.btn.continue`)}
        cancelBtnText={t(`common.btn.cancel`)}
      />
      <LoadingButton
        onClick={handleStartGenerate}
        variant="contained"
        color="primary"
        sx={{ paddingX: '64px' }}
        loading={generating}
        loadingIndicator={
          <LoadingIndicator label={t('common.jobGenerator.btn.generating')} />
        }
      >
        {generating
          ? t('common.jobGenerator.btn.generating')
          : t('submissionList.generate')}
      </LoadingButton>
    </Box>
  )
}
