import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import Parse from 'html-react-parser'
import { TFuncKey, useTranslation } from 'react-i18next'
import { finalize } from 'rxjs'
import { useObservable } from 'rxjs-hooks'
import * as Yup from 'yup'

import DownloadIcon from '@mui/icons-material/Download'
import RoomSharpIcon from '@mui/icons-material/RoomSharp'
import WorkIcon from '@mui/icons-material/Work'
import { LoadingButton } from '@mui/lab'
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DateFormatter } from '@procom-labs/atoms'
import {
  downloadPDF,
  IInterviewMetadata,
  IInterviewPreparationsFields,
  IJobLocationObj,
  JobAiQuestionsTypes,
  lensProxy,
  PreferredLanguageType,
  useJobLocation,
  UserWorkplacePreference,
  useServices,
  useSubscriptionRef,
} from '@procom-labs/common'
import { LoadingIndicator, useAlert } from '@procom-labs/molecules'

interface IInterviewPreparation {
  jobTitle: string
  jobId?: string
  jobLocationObj?: IJobLocationObj
  jobLocationString?: string
  jobWorkplaceType: UserWorkplacePreference[] | null
  jobStartDate: string | null | undefined
  atsUserId?: string
  atsJobId?: string
  notes?: string[] | null
  trackingEventName?: string
  interviewMetadata?: IInterviewMetadata
}

const defaultValues = {
  questionsTypes: {
    behavioural: false,
    technical: false,
  },
  includeAnswers: false,
  language: PreferredLanguageType.EN,
}

const interviewPreparationLens = lensProxy<IInterviewPreparationsFields>()

export const InterviewPreparation: FC<IInterviewPreparation> = memo(
  ({
    jobTitle,
    jobId,
    jobLocationObj,
    jobWorkplaceType,
    jobStartDate,
    atsUserId,
    atsJobId,
    jobLocationString,
    notes,
    trackingEventName,
    interviewMetadata,
  }) => {
    const { t } = useTranslation('main')
    const jobLocation =
      useJobLocation(jobLocationObj, true) || jobLocationString
    const subscriptionRef = useSubscriptionRef()
    const interviewQuestionSubscriptionRef = useSubscriptionRef()
    const pdfSubscriptionRef = useSubscriptionRef()
    const emailSubscriptionRef = useSubscriptionRef()
    const { addAlert } = useAlert()
    const aiResponseRef = useRef('')
    const aiPreferredLanguageRef = useRef(defaultValues.language)
    const theme = useTheme()
    const isBreakpointUpSm = useMediaQuery(theme.breakpoints.up('sm'))
    const [parsedAiResponse, setParsedAiResponse] = useState('')
    const [isPDFLoading, setIsPDFLoading] = useState(false)
    const [isEmailLoading, setIsEmailLoading] = useState(false)
    const [initialValues, setInitialValues] =
      useState<IInterviewPreparationsFields>(defaultValues)

    const isMobileOrTabView = useMediaQuery(() => theme.breakpoints.down('md'))

    const { interviewCopilotService, jobAiService } = useServices()

    const isLoading = useObservable(
      () => jobAiService.isInterviewQuestionsLoading$,
      false
    )

    const interviewQuestionsDeltas = useObservable(
      () => jobAiService.interviewQuestionsDeltas$,
      ''
    )

    const parseAiResponse = useCallback((aiResponse) => {
      if (aiResponse) {
        setParsedAiResponse(Parse(aiResponse) as string)
      }
    }, [])

    useEffect(() => {
      if (interviewMetadata) {
        parseAiResponse(interviewMetadata.response)
        setInitialValues(interviewMetadata)
      }
    }, [interviewMetadata])

    useEffect(() => {
      parseAiResponse(interviewQuestionsDeltas)
    }, [interviewQuestionsDeltas, parseAiResponse])

    const handleSubmit = useCallback(
      (values) => {
        setParsedAiResponse('')
        aiResponseRef.current = ''
        aiPreferredLanguageRef.current = values.language

        subscriptionRef.current = jobAiService
          .generateInterviewQuestions({
            jobId,
            behavioralQuestions: values.questionsTypes.behavioural,
            technicalQuestions: values.questionsTypes.technical,
            includeAnswers: values.includeAnswers,
            language: values.language,
            atsJobId,
            notes,
          })
          .subscribe({
            next: (data) => {
              aiResponseRef.current = data
              parseAiResponse(data)
            },
            error: () => {
              addAlert({
                severity: 'error',
                message: t('common.alert.somethingWrong'),
              })
            },
          })
      },
      [
        addAlert,
        atsJobId,
        jobAiService,
        jobId,
        parseAiResponse,
        subscriptionRef,
        t,
        notes,
      ]
    )

    const validationSchema = useMemo(
      () =>
        Yup.object().shape({
          questionsTypes: Yup.object().shape(
            {
              behavioural: Yup.bool().when('technical', {
                is: (technical: Yup.BooleanSchema) => !technical,
                then: Yup.bool().oneOf(
                  [true],
                  t('common.interviewPreparation.errors.selectQuestionType')
                ),
              }),
              technical: Yup.bool().when('behavioural', {
                is: (behavioural: Yup.BooleanSchema) => !behavioural,
                then: Yup.bool().oneOf(
                  [true],
                  t('common.interviewPreparation.errors.selectQuestionType')
                ),
              }),
            },
            [
              ['behavioural', 'technical'],
              ['technical', 'behavioural'],
            ]
          ),
        }),
      [t]
    )

    useEffect(() => {
      interviewQuestionSubscriptionRef.current = jobAiService
        .getInterviewQuestionsStream()
        .subscribe()
    }, [interviewQuestionSubscriptionRef, jobAiService])

    const handleSendEmail = useCallback(() => {
      setIsEmailLoading(true)
      emailSubscriptionRef.current = interviewCopilotService
        .sendEmailCopy({ jobTitle, body: aiResponseRef.current, atsUserId })
        .pipe(finalize(() => setIsEmailLoading(false)))
        .subscribe({
          complete: () => {
            addAlert({
              severity: 'success',
              message: t('common.alert.emailSent'),
            })
          },
          error: () => {
            addAlert({
              severity: 'error',
              message: t('common.alert.somethingWrong'),
            })
          },
        })
    }, [
      emailSubscriptionRef,
      interviewCopilotService,
      jobTitle,
      atsUserId,
      addAlert,
      t,
    ])

    const handleDownload = useCallback(() => {
      setIsPDFLoading(true)
      pdfSubscriptionRef.current = interviewCopilotService
        .downloadQuestionsPDF({
          jobTitle,
          body: aiResponseRef.current,
          atsUserId,
          language: aiPreferredLanguageRef.current,
        })
        .pipe(finalize(() => setIsPDFLoading(false)))
        .subscribe({
          next: (data) => {
            downloadPDF(data, `${jobTitle || 'Untitled'}-draft.pdf`)
          },
          error: () => {
            addAlert({
              severity: 'error',
              message: t('common.alert.somethingWrong'),
            })
          },
        })
    }, [
      aiResponseRef,
      pdfSubscriptionRef,
      interviewCopilotService,
      jobTitle,
      atsUserId,
      addAlert,
      t,
    ])

    return (
      <Grid
        sx={{
          width: '70%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid>
          <Typography
            variant="h5"
            component="h1"
            sx={{ marginBlockEnd: 2, color: 'primary.main' }}
          >
            {jobTitle}
          </Typography>
          <Stack
            sx={{
              paddingX: 2,
              paddingY: 2,
              boxSizing: 'content-box',
              width: '100%',
              left: `-${theme.spacing(2)}`,
              position: 'relative',
              marginBlockEnd: 3,
            }}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            alignItems="center"
          >
            {jobLocation && (
              <>
                <RoomSharpIcon sx={{ color: 'primary.main' }} />
                <Typography variant="body2">{jobLocation}</Typography>
              </>
            )}

            {jobWorkplaceType?.length ? (
              <>
                <Typography variant="body2">
                  {jobWorkplaceType
                    ?.map((code) =>
                      t(
                        `common.enums.jobSite.${
                          code as UserWorkplacePreference
                        }`
                      )
                    )
                    .join(' + ')}
                </Typography>
              </>
            ) : null}

            {jobStartDate && (
              <>
                <WorkIcon />
                <Typography variant="body2">
                  {t('common.dates.startDate')}&nbsp;
                  <DateFormatter date={jobStartDate} />
                </Typography>
              </>
            )}
          </Stack>

          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnMount
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  columnGap={2.5}
                  width="100%"
                  component={Form}
                  sx={{ marginBlockEnd: 3, '&.MuiGrid-root': { flexBasis: 0 } }}
                >
                  <Grid item container alignItems="end">
                    <Grid item container md={6}>
                      <Grid item xs={12} md={12} sx={{ marginBlockEnd: 1 }}>
                        <Typography variant="body1">
                          {t('common.interviewPreparation.description')}
                        </Typography>
                      </Grid>

                      {Object.values(JobAiQuestionsTypes).map((item) => {
                        return (
                          <Grid item xs="auto" md="auto" key={item}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    values.questionsTypes[
                                      item.toLowerCase() as keyof typeof values.questionsTypes
                                    ]
                                  }
                                />
                              }
                              onChange={handleChange}
                              name={`${interviewPreparationLens.questionsTypes[
                                item.toLowerCase() as keyof IInterviewPreparationsFields['questionsTypes']
                              ].$key()}`}
                              label={t(
                                `common.jobAiQuestionsTypes.${item.toLowerCase()}` as TFuncKey
                              )}
                            />
                          </Grid>
                        )
                      })}

                      <Grid item xs={12} sm="auto">
                        <FormControlLabel
                          control={<Checkbox checked={values.includeAnswers} />}
                          onChange={handleChange}
                          name={`${interviewPreparationLens.includeAnswers.$key()}`}
                          label={t(
                            `common.interviewPreparation.includeAnswers`
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={6}
                      mt={isMobileOrTabView ? 1 : 0}
                      mb={isMobileOrTabView ? 1 : 2}
                      sx={{
                        justifyContent: isMobileOrTabView
                          ? 'center'
                          : 'flex-end',
                      }}
                    >
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={values.language}
                        aria-label={t(
                          'common.jobGenerator.fineTuneJobLanguageType'
                        )}
                        onChange={(_, value) => {
                          if (value) {
                            setFieldValue('language', value)
                          }
                        }}
                      >
                        <ToggleButton value={PreferredLanguageType.EN}>
                          {t('common.jobGenerator.preferredLanguage.en')}
                        </ToggleButton>
                        <ToggleButton value={PreferredLanguageType.FR}>
                          {t('common.jobGenerator.preferredLanguage.fr')}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>

                  {!!(errors?.questionsTypes && touched?.questionsTypes) && (
                    <Grid
                      item
                      xs={12}
                      sx={{ marginBlockEnd: 1, textAlign: 'center' }}
                    >
                      <FormControl
                        error={
                          !!(errors?.questionsTypes && touched?.questionsTypes)
                        }
                      >
                        <FormHelperText>
                          <ErrorMessage
                            name={interviewPreparationLens.questionsTypes.technical.$key()}
                          />
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ justifyContent: 'center', marginBlockStart: 3 }}
                  >
                    <Tooltip
                      title={t(
                        'organisms.interviewPreparation.generateButtonTooltip'
                      )}
                      placement="top"
                      arrow
                    >
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        type="submit"
                        loading={isLoading}
                        fullWidth
                        name={trackingEventName}
                        sx={{ maxWidth: 250 }}
                        loadingIndicator={
                          <LoadingIndicator
                            label={t('common.jobGenerator.btn.generating')}
                          />
                        }
                      >
                        {t('common.btn.generate')}
                      </LoadingButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            }}
          </Formik>
        </Grid>

        <Grid
          container
          sx={{
            overflowY: 'scroll',
            minHeight: '350px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'text.main',
            borderRadius: '4px',
            padding: 2,
          }}
        >
          {parsedAiResponse && <>{parsedAiResponse}</>}
        </Grid>

        <Grid
          container
          spacing={2}
          width="100%"
          mt={1}
          py={3}
          justifyContent="center"
        >
          <Grid item>
            <LoadingButton
              loading={isEmailLoading}
              onClick={handleSendEmail}
              variant="outlined"
              disabled={
                parsedAiResponse.length === 0 || isLoading || isEmailLoading
              }
              fullWidth={!isBreakpointUpSm}
            >
              {t('common.btn.sendViaEmail')}
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isPDFLoading}
              onClick={handleDownload}
              endIcon={<DownloadIcon />}
              variant="outlined"
              disabled={
                parsedAiResponse.length === 0 || isLoading || isPDFLoading
              }
              fullWidth={!isBreakpointUpSm}
            >
              {t('common.btn.download')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }
)
